export const AdminBanksColumns = [
  {
    name: 'Currency',
    field: 'currencyName',
    selected: true,
    order: 1,
  },
  {
    name: 'Desk/IB',
    field: 'ibDeskId',
    selected: true,
    order: 2,
  },
  {
    name: 'Alias',
    field: 'alias',
    selected: true,
    order: 3,
  },
  {
    name: 'Account Number',
    field: 'accountNumber',
    selected: true,
    order: 4,
  },
  {
    name: 'Bank Name',
    field: 'name',
    selected: true,
    order: 5,
  },
  {
    name: 'IBAN',
    field: 'iban',
    selected: true,
    order: 6,
  },
  {
    name: 'Beneficiary',
    field: 'beneficiary',
    selected: true,
    order: 7,
  },
  {
    name: 'Beneficiary Address',
    field: 'beneficiaryAddress',
    selected: true,
    order: 8,
  },
  {
    name: 'Correspondent Account',
    field: 'correspondentAccount',
    selected: true,
    order: 9,
  },
  {
    name: 'INN (RU)',
    field: 'inn',
    selected: true,
    order: 10,
  },
  {
    name: 'KPP (RU)',
    field: 'kpp',
    selected: true,
    order: 11,
  },
  {
    name: 'Included countries',
    field: 'included_countries',
    selected: true,
    order: 12,
  },
  {
    name: 'Excluded Countries',
    field: 'excluded_countries',
    selected: true,
    order: 13,
  },
  {
    name: 'Status',
    field: 'isEnabled',
    selected: true,
    order: 14,
  },
  {
    name: 'Reference',
    field: 'reference',
    selected: true,
    order: 15,
  },
  {
    name: 'SWIFT/BIC',
    field: 'swiftCode',
    selected: true,
    order: 16,
  },
  {
    name: 'Actions',
    field: '',
    selected: true,
    order: 17,
  },
];

