import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { getClientActivities, exportClientActivityToFile } from '../../redux/clientActivity/clientActivityActions';
import { getActivityClientTypes } from '../../redux/activityClientType/activityClientTypeActions';
import { getSubCRMUsers } from '../../redux/crmUser/crmUserActions';
import { ClientActivityColumns } from '../../DataTableSchemas/ClientActivityColumns';
import { DownloadFile } from '../../components';
import { generateSortParams } from '../../helpers/sortHelper';
import { multiDebounceCallback, useMultiDebounce } from '../../hooks/useMultiDebounce';

function ClientActivityTable({
  isSeparatePage = false,
  isPositionUnset = false,
  userId = null,
}) {
  const dispatch = useDispatch();
  const paginationStorageName = 'DataTable_activity/client_pagination';

  const [tableLoading, setTableLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [isPaginationDT, setIsPaginationDT] = useState(false);
  const [sortParams, setSortParams] = useState(JSON.stringify({ createdAt: -1 }));
  
  const [userFilters, setUserFilters] = useState({});
  const [crmUsersFilter, setCrmUsersFilter] = useState([]);
  const [clientNameFilter, setClientNameFilter] = useState(null);
  const [clientCustomIdFilter, setClientCustomIdFilter] = useState(null);
  const [activityTypeFilter, setActivityTypeFilter] = useState([]);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);

  const allClientActivities = useSelector((state) => state?.clientActivity?.clientActivities);
        
  const setStoredPagination = () => {
    const clientLoginActivityRowsJSON = localStorage.getItem(paginationStorageName);
    if (clientLoginActivityRowsJSON) {
      const filterRows = JSON.parse(clientLoginActivityRowsJSON);
      setRowsPerPage(filterRows.limit || 25);
    }
    
    setIsPaginationDT(true);
  };

  const getActivities = (page, rowsPerPage, userFilters, sortParams) => dispatch(getClientActivities({
    page, rowsPerPage, userFilters, userId, sortParams,
  }));
  
  const handlePageChange = (page) => setPage(page);
  const handleRowsPerPageChange = (currentRowsPerPage) => {
    setRowsPerPage(currentRowsPerPage);
    localStorage.setItem(paginationStorageName, JSON.stringify({ limit: currentRowsPerPage }));
  };
  
  const handleExportClientActivity = async (fileType) => {
    await dispatch(exportClientActivityToFile({
      page, 
      rowsPerPage,
      filters: userFilters,
      fileType, 
      userId,
    }));
  };

  useMultiDebounce([clientNameFilter, clientCustomIdFilter], 1000, (inputValues) => {
    multiDebounceCallback(
      inputValues,
      ['clientName', 'clientCustomId'],
      userFilters,
      setUserFilters,
      undefined,
      initialLoadComplete,
      setInitialLoadComplete,
    );
  });

  const handleSort = async (sortField, sortDirection) => {
    const sort = generateSortParams(sortField, sortDirection);
    setSortParams(sort);
  };

  const activityColumns = ClientActivityColumns(
    userFilters, 
    setUserFilters,
    crmUsersFilter, 
    setCrmUsersFilter,
    activityTypeFilter,
    setActivityTypeFilter,
    clientNameFilter,
    setClientNameFilter,
    handleSort,
    clientCustomIdFilter,
    setClientCustomIdFilter,
  );

  useEffect(() => {
    const loginData = localStorage.getItem('user');
    const data = JSON.parse(loginData);
    const roleId = data?.roleId;
    const uid = data?._id;

    setStoredPagination();
    
    Promise.allSettled([
      dispatch(getActivityClientTypes()),
      dispatch(getSubCRMUsers(roleId, uid)),
    ]);
  }, []);

  useEffect(async () => {
    if (isPaginationDT) {
      setTableLoading(true);
      await getActivities(page, rowsPerPage, userFilters, sortParams);
      setTableLoading(false);
    }
  }, [isPaginationDT, userFilters, page, rowsPerPage, sortParams]);

  return (
    <div className="content-wrapper right-content-wrapper">
      <div className={!isSeparatePage ? 'content-box' : ''}>
        {
          !isSeparatePage
            && (
              <h3>Clients Activity Log</h3>
            )
        }
        <div className="action__btn-row">
          <DownloadFile isPositionUnset={isPositionUnset} handleExport={handleExportClientActivity} />
        </div>
        <div className="dashboard-tbl-wrapper custom-tbl-wrapper mt-3">
          {
                isPaginationDT
                  && (
                    <DataTable
                      columns={activityColumns}
                      data={allClientActivities.paginatedResult}
                      pagination
                      paginationServer
                      highlightOnHover
                      paginationPerPage={rowsPerPage}
                      paginationRowsPerPageOptions={[25, 50, 100, 500]}
                      paginationTotalRows={allClientActivities.total}
                      onChangeRowsPerPage={handleRowsPerPageChange}
                      onChangePage={handlePageChange}
                      persistTableHead
                      selectableRowsHighlight
                      selectableRowsVisibleOnly
                      theme="solarizedd"
                      className="specific-field-table custome-table-scroll"
                      progressPending={tableLoading}
                      progressComponent={<div className="datatable-loader__background" />}
                    />
                  )
                }
        </div>
      </div>
    </div>
  );
}

export default ClientActivityTable;
