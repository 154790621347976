import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { displayAdminRoles, updateRoleState } from '../../redux/roles/roleActions';
import { registerAdmin, updateAuthState } from '../../redux/auth/authActions';
import { getManagers, getRegUplineUsers } from '../../redux/crmUser/crmUserActions';

let rolesOptions = [];

function AddCRMUser() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [loader, setLoader] = useState(false);
  const [user, setUser] = useState('');
  const [loggedUserRole, setLoggedUserRole] = useState('');
  const [selectedRole, setSelectedRole] = useState(null);
  const [allManagerOptions, setAllManagerOptions] = useState([]);
  const [allUplineUsersOptions, setAllUplineUsersOptions] = useState([]);
  const [selectedDefaultManager, setSelectedDefaultManager] = useState(null);
  const [salesRole, setSalesRole] = useState(1);
  const [showPassword, setShowPassword] = useState('password');

  const error = useSelector((state) => state.auth?.error);
  const roles = useSelector((state) => state.role?.adminRoles);
  const managers = useSelector((state) => state.crmUser?.managers);
  const registeredAdmin = useSelector((state) => state.auth?.registeredAdmin);
  const fetchedAdminRoles = useSelector((state) => state.role?.fetchedAdminRoles);
  const uplineUsers = useSelector((state) => state.crmUser?.uplineUsers);

  const { register, handleSubmit, formState: { errors } } = useForm();

  const handleShowPassword = () => {
    if (showPassword === 'password') setShowPassword('text');
    else setShowPassword('password');
  };

  const addSubAdmin = {
    firstname: {
      required: 'First name is required',
    },
    lastname: {
      required: 'Last name is required',
    },
    username: {
      required: 'Username is required',
    },
    email: {
      required: 'Email is required',
      pattern: {
        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: 'Please enter a valid email',
      },
    },
    password: {
      required: 'Password is required',
      minLength: {
        value: 6,
        message: 'Password must have at least 6 characters',
      },
    },
  };

  useEffect(() => {
    setLoader(true);
    dispatch(displayAdminRoles());
    dispatch(getManagers());
  }, []);

  useEffect(() => {
    if (selectedRole?.value) {
      dispatch(getRegUplineUsers(selectedRole?.value));
    }
  }, [selectedRole, salesRole]);

  useEffect(() => {
    if (registeredAdmin) {
      dispatch(updateAuthState());
      setLoader(false);
      history.goBack();
    }
  }, [registeredAdmin]);

  useEffect(() => {
    if (error) {
      setLoader(false);
      dispatch(updateAuthState());
    }
  }, [error]);

  const filterManagers = async (currentSalesRole) => {
    if (managers?.length > 0) {
      const managersOptions = await managers?.filter((elem) => Number(elem?.salesRole) === Number(currentSalesRole) || Number(elem?.salesRole) === 3)?.map((manager) => ({ value: manager._id, label: `${manager.firstName} ${manager.lastName}` }));

      setAllManagerOptions(managersOptions);
      setSelectedDefaultManager(null);
    }
  };

  useEffect(() => {
    filterManagers(salesRole);
  }, [managers]);

  useEffect(() => {
    async function fetchRoleOptions() {
      if (roles?.length > 0 && fetchedAdminRoles) {
        rolesOptions = await roles?.map((role) => ({ value: role._id, label: role.name }));
        if (rolesOptions && rolesOptions.length > 0) {
          setSelectedRole(rolesOptions?.[0]);

          const loginData = localStorage.getItem('user');
          const data = JSON.parse(loginData);

          const currUserRole = rolesOptions.find((stat) => stat.value === data?.roleId);
          setLoggedUserRole(currUserRole?.label);
        }
      }
    }

    fetchRoleOptions();

    if (fetchedAdminRoles) {
      setLoader(false);
      dispatch(updateRoleState());
    }
  }, [roles, fetchedAdminRoles]);

  const handleRoleChange = (selectedRoleChange) => {
    setSelectedRole(selectedRoleChange);
    setSelectedDefaultManager(null);
  };

  const handleManagerChange = (selectedManager) => {
    setSelectedDefaultManager(selectedManager);
  };

  const handleSalesRoleChange = async (e) => {
    setSalesRole(e.target.value);
    filterManagers(e.target.value);
  };

  const filterUplineUsers = async (currentSalesRole) => {
    const uplineUsersOptions = await uplineUsers?.filter((elem) => Number(elem?.salesRole) === Number(currentSalesRole) || Number(elem?.salesRole) === 3)?.map((uplineUser) => ({ value: uplineUser._id, label: `${uplineUser.firstName} ${uplineUser.lastName}` }));

    setAllUplineUsersOptions(uplineUsersOptions);

    if (uplineUsersOptions && uplineUsersOptions.length > 0 && user?.defaultManager) {
      const currUplineUser = await uplineUsersOptions.find((stat) => stat.value === user?.defaultManager);
      if (currUplineUser) {
        setSelectedDefaultManager(currUplineUser);
      } else {
        setSelectedDefaultManager(null);
      }
    } else {
      setSelectedDefaultManager(null);
    }
  };

  useEffect(() => {
    filterUplineUsers(salesRole);
  }, [uplineUsers, user]);

  const handleAddSubAdmin = (formData) => {
    let allowedRoleAssignment = true;
    if ((selectedRole?.label === 'Manager' || selectedRole?.label === 'IBDesk' || selectedRole?.label === 'Sub Admin' || selectedRole?.label === 'Admin') && loggedUserRole === 'Agent') {
      allowedRoleAssignment = false;
    } else if ((selectedRole?.label === 'IBDesk' || selectedRole?.label === 'Sub Admin' || selectedRole?.label === 'Admin') && loggedUserRole === 'Manager') {
      allowedRoleAssignment = false;
    } else if ((selectedRole?.label === 'Sub Admin' || selectedRole?.label === 'Admin') && loggedUserRole === 'IBDesk') {
      allowedRoleAssignment = false;
    } else if (selectedRole?.label === 'Admin' && loggedUserRole === 'Sub Admin') {
      allowedRoleAssignment = false;
    }

    if (allowedRoleAssignment === false) {
      toast.error('Permission denied. Please re-login.', {
        autoClose: 1000,
      });
    } else {
      setLoader(true);
      const data = {
        firstName: formData.firstname,
        lastName: formData.lastname,
        username: formData.username,
        email: formData.email,
        roleId: selectedRole.value,
        salesRole,
        password: formData.password,
      };

      if (selectedDefaultManager) {
        data.defaultManager = selectedDefaultManager?.value;
      }

      dispatch(registerAdmin(data));
    }
  };

  const handleUplineUserChange = (selectedUplineUser) => {
    setSelectedDefaultManager(selectedUplineUser);
  };

  const colourStyles = {
    control: (styles, { isSelected }) => ({
      ...styles,
      background: '#374057',
      color: '#fff',
      border: '1px solid #374057',
      boxShadow: isSelected ? 'none' : 'none',
      borderColor: isSelected ? '#374057' : '#374057',
      '&:hover': {
        boxShadow: 'none',
      },
    }),
    input: (styles) => ({
      ...styles,
      color: '#fff',
    }),
    singleValue: (styles) => ({
      ...styles,
      color: '#fff',
    }),
    menuList: (styles) => ({
      ...styles,
      background: '#374057',
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      background: isFocused
        ? '#16202e'
        : isSelected
          ? '#16202e'
          : undefined,
      color: '#fff',
      cursor: 'pointer',
      zIndex: 1,
      '&:hover': {
        background: '#16202e',
      },
    }),
  };

  return (
    <div className="content-wrapper right-content-wrapper">
      <div className="content-box">
        <FontAwesomeIcon className="faArrowLeftIcon" icon={faArrowLeft} onClick={() => history.goBack()} />
        <h3>Add New CRM User</h3>
        <form onSubmit={handleSubmit(handleAddSubAdmin)}>
          <div className="form-group col-md-12">
            <label className="control-label">First Name</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter First name"
              {...register('firstname', addSubAdmin.firstname)}
              name="firstname"
            />
            {errors?.firstname && <span className="errMsg">{errors.firstname.message}</span>}
          </div>
          <div className="form-group col-md-12 pt-2">
            <label className="control-label">Last Name</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Last name"
              {...register('lastname', addSubAdmin.lastname)}
              name="lastname"
            />
            {errors?.lastname && <span className="errMsg">{errors.lastname.message}</span>}
          </div>
          <div className="form-group col-md-12 pt-2">
            <label className="control-label">User Name</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Username"
              {...register('username', addSubAdmin.username)}
              name="username"
            />
            {errors?.username && <span className="errMsg">{errors.username.message}</span>}
          </div>
          <div className="form-group col-md-12 pt-2">
            <label className="control-label">Email</label>
            <input
              type="email"
              className="form-control"
              placeholder="Enter Email"
              {...register('email', addSubAdmin.email)}
              name="email"
            />
            {errors?.email && <span className="errMsg">{errors.email.message}</span>}
          </div>
          <div className="form-group col-md-12 pt-2">
            <label className="control-label">Password</label>
            <div style={{ position: 'relative' }}>
              <input
                type={showPassword}
                className="form-control"
                placeholder="Enter Password"
                {...register('password', addSubAdmin.password)}
                name="password"
              />
              <FontAwesomeIcon
                icon={showPassword === 'password' ? faEyeSlash : faEye}
                color="white"
                onClick={() => handleShowPassword()}
                style={{ position: 'absolute', right: 10, top: 15 }}
              />
            </div>
            {errors?.password && <span className="errMsg">{errors.password.message}</span>}
          </div>
          <div className="form-group col-md-12 pt-2 ">
            <label className="control-label">Select Role</label>
            <Select
              value={selectedRole}
              onChange={handleRoleChange}
              options={rolesOptions}
              styles={colourStyles}
            />
          </div>
          <div className="form-group col-md-12 pt-2">
            <label className="control-label">Sales Role</label>
            <select className="form-control user-status-select" name="type" value={salesRole} onChange={(e) => handleSalesRoleChange(e)}>
              <option value={1}>Sales</option>
              <option value={2}>Retention</option>
              <option value={3}>Both</option>
            </select>
          </div>
          {selectedRole?.label !== 'Admin' ? (
            <div className="form-group col-md-12 pt-2">
              <label className="control-label">{selectedRole?.label === 'Agent' ? 'Manager' : 'Upline'}</label>
              <Select
                value={selectedDefaultManager}
                onChange={handleUplineUserChange}
                options={allUplineUsersOptions}
                styles={colourStyles}
              />
            </div>
          ) : null}
          <div>
            <button className="btn btn-default" type="submit">Save</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddCRMUser;
