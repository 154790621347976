import React from 'react';

function CircleLoader({
  width = 50, height = 50, color = '#3498db', radius = 20, thickness = 4, 
}) {
  const loaderStyle = {
    width: `${width}px`,
    height: `${height}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const circleStyle = {
    width: `${radius * 2}px`,
    height: `${radius * 2}px`,
    border: `${thickness}px solid ${color}50`, // Transparent version of color
    borderTop: `${thickness}px solid ${color}`,
    borderRadius: '50%',
    animation: 'spin 1s linear infinite',
  };

  return (
    <div style={loaderStyle}>
      <div style={circleStyle} />
      <style>
        {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
      </style>
    </div>
  );
}

export default CircleLoader;
