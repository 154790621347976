import React, { useState, useEffect, useMemo } from 'react';
import Select from 'react-select';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
import { colourStyles } from '../../helpers/styles';
import { editUser } from '../../redux/users/userActions';
import { getCRMUserOptions, deleteCRMUser } from '../../redux/crmUser/crmUserActions';

export function AssignToManagerModal({
  hideModal, 
  selectedUserType, 
  user, 
  defaultManagerOption,
  isBulkAssign = false,
  isNeedToDeleteUser = false,
  ids = [],
  massEdit,
}) {
  const dispatch = useDispatch();
  const { options } = useSelector((state) => state.crmUser);
  const unassignedOption = { value: null, label: 'Unassigned' };

  const [assignedToManager, setAssignedToManager] = useState(
    !isNeedToDeleteUser ? defaultManagerOption : unassignedOption,
  );

  const updatedOptions = useMemo(() => {
    const filteredOptions = isNeedToDeleteUser ? options.filter((option) => option.value !== defaultManagerOption.value) : options;

    return [
      unassignedOption,
      ...filteredOptions,
    ];
  }, [options]);

  useEffect(() => {
    dispatch(getCRMUserOptions());
  }, []);

  const handleUserTypeChange = () => {
    const clientType = Number(selectedUserType) === 1 ? 2 : 1;

    const data = {
      clientType,
      assignedTo: assignedToManager?.value ?? null,
    };

    if (isBulkAssign) data.ids = ids;

    hideModal();

    const method = isBulkAssign 
      ? massEdit(data, `Are you sure you want to move user(s) to ${Number(selectedUserType) === 1 ? 'client' : 'lead'} category?`) 
      : dispatch(editUser(user._id, data));

    method();
  };

  const handleReassignCRMUser = () => {
    dispatch(deleteCRMUser(defaultManagerOption.value, assignedToManager.value));
    hideModal();
  };

  return (
    <>
      <div className="mb-3">
        <label htmlFor="managerSelect" className="mb-2">Manager</label>
        <Select
          styles={colourStyles}
          options={updatedOptions}
          isSearchable
          defaultValue={assignedToManager}
          onChange={(option) => setAssignedToManager(option)}
        />
      </div>
      <div style={{ justifyContent: 'flex-end', display: 'flex' }}>
        <Button variant="secondary" onClick={() => hideModal()}>Cancel</Button>
        <Button 
          variant="primary" 
          className="ms-2" 
          disabled={assignedToManager === null}
          onClick={!isNeedToDeleteUser ? handleUserTypeChange : handleReassignCRMUser}
        >
          Save
        </Button>
      </div>
    </>
  );
}
