import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClone, faUndo } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import currencyFormatter from 'currency-formatter';
import Swal from 'sweetalert2';
import { resolveDepositTransaction, revertTransaction } from '../redux/users/userActions';
import { displayAffiliateDeposits } from '../redux/affiliate/affiliateActions';
import { TooltipComponent } from '../components/TooltipComponent/TooltipComponent';
import { prettyCutOutputByCellWidth, valueOutputCutLength } from './helper';
import { SortWrapper, useTableSorting } from '../helpers/sortHelper';
import SortIcon from '../components/SortIcon';
  
export const AffiliateDepositColumns = (handleSort) => {
  const dispatch = useDispatch();
  const currencyRates = useSelector((state) => state.currencyRates);
  const { sortFields, getSortIcon } = useTableSorting(handleSort);

  const copyReferral = () => {
    toast.success('Successfully copied!', {
      autoClose: 1000,
    });
  };

  const getRateInUsdt = (coin_symbol, amount) => {
    if (currencyRates && Object.keys(currencyRates).length) {
      const total_in_usdt = parseFloat(parseFloat((1 / currencyRates[coin_symbol === 'USDT' ? 'USD' : coin_symbol])) * parseFloat(amount));

      // eslint-disable-next-line no-restricted-globals
      if (!isNaN(total_in_usdt)) {
        return total_in_usdt;
      }

      return null;
    }

    return parseFloat(amount);
  };

  const padTo2Digits = (num) => num.toString().padStart(2, '0');

  const formatDate = (date) => (
    `${[
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join('-')
    } ${
      [
        padTo2Digits(date.getHours()),
        padTo2Digits(date.getMinutes()),
        padTo2Digits(date.getSeconds()),
      ].join(':')}`
  );

  const resolveCurrentDepositTransaction = async (rowId, userId, status) => {
    Swal.fire({
      title: `Are you sure you want to ${status === 1 ? 'Approve' : 'Decline'} it?`,
      input: 'textarea',
      inputPlaceholder: 'Enter information/comment...',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    // eslint-disable-next-line consistent-return
    }).then((result) => {
      if (result.isConfirmed === true) {
        const userIdData = localStorage.getItem('userId');
        const loginUserId = JSON.parse(userIdData);

        const data = {
          userId, crmUserId: loginUserId, resolvedStatus: status, comment: result.value ? result.value : '',
        };

        return Promise.resolve(
          dispatch(resolveDepositTransaction(rowId, data, false)),
        )
          .then(
            () => {
              const loginData = localStorage.getItem('user');
              // eslint-disable-next-line no-shadow
              const data = JSON.parse(loginData);
              const roleId = data?.roleId;
              const uid = data?._id;

              // eslint-disable-next-line no-undef
              dispatch(displayAffiliateDeposits(roleId, uid, id));
            },
          ).catch((error) => {
            // console.log(error, 'resolveCurrentDepositTransaction');
          });
      }
    });
  };

  const handleRevertTransaction = async (rowId, userId) => {
    Swal.fire({
      title: 'Are you sure you want to Revert the transaction?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    // eslint-disable-next-line consistent-return
    }).then((result) => {
      if (result.isConfirmed === true) {
        const data = { userId };
        return Promise.resolve(
          dispatch(revertTransaction(rowId, data, false)),
        )
          .then(
            () => {
              const loginData = localStorage.getItem('user');
              // eslint-disable-next-line no-shadow
              const data = JSON.parse(loginData);
              const roleId = data?.roleId;
              const uid = data?._id;

              // eslint-disable-next-line no-undef
              dispatch(displayAffiliateDeposits(roleId, uid, id));
            },
          ).catch((error) => {
            // console.log(error, 'revertCurrentDepositTransaction');
          });
      }
    });
  };

  const depositColumns = [
    {
      name: (
        <SortWrapper handleSort={() => sortFields('_id')}>
          <div className="d-flex flex-row">
            <span>ID</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('_id')} />
            </button>
          </div>
        </SortWrapper>
      ),
      cell: ({ customId }) => (
        customId.length >= valueOutputCutLength.customId
          ? (
            <TooltipComponent
              title={customId}
              // eslint-disable-next-line react/no-children-prop
              children={(
                <div>
                  <CopyToClipboard text={customId}>
                    <span style={{
                      whiteSpace: 'nowrap', cursor: 'pointer', 
                    }}
                    >
                      <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
                      {prettyCutOutputByCellWidth(customId, 'customId')}
                    </span>
                  </CopyToClipboard>
                </div>
              )}
            />
          )
          : (
            <CopyToClipboard text={customId}>
              <span style={{ margin: '5px', whiteSpace: 'nowrap' }}>
                <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
                {customId}
              </span>
            </CopyToClipboard>
          )
      ),
      minWidth: '84px',
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('createdAt')}>
          <div className="d-flex flex-row">
            <span>Transaction Time</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('createdAt')} />
            </button>
          </div>
        </SortWrapper>
      ),
      selector: (row) => formatDate(new Date(row?.createdAt)),
      minWidth: '174px',
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('balanceType')}>
          <div className="d-flex flex-row">
            <span>Type</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('balanceType')} />
            </button>
          </div>
        </SortWrapper>
      ),
      minWidth: '80px',
      cell: ({ balanceType }) => (balanceType ? 'Balance' : 'Credit'),
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('currency')}>
          <div className="d-flex flex-row">
            <span>Currency</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('currency')} />
            </button>
          </div>
        </SortWrapper>
      ),
      minWidth: '80px',
      selector: (row) => row?.currency,
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('amount')}>
          <div className="d-flex flex-row">
            <span>Amount</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('amount')} />
            </button>
          </div>
        </SortWrapper>
      ),
      minWidth: '104px',
      selector: (row) => row?.amount,
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('user.firstName user.lastName')}>
          <div className="d-flex flex-row">
            <span>Client</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('user.firstName user.lastName')} />
            </button>
          </div>
        </SortWrapper>
      ),
      minWidth: '170px',
      selector: (row) => (
        <Link
          to={`/user-detail/${row?.user?._id}`}
          className="text-decoration-none"
        >
          {`${row?.user?.firstName} ${row?.user?.lastName}`}
        </Link>
      ),
      cell: ({ user }) => {
        const fullUserName = `${user?.firstName} ${user?.lastName}`;
        return (
          fullUserName.length >= valueOutputCutLength.manager 
            ? (
              <TooltipComponent 
                title={fullUserName}
                  // eslint-disable-next-line react/no-children-prop
                children={(
                  <Link
                    to={`/user-detail/${user?._id}`}
                    className="text-decoration-none"
                  >
                    {prettyCutOutputByCellWidth(fullUserName, 'manager')}
                  </Link>
                  )}
              />
            )
            : (
              <Link
                to={`/user-detail/${user?._id}`}
                className="text-decoration-none"
              >
                {fullUserName}
              </Link>
            )
        );
      },
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('assignedToFullName')}>
          <div className="d-flex flex-row">
            <span>Assigned To</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('assignedToFullName')} />
            </button>
          </div>
        </SortWrapper>
      ),
      minWidth: '170px',
      cell: ({ assignedToFullName = '' }) => {
        if (assignedToFullName) {
          return (
            assignedToFullName.length >= valueOutputCutLength.manager 
              ? (
                <TooltipComponent 
                  title={assignedToFullName}
                  // eslint-disable-next-line react/no-children-prop
                  children={assignedToFullName}
                />
              )
              : (
                assignedToFullName
              )
          );
        }
        return '-';
      },
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('convertedAmount')}>
          <div className="d-flex flex-row">
            <span>In USD</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('convertedAmount')} />
            </button>
          </div>
        </SortWrapper>
      ),
      minWidth: '100px',
      selector: (row) => parseFloat(row?.amount).toFixed(2),
      cell: ({ currency, amount }) => { 
        const usdtValue = getRateInUsdt(currency, amount, currencyRates);
        return (
          usdtValue?.toFixed(2).toString().length >= valueOutputCutLength.inUsd 
            ? (
              <TooltipComponent title={usdtValue?.toFixed(2).toString()}>
                <span style={{ cursor: 'pointer' }}>
                  {prettyCutOutputByCellWidth(usdtValue?.toFixed(2).toString(), 'inUsd')}
                </span>
              </TooltipComponent>
            )
            : (
              <span>
                {usdtValue?.toFixed(2) ?? '-'}
              </span>
            )
        );
      },
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('isReal')}>
          <div className="d-flex flex-row">
            <span>Real/Fake</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('isReal')} />
            </button>
          </div>
        </SortWrapper>
      ),
      minWidth: '140px',
      selector: (row) => (row?.isReal === true ? 'Real' : 'Fake'),
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('isResolved')}>
          <div className="d-flex flex-row">
            <span>Status</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('isResolved')} />
            </button>
          </div>
        </SortWrapper>
      ),
      minWidth: '130px',
      selector: (row) => (
        row?.isResolved === 0 ? (
          <span className="badge rounded-pill bg-warning">Pending</span>
        ) : row?.isResolved === 1 ? (
          <span className="badge rounded-pill bg-success">Completed</span>
        ) : (
          <span className="badge rounded-pill bg-danger">Declined</span>
        )
      ),
    },
    {
      name: 'Action(s)',
      minWidth: '170px',
      cell: (row) => (
        row?.isResolved === 0 ? (
          <>
            <button
              type="button"
              className="btn btn-success btn-sm me-1 p-1"
              onClick={() => resolveCurrentDepositTransaction(row?._id, row?.userId, 1)}
            >
              Accept
            </button>
            <button
              type="button"
              className="btn btn-danger btn-sm me-1 p-1"
              onClick={() => resolveCurrentDepositTransaction(row?._id, row?.userId, 2)}
            >
              Decline
            </button>
          </>
        ) : row?.isResolved === 1 ? (
          <button
            type="button"
            className="btn btn-danger btn-sm me-1 p-1"
            onClick={() => handleRevertTransaction(row?._id, row?.userId)}
          >
            <FontAwesomeIcon
              icon={faUndo}
              className="header-icon text-white"
            />
          </button>
        ) : (
          <span>-</span>
        )
      ),
    },
  ];
  return depositColumns;
};
