import React, { useMemo, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import {
  faClone, faComment, faCircleInfo, faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { editUser } from '../../redux/users/userActions';
import { getAdminComments } from '../../redux/adminComment/adminCommentActions';
import { ModalFilters } from '../../components';
import { RangeModalDebounceFilter } from '../../pages/Transactions/modalFilters';
import {
  omitColumn, 
  formatDate, 
  setTransactionActionColumn, 
  dateOptions, 
  prettyCutOutputByCellWidth, 
  valueOutputCutLength,
  sortColumnByOrderConfig,
} from '../helper';
import { getRateInUsdt } from '../../helpers/getRateInUSD';
import { TooltipComponent } from '../../components/TooltipComponent/TooltipComponent';
import SortIcon from '../../components/SortIcon';
import styles from './styles.module.css';
import { useTableSorting, SortWrapper } from '../../helpers/sortHelper';
import { updateTransactionFields } from '../../redux/transactions/transactionActions';
import useIsSuperUser from '../../hooks/isSuperAdmin';


export const ExternalTransactionsSchema = (
  setTransactionIdFilter,
  permissionName,
  currencies,
  transactionIdFilter,
  uidFilter,
  setUidFilter,
  nameFilter,
  setNameFilter,
  emailFilter, 
  secondEmailFilter, 
  setEmailFilter,
  setSecondEmailFilter,
  setAssetFilters,
  assetFilters,
  amountRange,
  setAmountRange,
  handleAdditionalInfoModalToggle,
  handleAdditionalCommentModalToggle,
  setManagerFilters,
  managerFilters,
  realFilter,
  setRealFilter,
  statusFilter,
  setStatusFilter,
  isAdditionalInfoModalShown,
  isAdditionalCommentModalShown,
  setIsAdditionalInfoModalShown,
  setIsAdditionalCommentModalShown,
  resolveCurrentDepositTransaction,
  handleRevertTransaction,
  assignToShow, 
  depositsFilters,
  setDepositsFilters,
  columnConfig,
  timeInitiatedFilter,
  setTimeInitiatedFilter,
  updateIsReal,
  handleDeleteTransaction,
  isDeleted,
  setDebounceAmountRange,
  brandsFilters,
  setBrandsFilters,
  balanceTypeFilter,
  setBalanceTypeFilter,
  handleSort,
  accountIdFilter,
  setAccountIdFilter,
  sourceFilter, 
  setSourceFilter,
  ibdeskUserFilter,
  setIbdeskUserFilter,
  allIbDesks,
  allAffiliates,
  affiliateFilter,
  setAffiliateFilter,
) => {
  const dispatch = useDispatch();
  const isSuperUser = useIsSuperUser();
  const [showComments, setShowComments] = useState(0);

  const adminComments = useSelector((state) => state?.adminComment?.adminComments);
  const [commentDate, setCommentDate] = useState(new Date());
  const currencyRates = useSelector((state) => state.currencyRates);
  const brands = useSelector((state) => state.brands);

  const isAffiliateRule = useMemo(() => (permissionName && permissionName.length && permissionName.includes('affiliates')), [permissionName]);

  const copyReferral = () => {
    toast.success('Successfully copied!', {
      autoClose: 1000,
    });
  };

  function JSONparser(inputString) {
    if (!inputString) {
      console.warn('Empty string received for JSON parsing');
      return null;
    }
    try {
      const parsed = JSON.parse(inputString);
      return parsed;
    } catch (e) {
      console.error('JSON parse error:', e);
      return null;
    }
  }

  const handleShowComment = (userId, rowId) => {
    dispatch(getAdminComments(userId));
    if (adminComments.length > 0) {
      setCommentDate(new Date(adminComments[adminComments.length - 1].createdAt));
    } 

    setShowComments(rowId);
  };

  const handleChangeAssignTo = async (e, id) => {
    try {
      const data = {
        assignedTo: e.target.value,
      };
      await dispatch(updateTransactionFields(id, data));
    } catch (error) {
      toast.error(error);
    }
  };

  const isUserCanSeeClientCard = permissionName && permissionName.includes('clients');
  const isUserCanAssignToAgent = permissionName && permissionName.includes('assign_to_agent');

  const { sortFields, getSortIcon } = useTableSorting(handleSort);

  const columns = [
    {
      name: (
        <div className={`d-flex align-items-start flex-column search-input-des pt-2 ${styles.wrapperInput}`}>
          <SortWrapper handleSort={() => sortFields('customId')}>
            <div className="d-flex flex-row">
              <span>Transaction ID</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('customId')} />
              </button>
            </div>
          </SortWrapper>
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            autoComplete="off"
            name="Transaction ID"
            value={transactionIdFilter || ''}
            onChange={({ target: { value } }) => setTransactionIdFilter(value)}
          />
          <span style={{ height: '20px' }} />
        </div>
      ),
      minWidth: '150px',
      reorder: true,
      label: 'Transaction ID',
      omit: omitColumn(columnConfig, 'Transaction ID'), 
      cell: ({ customId }) => (
        customId.length >= valueOutputCutLength.id
          ? (
            <TooltipComponent
              title={customId}
              // eslint-disable-next-line react/no-children-prop
              children={(
                <CopyToClipboard text={customId}>
                  <span>      
                    <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
                    {prettyCutOutputByCellWidth(customId, 'id')}
                  </span>
                </CopyToClipboard>
            )}
            />
          )
          : (
            <CopyToClipboard text={customId}>
              <span>      
                <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
                {customId}
              </span>
            </CopyToClipboard>
          )
      ),
    },
    {
      name: (
        <div className={`d-flex align-items-start flex-column search-input-des pt-2 ${styles.wrapperInput}`}>
          <SortWrapper handleSort={() => sortFields('userCustomId')}>
            <div className="d-flex flex-row">
              <span>Client ID</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('userCustomId')} />
              </button>
            </div>
          </SortWrapper>
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            autoComplete="off"
            name="Client ID"
            value={uidFilter || ''}
            onChange={(e) => setUidFilter(e.target.value)}
          />
        </div>
      ),
      minWidth: '160px',
      omit: omitColumn(columnConfig, 'Client ID'), 
      reorder: true,
      label: 'Client ID',
      cell: ({ userCustomId = '' }) => (
        userCustomId?.length >= valueOutputCutLength.id
          ? (
            <TooltipComponent
              title={userCustomId}
              // eslint-disable-next-line react/no-children-prop
              children={(
                <CopyToClipboard text={userCustomId}>
                  <span>      
                    <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
                    {prettyCutOutputByCellWidth(userCustomId, 'id')}
                  </span>
                </CopyToClipboard>
              )}
            />
          )
          : (
            <CopyToClipboard text={userCustomId}>
              <span>
                <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
                {userCustomId}
              </span>
            </CopyToClipboard>
          )
      ),
    },
    {
      name: (
        <div className={`d-flex align-items-start flex-column search-input-des pt-2 ${styles.wrapperInput}`}>
          <SortWrapper handleSort={() => sortFields('userFullName')}>
            <div className="d-flex flex-row">
              <span>Client</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('userFullName')} />
              </button>
            </div>
          </SortWrapper>
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            autoComplete="off"
            name="Client"
            value={nameFilter || ''}
            onChange={({ target: { value } }) => setNameFilter(value)}
          />
        </div>
      ),
      omit: omitColumn(columnConfig, 'Client'), 
      reorder: true,
      label: 'Client',
      cell: ({ userObjectId, userFullName = '-' }) => (
        <div style={{
          width: '100%',
          display: 'flex',
          gap: '3px',
          position: 'relative',
          justifyContent: 'flex-start',
        }}
        >
          {
            userFullName?.length >= valueOutputCutLength.fullName
              ? (
                <TooltipComponent
                  title={userFullName}
                  // eslint-disable-next-line react/no-children-prop
                  children={(
                      isUserCanSeeClientCard ? (
                        <Link to={`/user-detail/${userObjectId}`}>
                          <span data-tip={userFullName} className="full-name-text">
                            {prettyCutOutputByCellWidth(userFullName, 'fullName')}
                          </span>
                        </Link>
                      ) : (
                        <span data-tip={userFullName} className="full-name-text">
                          {prettyCutOutputByCellWidth(userFullName, 'fullName')}
                        </span>
                      )
                  )}
                />
              )
              : (isUserCanSeeClientCard ? (
                <Link to={`/user-detail/${userObjectId}`}>
                  <span data-tip={userFullName} className="full-name-text">
                    {userFullName}
                  </span>
                </Link>
              ) : (
                <span data-tip={userFullName} className="full-name-text">
                  {userFullName}
                </span>
              ))
          }
        </div>
      ),
      minWidth: '194px',
    },
    {
      name: (
        <div className={`d-flex align-items-start flex-column search-input-des pt-2 ${styles.wrapperInput}`}>
          <SortWrapper handleSort={() => sortFields('userEmail')}>
            <div className="d-flex flex-row">
              <span>Email</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('userEmail')} />
              </button>
            </div>
          </SortWrapper>
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            name="Email"
            autoComplete="off"
            value={emailFilter || ''}
            onChange={({ target: { value } }) => setEmailFilter(value)}
          /> 
        </div>
      ),
      omit: omitColumn(columnConfig, 'Email'), 
      minWidth: '180px',
      reorder: true,
      label: 'Email',
      cell: ({ userEmail = '-' }) => (
        userEmail?.length >= valueOutputCutLength.email 
          ? (
            <TooltipComponent
              title={userEmail}
              // eslint-disable-next-line react/no-children-prop
              children={(
                <div>
                  <CopyToClipboard text={userEmail}>
                    <span style={{ margin: '5px', whiteSpace: 'nowrap', cursor: 'pointer' }} data-tip={userEmail}>
                      <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
                      {prettyCutOutputByCellWidth(userEmail, 'email')}
                    </span>
                  </CopyToClipboard>
                </div>
                )}
            />
          )
          : (userEmail || '').length === 0 ? '-' : (
            <div>
              <CopyToClipboard text={userEmail}>
                <span style={{ margin: '5px', whiteSpace: 'nowrap', cursor: 'pointer' }} data-tip={userEmail}>
                  <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
                  {userEmail}
                </span>
              </CopyToClipboard>
            </div>
          )
      ),
    },
    {
      name: (
        <div className={`d-flex align-items-start flex-column search-input-des pt-2 ${styles.wrapperInput}`}>
          <SortWrapper handleSort={() => sortFields('userSecondEmail')}>
            <div className="d-flex flex-row">
              <span>Second Email</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('userSecondEmail')} />
              </button>
            </div>
          </SortWrapper>
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            name="Second Email"
            autoComplete="off"
            value={secondEmailFilter || ''}
            onChange={({ target: { value } }) => setSecondEmailFilter(value)}
          />
        </div>
      ),
      reorder: true,
      label: 'Second Email',
      omit: omitColumn(columnConfig, 'Second Email'), 
      minWidth: '180px',
      cell: ({ userSecondEmail = '' }) => (
        userSecondEmail?.length >= valueOutputCutLength.secondEmail 
          ? (
            <TooltipComponent
              title={userSecondEmail}
                  // eslint-disable-next-line react/no-children-prop
              children={(
                <div>
                  <CopyToClipboard text={userSecondEmail}>
                    <span style={{ margin: '5px', whiteSpace: 'nowrap', cursor: 'pointer' }} data-tip={userSecondEmail}>
                      <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
                      {prettyCutOutputByCellWidth(userSecondEmail, 'secondEmail')}
                    </span>
                  </CopyToClipboard>
                </div>
                  )}
            />
          ) : (userSecondEmail || '').length === 0 ? '-' : (
            <div>
              <CopyToClipboard text={userSecondEmail}>
                <span style={{ margin: '5px', whiteSpace: 'nowrap', cursor: 'pointer' }} data-tip={userSecondEmail}>
                  <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
                  {userSecondEmail}
                </span>
              </CopyToClipboard>
            </div>
          )
      ),
    },
    {
      name: (
        <div className={`d-flex align-items-start flex-column search-input-des pt-2 ${styles.wrapperInput}`}>
          <SortWrapper handleSort={() => sortFields('accountCustomId')}>
            <div className="d-flex flex-row">
              <span>Account ID</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('accountCustomId')} />
              </button>
            </div>
          </SortWrapper>
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            autoComplete="off"
            name="Account ID"
            value={accountIdFilter || ''}
            onChange={(e) => setAccountIdFilter(e.target.value)}
          />
        </div>
      ),
      minWidth: '160px',
      omit: omitColumn(columnConfig, 'Account Id'), 
      reorder: true,
      label: 'Account Id',
      cell: ({ accountCustomId = '' }) => (
        accountCustomId?.length >= valueOutputCutLength.id
          ? (
            <TooltipComponent
              title={accountCustomId}
              // eslint-disable-next-line react/no-children-prop
              children={(
                <CopyToClipboard text={accountCustomId}>
                  <span>      
                    <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
                    {prettyCutOutputByCellWidth(accountCustomId, 'id')}
                  </span>
                </CopyToClipboard>
              )}
            />
          )
          : (
            <CopyToClipboard text={accountCustomId}>
              <span>
                <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
                {accountCustomId}
              </span>
            </CopyToClipboard>
          )
      ),
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('ibdeskFullName')}>
            <div className="d-flex flex-row">
              <span>Desk/IB</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('ibdeskFullName')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters
            tabName="Desk/IB"
            filterName="ibdeskUser"
            data={allIbDesks}
            filters={ibdeskUserFilter}
            setFilters={setIbdeskUserFilter}
            searchParams={{ id: '_id', params: ['firstName', 'lastName'], optionsName: ['firstName', 'lastName'] }}
            usersFilters={depositsFilters}
            setUserFilters={setDepositsFilters}
            addNonSelected
          />
        </div>
      ),
      minWidth: '170px',
      cell: ({ ibdeskId, ibdeskFullName }) => {
        if (!ibdeskFullName) return '-';

        return (
          ibdeskFullName.length >= valueOutputCutLength.ibdesk
            ? (
              <TooltipComponent
                title={ibdeskFullName}
                // eslint-disable-next-line react/no-children-prop
                children={(
                  <Link
                    to={`/edit-admin/${ibdeskId}`}
                    className="text-decoration-none"
                  >
                    {prettyCutOutputByCellWidth(ibdeskFullName, 'ibdesk')}
                  </Link>
                )}
              />
            )
            : (
              <Link
                to={`/edit-admin/${ibdeskId}`}
                className="text-decoration-none"
              >
                {ibdeskFullName}
              </Link>
            )
        );
      },
      reorder: true,
      label: 'Desk/IB',
      omit: omitColumn(columnConfig, 'Desk/IB'),
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('assignedToFullName')}>
            <div className="d-flex flex-row">
              <span>Assigned To</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('assignedToFullName')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters
            tabName="Assigned To" 
            data={assignToShow}
            filters={managerFilters}
            setFilters={setManagerFilters}
            searchParams={{ id: '_id', params: ['firstName', 'lastName'], optionsName: ['firstName', 'lastName'] }}
            usersFilters={depositsFilters}
            storageName="Assigned To"
            setUserFilters={setDepositsFilters}
            addNonSelected
          />
        </div>
      ),
      minWidth: '190px',
      omit: omitColumn(columnConfig, 'Assigned To'), 
      reorder: true,
      label: 'Assigned To',
      cell: ({ assignedTo, assignedToFullName }) => {
        if (!assignedToFullName) return '-';

        return (
          assignedToFullName.length >= valueOutputCutLength.manager 
            ? (
              <TooltipComponent
                title={assignedToFullName}
                // eslint-disable-next-line react/no-children-prop
                children={(
                  <Link
                    to={`/edit-admin/${assignedTo}`}
                    className="text-decoration-none"
                  >
                    {prettyCutOutputByCellWidth(assignedToFullName, 'manager')}
                  </Link>
                )}
              />
            )
            : (
              <Link
                to={`/edit-admin/${assignedTo}`}
                className="text-decoration-none"
              >
                {assignedToFullName}
              </Link>
            )
        );
      },
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('amount')}>
            <div className="d-flex flex-row">
              <span>Amount</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('amount')} />
              </button>
            </div>
          </SortWrapper>
          <RangeModalDebounceFilter 
            value={amountRange ?? [0, 1000000]}
            setValue={setAmountRange}
            setFilters={setDebounceAmountRange}
            filters={{}}
            filedName="amount"
            debounceAmountRange
          />
        </div>
      ),
      minWidth: '110px',
      omit: omitColumn(columnConfig, 'Amount'), 
      reorder: true,
      label: 'Amount',
      selector: ({ amount }) => (
        amount?.toString()?.length >= valueOutputCutLength.balance 
          ? (
            <TooltipComponent 
              title={amount.toString()}
              // eslint-disable-next-line react/no-children-prop
              children={(
                <span style={{ cursor: 'pointer' }}>
                  {prettyCutOutputByCellWidth(amount, 'balance')}
                </span>
              )}
            />
          )
          : (
            <span>
              {amount}
            </span>
          )
      ),
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('currency')}>
            <div className="d-flex flex-row">
              <span>Currency</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('currency')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters
            tabName="Currency" 
            data={currencies} 
            filters={assetFilters} 
            setFilters={setAssetFilters} 
            searchParams={{ id: '_id', params: ['name', '_id', 'symbol'], optionsName: ['symbol'] }}
            usersFilters={depositsFilters}
            setUserFilters={setDepositsFilters}
          />
        </div>
      ),
      omit: omitColumn(columnConfig, 'Currency'), 
      reorder: true,
      label: 'Currency',
      minWidth: '120px',
      selector: ({ currency }) => currency,
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('balanceType')}>
            <div className="d-flex flex-row">
              <span>Type</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('balanceType')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters
            tabName="Type" 
            data={[{ name: 'Balance', _id: 'true' }, { name: 'Credit', _id: 'false' }]}
            filters={balanceTypeFilter}
            setFilters={setBalanceTypeFilter}
            searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
            usersFilters={depositsFilters}
            setUserFilters={setDepositsFilters}
          />
        </div>
      ),
      omit: omitColumn(columnConfig, 'Type'), 
      reorder: true,
      label: 'Type',
      minWidth: '120px',
      selector: ({ balanceType = true }) => (balanceType ? 'Balance' : 'Credit'),
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('brandName')}>
            <div className="d-flex flex-row">
              <span>Brand</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('brandName')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters 
            tabName="Brand" 
            data={brands} 
            filters={brandsFilters} 
            setFilters={setBrandsFilters} 
            searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
            usersFilters={depositsFilters}
            setUserFilters={setDepositsFilters}
            addNonSelected
          />
        </div>
      ),
      omit: omitColumn(columnConfig, 'Brand'), 
      reorder: true,
      label: 'Brand',
      minWidth: '130px',
      selector: ({ brandName = '' }) => (
        brandName?.length >= valueOutputCutLength.brand 
          ? (
            <TooltipComponent 
              title={brandName}
              // eslint-disable-next-line react/no-children-prop
              children={(
                <span style={{ cursor: 'pointer' }}>
                  {prettyCutOutputByCellWidth(brandName ?? '', 'brand')}
                </span>
              )}
            />
          )
          : (
            <span>
              {brandName ?? ''}
            </span>
          )
      ),
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('convertedAmount')}>
          <div className="d-flex flex-row">
            <span>In USD</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('convertedAmount')} />
            </button>
          </div>
        </SortWrapper>
      ),
      selector: (row) => parseFloat(row?.amount).toFixed(2),
      minWidth: '110px',
      reorder: true,
      label: 'In USD',
      omit: omitColumn(columnConfig, 'In USD'), 
      cell: ({ currency, amount, inUSD }) => {
        const usdtValue = inUSD ?? getRateInUsdt(currency, amount, currencyRates);
        return (
          usdtValue?.toFixed(2).toString().length >= valueOutputCutLength.balance 
            ? (
              <TooltipComponent 
                title={usdtValue?.toFixed(2).toString()}
              // eslint-disable-next-line react/no-children-prop
                children={(
                  <span style={{ cursor: 'pointer' }}>
                    {prettyCutOutputByCellWidth(usdtValue?.toFixed(2).toString(), 'balance')}
                  </span>
                )}
              />
            )
            : (
              <span>
                {usdtValue?.toFixed(2) ?? '-'}
              </span>
            )
        );
      },
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('userAffiliateFullName')}>
            <div className="d-flex flex-row">
              <span>Affiliate Name</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('userAffiliateFullName')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters
            tabName="Affiliate Name"
            data={allAffiliates}
            filters={affiliateFilter}
            setFilters={setAffiliateFilter}
            searchParams={{ id: '_id', params: ['firstName', 'lastName'], optionsName: ['firstName', 'lastName'] }}
            usersFilters={depositsFilters}
            setUserFilters={setDepositsFilters}
            addNonSelected
          />
        </div>
      ),
      minWidth: '180px',
      selector: ({ userAffiliateFullName }) => userAffiliateFullName ?? '-',
      cell: ({ _id, userAffiliateFullName }) => {
        if (userAffiliateFullName) {
          return (
            userAffiliateFullName.length >= valueOutputCutLength.affiliateFullName
              ? (
                <TooltipComponent
                  title={userAffiliateFullName}
                  // eslint-disable-next-line react/no-children-prop
                  children={(
                    <div className="truncate-text" style={{ cursor: 'pointer' }} data-for={`aff-${_id}`} data-tip={`${userAffiliateFullName}`}>
                      {prettyCutOutputByCellWidth(userAffiliateFullName, 'affiliateFullName')}
                    </div>
                  )}
                />
              )
              : (
                <div
                  className="truncate-text"
                  data-for={`aff-${_id}`}
                  data-tip={`${userAffiliateFullName}`}
                >
                  {userAffiliateFullName}
                </div>
              )
          );
        }
        return '-';
      },
      reorder: true,
      label: 'Affiliate Name',
      omit: omitColumn(columnConfig, 'Affiliate Name') || !isAffiliateRule,
    },
    {
      name: (
        <div className="d-flex flex-row search-input-des p-2 pl-0" style={{ alignItems: 'start' }}>
          <SortWrapper handleSort={() => sortFields('userSource')}>
            <div className="d-flex flex-row">
              <span>Funnel Name</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('userSource')} />
              </button>
            </div>
          </SortWrapper>
        </div>
      ),
      minWidth: '170px',
      omit: omitColumn(columnConfig, 'Funnel Name'),
      reorder: true,
      label: 'Funnel Name',
      selector: ({ _id, userSource = '-' }) => (
        <div>
          {
            userSource?.length >= valueOutputCutLength.funnelName
              ? (
                <TooltipComponent
                  title={userSource}
                  // eslint-disable-next-line react/no-children-prop
                  children={(
                    <div className="truncate-text" style={{ cursor: 'pointer', margin: '5px' }} data-for={`aff-${_id}`} data-tip={`${userSource}`}>
                      {prettyCutOutputByCellWidth(userSource, 'funnelName')}
                    </div>
                  )}
                />
              )
              : (
                <div className="truncate-text" style={{ margin: '5px' }} data-for={`funnel-${_id}`} data-tip={userSource}>
                  {userSource ?? '-'}
                </div>
              )
          }
        </div>
      ),
    },
    {
      name: (
        <div className="d-flex flex-row search-input-des p-2 pl-0" style={{ alignItems: 'start' }}>
          <SortWrapper handleSort={() => sortFields('userAdditionalInfo')}>
            <div className="d-flex flex-row">
              <span>Additional Info</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('userAdditionalInfo')} />
              </button>
            </div>
          </SortWrapper>
        </div>
      ),
      minWidth: '170px',
      omit: omitColumn(columnConfig, 'Additional Info'),
      reorder: true,
      label: 'Additional Info',
      selector: ({ _id, userAdditionalInfo }) => (
        <div>
          {
            userAdditionalInfo?.length >= valueOutputCutLength.additionalInfo
              ? (
                <TooltipComponent
                  title={userAdditionalInfo}
                  // eslint-disable-next-line react/no-children-prop
                  children={(
                    <div className="truncate-text" style={{ cursor: 'pointer', margin: '5px' }} data-for={`aff-${_id}`} data-tip={`${userAdditionalInfo}`}>
                      {prettyCutOutputByCellWidth(userAdditionalInfo, 'additionalInfo')}
                    </div>
                  )}
                />
              )
              : (
                <div className="truncate-text" style={{ margin: '5px' }} data-for={`funnel-${_id}`} data-tip={userAdditionalInfo}>
                  {userAdditionalInfo || '-'}
                </div>
              )
          }
        </div>
      ),
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('isResolved')}>
            <div className="d-flex flex-row">
              <span>Status</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('isResolved')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters
            tabName="Status" 
            data={[{ name: 'Pending', _id: '0' }, { name: 'Completed', _id: '1' }, { name: 'Declined', _id: '2' }]}
            filters={statusFilter} 
            setFilters={setStatusFilter}
            searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
            usersFilters={depositsFilters}
            setUserFilters={setDepositsFilters}
          />
        </div>
      ),
      minWidth: '175px',
      omit: omitColumn(columnConfig, 'Status'), 
      reorder: true,
      label: 'Status',
      selector: ({
        _id, isResolved, additionalInfo, comment,
      }) => {
        const additionalInfoObj = JSONparser(additionalInfo);
        return (
          <>
            {isResolved === 0 ? (
              <span className="badge rounded-pill bg-warning">Pending</span>
            ) : isResolved === 1 ? (
              <span className="badge rounded-pill bg-success">Completed</span>
            ) : (
              <span className="badge rounded-pill bg-danger">Declined</span>
            )}
      
            <FontAwesomeIcon
              icon={faCircleInfo}
              className="ms-2"
              size="lg"
              color="#6E7F95"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                handleAdditionalInfoModalToggle(_id);
              }}
            />
      
            {isAdditionalInfoModalShown === _id ? (
              <div className="depositsAdditionalInfoModal">
                <h5>Additional Info</h5>
                <FontAwesomeIcon
                  icon={faTimes}
                  size="lg"
                  color="#6E7F95"
                  onClick={() => setIsAdditionalInfoModalShown('')}
                />
                {additionalInfoObj ? (
                  Object.hasOwnProperty.call(additionalInfoObj, 'name') ? (
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                      <span>
                        Name:
                        {additionalInfoObj.name}
                      </span>
                      <span>
                        Card:
                        {additionalInfoObj.number}
                      </span>
                      <span>
                        CVC:
                        {additionalInfoObj.cvc.value}
                      </span>
                      <span>
                        Expire Date:
                        {additionalInfoObj.expiry}
                      </span>
                    </div>
                  ) : (
                    additionalInfo
                  )
                ) : (
                  additionalInfo?.length ? additionalInfo : 'No Info'
                )}
              </div>
            ) : null}
      
            <FontAwesomeIcon
              icon={faComment}
              className="ms-2"
              size="lg"
              color="#6E7F95"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                handleAdditionalCommentModalToggle(_id);
              }}
            />
      
            {isAdditionalCommentModalShown === _id && (
            <div className="depositsAdditionalCommentModal">
              <h5>Comment</h5>
              <FontAwesomeIcon
                icon={faTimes}
                size="lg"
                color="#6E7F95"
                onClick={() => setIsAdditionalCommentModalShown('')}
              />
              <div className="depositsAdditionalCommentModalIn">
                {comment && comment.length ? comment : 'No comments'}
              </div>
            </div>
            )}
          </>
        );
      },
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('createdAt')}>
            <div className="d-flex flex-row">
              <span>Time Initiated</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('createdAt')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters 
            tabName="Time Initiated" 
            data={dateOptions} 
            filters={timeInitiatedFilter} 
            setFilters={setTimeInitiatedFilter}
            searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
            usersFilters={depositsFilters}
            setUserFilters={setDepositsFilters}
            isDateOptions
          />
        </div>
      ),
      selector: ({ createdAt }) => (createdAt ? formatDate(new Date(createdAt)) : ''),
      omit: omitColumn(columnConfig, 'Time Initiated'),
      reorder: true,
      label: 'Time Initiated',
      minWidth: '154px',
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('processedAt')}>
          <div className="d-flex flex-row">
            <span>Transaction Time</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('processedAt')} />
            </button>
          </div>
        </SortWrapper>
      ),
      omit: omitColumn(columnConfig, 'Transaction Time'), 
      reorder: true,
      label: 'Transaction Time',
      selector: ({ processedAt }) => (processedAt ? formatDate(new Date(processedAt)) : '-'),
      minWidth: '154px',
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('isReal')}>
            <div className="d-flex flex-row">
              <span>Real/Fake</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('isReal')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters
            tabName="Real/Fake" 
            data={[{ name: 'Fake', _id: false }, { name: 'Real', _id: true }]}
            filters={realFilter} 
            setFilters={setRealFilter}
            searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
            usersFilters={depositsFilters}
            setUserFilters={setDepositsFilters}
          />
        </div>
      ),
      minWidth: '130px',
      reorder: true,
      label: 'Real/Fake',
      omit: omitColumn(columnConfig, 'Real/Fake'), 
      selector: ({ isReal }) => (isReal !== undefined ? (isReal === true ? <span className="clr-green">Real</span> : <span className="clr-red">Fake</span>) : '-'),
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('type')}>
            <div className="d-flex flex-row">
              <span>Source</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('type')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters
            tabName="Source" 
            data={[
              { name: 'Admin Transaction', _id: 'Admin Transaction' }, 
              { name: 'Crypto', _id: 'Crypto' },
              { name: 'Card', _id: 'Card' },
              { name: 'Bank', _id: 'Bank' },
            ]}
            filters={sourceFilter} 
            setFilters={setSourceFilter}
            searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
            usersFilters={depositsFilters}
            setUserFilters={setDepositsFilters}
          />
        </div>
      ),
      minWidth: '130px',
      reorder: true,
      label: 'Source',
      omit: omitColumn(columnConfig, 'Source'), 
      selector: ({ type }) => (type),
    },
    {
      name: 'Action(s)',
      minWidth: '250px',
      omit: omitColumn(columnConfig, 'Actions'), 
      reorder: true,
      label: 'Actions',
      cell: (row) => setTransactionActionColumn(
        row, 
        permissionName,
        resolveCurrentDepositTransaction,
        updateIsReal,
        handleRevertTransaction,
        handleDeleteTransaction,
        isDeleted,
        dispatch,
        isSuperUser,
      ),
    },
  ];

  return sortColumnByOrderConfig(columns, columnConfig);
};
