import {
  DISPLAY_TRANSACTIONS,
  DISPLAY_ALL_ADMIN_DEPOSITS,
  GET_ASSET_DETAILS,
  GET_DEPOSIT_ORDER_DETAILS,
  TOGGLE_TRANSACTION_STATE,
  TOGGLE_DEPOSIT_ORDER_DETAILS_STATE,
  ALL_PENDING_DEPOSITS,
  ALL_PENDING_WITHDRAWALS,
  DISPLAY_ALL_ADMIN_WITHDRAWS,
  ALL_WITHDRAWALS,
  ALL_DEPOSITS,
  ALL_USER_WITHDRAWALS,
  UPDATE_ISREAL_TRANSACTION,
  DELETE_TRANSACTION_DEP,
  EXPORT_FILTERED_DATA_TO_FILE,
  GET_TRANSACTIONS_BY_QUERY,
  GET_WITHDRAWS_BY_QUERY, UPDATE_TRANSACTION_FIELDS,
  MASS_DELETE_TRANSACTIONS,
  SET_PENDING_TRANSACTION_COUNT,
} from './transactionTypes';

const initialState = {
  transactions: [],
  pendingDeposits: 0,
  pendingWithdrawals: 0,
  allAdminDeposits: [],
  allAdminWithdrawals: [],
  allWithdrawals: { paginatedData: [], totalCount: 0 },
  allUserWithdrawals: [],
  allDeposits: { paginatedData: [], totalCount: 0 },
  assetDetails: [],
  exportFilterdDataToFile: false,
  depositOrderDetails: {},
  fetched: false,
  fetchedAssetDetails: false,
  fetchedDepositOrderDetails: false,
  transactionUpdated: false,
  success: false,
};

const transactionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PENDING_TRANSACTION_COUNT:
      return {
        ...state,
        pendingDeposits: action.payload.depositsCount,
        pendingWithdrawals: action.payload.withdrawalsCount,
      };
    case MASS_DELETE_TRANSACTIONS:
      return {
        ...state,
        allDeposits: {
          totalCount: state.allDeposits.totalCount - action.payload.length,
          paginatedData: state?.allDeposits?.paginatedData?.filter((deposit) => !action.payload.includes(deposit._id)),
        },
        allWithdrawals: {
          totalCount: state.allWithdrawals.totalCount - action.payload.length,
          paginatedData: state?.allWithdrawals?.paginatedData?.filter((withdrawal) => !action.payload.includes(withdrawal._id)),
        },
      };
    case DISPLAY_TRANSACTIONS:
      return {
        ...state,
        transactions: action.payload.transactions,
        fetched: true,
      };
    case DISPLAY_ALL_ADMIN_DEPOSITS:
      return {
        ...state,
        allAdminDeposits: action.payload,
        fetched: true,
      };
    case DISPLAY_ALL_ADMIN_WITHDRAWS:
      return {
        ...state,
        allAdminWithdrawals: action.payload,
        fetched: true,
      };
    case GET_ASSET_DETAILS:
      return {
        ...state,
        assetDetails: action.payload,
        fetchedAssetDetails: true,
      };
    case ALL_PENDING_DEPOSITS:
      return {
        ...state,
        pendingDeposits: action.payload,
        fetched: true,
      };
    case ALL_WITHDRAWALS:
      return {
        ...state,
        allWithdrawals: {
          ...state.allWithdrawals,
          paginatedData: action.payload,
        },
        fetched: true,
      };
    case ALL_USER_WITHDRAWALS:
      return {
        ...state,
        allUserWithdrawals: action.payload,
        fetched: true,
      };
    case ALL_DEPOSITS:
      return {
        ...state,
        allDeposits: action.payload,
        fetched: true,
      };
    case ALL_PENDING_WITHDRAWALS:
      return {
        ...state,
        pendingWithdrawals: action.payload,
        fetched: true,
      };
    case GET_DEPOSIT_ORDER_DETAILS:
      return {
        ...state,
        depositOrderDetails: action.payload,
        fetchedDepositOrderDetails: true,
      };
    case UPDATE_TRANSACTION_FIELDS:
      return {
        ...state,
        allDeposits: {
          ...state.allDeposits,
          paginatedData: state.allDeposits.paginatedData.map((deposit) => {
            if (deposit._id === action.payload._id) {
              return { ...deposit, ...action.payload };
            }

            return deposit;
          }),
        },
        allWithdrawals: {
          ...state.allWithdrawals,
          paginatedData: state.allWithdrawals?.paginatedData?.map((withdrawal) => {
            if (withdrawal._id === action.payload._id) {
              return { ...withdrawal, ...action.payload };
            }

            return withdrawal;
          }),
        },
        allUserWithdrawals: state.allUserWithdrawals.map((withdrawal) => {
          if (withdrawal._id === action.payload._id) {
            return { ...withdrawal, ...action.payload };
          }

          return withdrawal;
        }),
        success: true,
        transactionUpdated: true,
      };
    case UPDATE_ISREAL_TRANSACTION:
      return {
        ...state,
        allDeposits: {
          ...state.allDeposits,
          paginatedData: state.allDeposits.paginatedData.map((deposit) => {
            if (deposit._id === action.payload) {
              return { ...deposit, isReal: !deposit.isReal };
            }

            return deposit;
          }),
        },
        allWithdrawals: {
          ...state.allWithdrawals,
          paginatedData: state.allWithdrawals?.paginatedData?.map((withdrawal) => {
            if (withdrawal._id === action.payload) {
              return { ...withdrawal, isReal: !withdrawal.isReal };
            }

            return withdrawal;
          }),
        },
        allUserWithdrawals: state.allUserWithdrawals.map((withdrawal) => {
          if (withdrawal._id === action.payload) {
            return { ...withdrawal, isReal: !withdrawal.isReal };
          }

          return withdrawal;
        }),
        success: true,
        transactionUpdated: true,
      };
    case TOGGLE_DEPOSIT_ORDER_DETAILS_STATE:
      return {
        ...state,
        fetchedDepositOrderDetails: false,
      };
    case TOGGLE_TRANSACTION_STATE:
      return {
        ...state,
        fetched: false,
        fetchedAssetDetails: false,
        exportFilterdDataToFile: false,
      };
    case DELETE_TRANSACTION_DEP:
      return {
        ...state,
        allDeposits: {
          totalCount: state.allDeposits.totalCount - 1,
          paginatedData: state?.allDeposits?.paginatedData?.filter((deposit) => deposit._id !== action.payload),
        },
        allWithdrawals: {
          totalCount: state.allWithdrawals.totalCount - 1,
          paginatedData: state?.allWithdrawals?.paginatedData?.filter((withdrawal) => withdrawal._id !== action.payload),
        },
        allUserWithdrawals: state?.allUserWithdrawals?.filter((withdrawal) => withdrawal._id !== action.payload),
        success: true,
        transactionUpdated: true,
      };
    case EXPORT_FILTERED_DATA_TO_FILE:
      return {
        ...state,
        exportFilterdDataToFile: action.payload,
      };
    case GET_TRANSACTIONS_BY_QUERY:
      return {
        ...state,
        allDeposits: action.payload,
        success: true,
        transactionUpdated: true,
      };
    case GET_WITHDRAWS_BY_QUERY:
      return {
        ...state,
        allWithdrawals: action.payload,
        fetched: true,
      };
    default:
      return state;
  }
};

export default transactionReducer;

