import {
  GET_USER_BANK_ACCOUNT, 
  UPDATE_USER_BANK_ACCOUNT, 
  DELETE_USER_BANK_ACCOUNT, 
  ADD_USER_BANK_ACCOUNT, 
  UPDATE_STATUS_USER_BANK_ACCOUNT,
} from './userBankAccountTypes';

const initialState = {
  paginatedResult: [],
  totalCount: 0,
};

const userBankAccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_BANK_ACCOUNT:
      return {
        ...state,
        paginatedResult: action.payload.paginatedResult,
        totalCount: action.payload.totalCount,
      };
    case ADD_USER_BANK_ACCOUNT:
      return {
        ...state,
        paginatedResult: [...state.paginatedResult, action.payload],
        totalCount: state.totalCount + 1,
      };
    case UPDATE_USER_BANK_ACCOUNT:
      return {
        ...state,
        paginatedResult: state.paginatedResult.map((item) => (item._id === action.payload._id ? action.payload : item)),
      };
    case UPDATE_STATUS_USER_BANK_ACCOUNT:
      return {
        ...state,
        paginatedResult: state.paginatedResult.map((item) => (item._id === action.payload._id ? action.payload : item)),
      };
    case DELETE_USER_BANK_ACCOUNT:
      return {
        ...state,
        paginatedResult: state.paginatedResult.filter((item) => item._id !== action.payload._id),
      };  
    default:
      return state;
  }
};

export default userBankAccountReducer;
