import React, {
  useState, useEffect, useContext, useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ModalContext } from '../../context';
import {
  getUserDetails, showAllUsers,
} from '../../redux/users/userActions';
import { getIsUserInScope, setUserIsInScopeByType } from '../../redux/hierarchy/hierarchyActions';
import { GET_IS_USER_IN_SCOPE } from '../../redux/hierarchy/hierarchyTypes';
import socket from '../../services/socket';
import { AssignToManagerModal } from './AssignToManagerModal';
import UserInfo from './UserInfo';
import UserTabs from './UserTabs';

function UserDetail() {
  const { id } = useParams();
  const { showModal, hideModal } = useContext(ModalContext);
  const dispatch = useDispatch();

  const [user, setUser] = useState('');
  const [activeTab, setActveTab] = useState('edit');
  const [selectedUserType, setSelectedUserType] = useState(1);

  const userData = useSelector((state) => state?.users?.user);
  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions);
  const allUsersList = useSelector((state) => state?.users?.users);
  const userIds = useMemo(() => allUsersList.map((user) => user._id), [allUsersList]);
  
  
  const { isUserInScope } = useSelector((state) => state.hierarchy);
  const crmUsers = useSelector((state) => state.crmUser?.crmUsers);

  const [onlineUsers, setOnlineUsers] = useState({});

  const isUserCanSeeClients = useMemo(() => permissionName && permissionName.includes('clients'), [permissionName]);

  const userIndex = useMemo(() => allUsersList.indexOf(allUsersList.filter((item) => item._id === user._id)[0]), [allUsersList, user._id]);
  const prevUser = useMemo(() => (userIndex > 0 ? allUsersList[userIndex - 1] : allUsersList[allUsersList.length - 1]), [userIndex, allUsersList]);
  const nextUser = useMemo(() => (userIndex < allUsersList.length - 1 ? allUsersList[userIndex + 1] : allUsersList[0]), [userIndex, allUsersList]);

  const onlineUserIds = useMemo(() => Object.values(onlineUsers), [onlineUsers]);

  useEffect(() => {
    setTimeout(() => {
      socket.emit('getOnlineCRMUsers');
    }, 1000);
    
    socket.on('recieveOnlineCRMUsers', ({ onlineUsers = {} }) => {
      if (Object.keys(onlineUsers).length) {
        const onlineUsersIds = Object.values(onlineUsers);
        let isNeedToChange = false;

        // eslint-disable-next-line no-restricted-syntax
        for (const userId of onlineUsersIds) {
          if (userIds.includes(userId)) {
            isNeedToChange = true;

            break;
          }
        };

        if (isNeedToChange) {
          setOnlineUsers(onlineUsers);
        }
      }
    });

    return () => {
      socket.off('recieveOnlineCRMUsers');
    };
  }, [userIds]);

  useEffect(() => {
    const savedTab = localStorage.getItem('user-details-tab');
    setActveTab(savedTab || 'edit');

    return () => {
      localStorage.setItem('user-details-tab', 'edit');
      dispatch(setUserIsInScopeByType({ type: GET_IS_USER_IN_SCOPE, value: undefined }));
    };
  }, []);

  useEffect(() => {
    dispatch(getIsUserInScope(id));
  }, [id]);

  useEffect(() => {
    if (isUserInScope) {
      dispatch(getUserDetails(id));
    }
  }, [isUserInScope, id]);

  const parseData = (data) => {
    try {
      return JSON.parse(data);
    } catch (error) {
      return null;
    }
  };

  useEffect(() => {
    const fetchData = () => {
      const loginData = localStorage.getItem('user');
      const data = JSON.parse(loginData);
      const id = data?.roleId;
      const uid = data?._id;
      const storage = userData.clientType === 2 ? 'clients' : 'leads';
      const filtersStorageName = `DataTable_users/${storage}_filters`; 
      const paginationStorageName = `DataTable_users/${storage}_pagination`;
      const pageStorageName = `DataTable_users/${storage}_page`;
      const sortParamsStorageName = `DataTable_users/${storage}_sortParams`;
      const filters = parseData(localStorage.getItem(filtersStorageName)) || {};
      const pagination = parseData(localStorage.getItem(paginationStorageName)) || {};
      const page = parseData(localStorage.getItem(pageStorageName)) || 1;
      const sortParams = parseData(localStorage.getItem(sortParamsStorageName)) || {};
      const rowsPerPage = pagination?.limit || 25;
      dispatch(showAllUsers('1', id, uid, userData.clientType, '', page, rowsPerPage, filters, {}, sortParams));
    };
    if (!allUsersList.length) fetchData();
  }, [user]);

  useEffect(() => {
    if (userData.clientType && permissionName?.length) {
      document.title = userData.firstName && userData.lastName ? `${userData.firstName} ${userData.lastName}` : 'User Detail';
      if (isUserCanSeeClients) {
        setUser(userData);
        const { clientType } = userData;
        setSelectedUserType(clientType);
      }
    }
  }, [userData, permissionName]);

  const assignToModalUser = () => {
    const header = selectedUserType === 1 ? 'Promote to Client' : 'Demote to Lead';
    const manager = crmUsers.find((crmUser) => crmUser._id === user.assignedTo);
    const defaultManagerOption = manager ? { label: `${manager.firstName} ${manager.lastName}`, value: manager._id } : null;

    showModal({
      headerContent: <h3>{header}</h3>,
      bodyContent: <AssignToManagerModal 
        hideModal={hideModal}
        selectedUserType={selectedUserType}
        user={user}
        defaultManagerOption={defaultManagerOption}
      />,
    });
  };

  const setTabWithHistory = (tab) => {
    setActveTab(tab);
    localStorage.setItem('user-details-tab', tab);
  };

  return (
    user ? (
      <div className="content-wrapper right-content-wrapper">
        <div className="content-box">
          <UserInfo user={user} onlineUserIds={onlineUserIds} assignToModalUser={assignToModalUser} prevUser={prevUser} nextUser={nextUser} />
          <UserTabs activeTab={activeTab} setTabWithHistory={setTabWithHistory} user={user} permissionName={permissionName} />
        </div>
      </div>
    ) : isUserInScope === false && (
      <div className="content-wrapper right-content-wrapper">
        <div className="content-box">
          <div className="tab-pane fade show active tab-main-box" id="home" role="tabpanel" aria-labelledby="home-tab">
            <h3 className="text-capitalize">User not found</h3>
          </div>
        </div>
      </div>
    )
  );
}

export default UserDetail;
