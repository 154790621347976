import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { formatDate } from './helper';
import { ModalFilters } from '../components';
import { selectAllCountries } from '../redux/countries/countriesSelectors';
import { useTableSorting, SortWrapper } from '../helpers/sortHelper';
import SortIcon from '../components/SortIcon';

export const CRMActivityColumns = (
  userFilters, 
  setUserFilters,
  crmUsersFilter,
  setCrmUsersFilter,
  crmActivityTypeFilter, 
  setCrmActivityTypeFilter,
  clientNameFilter,
  setClientNameFilter,
  handleSort,
  clientCustomIdFilter,
  setClientCustomIdFilter,
) => {
  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions);
  const crmUsers = useSelector((state) => state.crmUser.crmUsers);
  const activityTypes = useSelector((state) => state.activityType.activityTypes);
  const countries = useSelector(selectAllCountries);

  const makeListOfRecords = ({ state = [], ids = [], nameField = '' }) => {
    let list = '';

    // eslint-disable-next-line no-restricted-syntax
    for (const record of state) {
      if (ids.includes(record._id)) {
        if (list.length < 25) {
          list += `${record[nameField]}, `;
        } else {
          break;
        }
      }
    }

    list = list.length ? `${list.slice(0, -2)}...and ${list.length - 2} more` : list;

    return list;
  };

  const makeListOfCountries = (text) => {
    const cutOffIndex = Math.min(
      text.indexOf('to') !== -1 ? text.indexOf('to') : text.length,
      text.indexOf('from') !== -1 ? text.indexOf('from') : text.length,
    );
    if (cutOffIndex !== text.length) {
      const keyword = text.slice(cutOffIndex, cutOffIndex + 4).trim();
      const firstTextPart = text.slice(0, cutOffIndex + keyword.length);

      const substring = text.slice(cutOffIndex + keyword.length).trim();
      const ids = substring.split(',').map((id) => id.trim()).filter((id) => id);
      const prettyCountriesOutput = makeListOfRecords({ state: countries, ids, nameField: 'nicename' });

      return `${firstTextPart}: ${prettyCountriesOutput}`;
    }
    return text;
  };

  const checkPsp = (row) => {
    const regex = /(includedCountries|excludedCountries)/;
    if (row?.activityType?.name === 'PSP Update') {
      if (regex.test(row?.information)) {
        return makeListOfCountries(row?.information);
      }
    };
    return row?.information;
  };

  const { sortFields, getSortIcon } = useTableSorting(handleSort);

  const crmActivityColumns = [
    {
      name: (
        <SortWrapper handleSort={() => sortFields('createdAt')}>
          <div className="d-flex flex-row">
            <span>Time</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('createdAt')} />
            </button>
          </div>
        </SortWrapper>
      ),
      selector: (row) => (
        <span>
          {formatDate(new Date(row?.createdAt))}
        </span>
      ),
      minWidth: '150px',
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('crmUser.firstName crmUser.lastName')}>
            <div className="d-flex flex-row">
              <span>CRM User</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('crmUser.firstName crmUser.lastName')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters 
            tabName="CRM User" 
            data={crmUsers} 
            filters={crmUsersFilter} 
            setFilters={setCrmUsersFilter}
            searchParams={{ id: '_id', params: ['firstName', 'lastName'], optionsName: ['firstName', 'lastName'] }}
            usersFilters={userFilters}
            setUserFilters={setUserFilters}
          />
        </div>
      ),
      selector: (row) => {
        if (row?.crmUser?._id) {
          return `${row?.crmUser?.firstName} ${row?.crmUser?.lastName}`;
        }
        return '-';
      },
      cell: (row) => {
        if (row?.crmUser?._id) {
          return permissionName
            && permissionName.length > 0
            && permissionName.includes('edit_crm_user') ? (
              <Link
                to={`/edit-admin/${row?.crmUserId}`}
                className="text-decoration-none"
              >
                <span className="full-name-text"> 
                  {`${row?.crmUser?.firstName} ${row?.crmUser?.lastName}`}
                </span>
              </Link>
            ) : (
              <span>{`${row?.crmUser?.firstName} ${row?.crmUser?.lastName}`}</span>
            );
        }
        console.log(row?.crmUserId);
        return '-';
      },
      minWidth: '150px',
    },
    {
      name: (
        <div className="d-flex flex-column search-input-des pt-2" style={{ alignItems: 'start' }}>
          <SortWrapper handleSort={() => sortFields('client.firstName client.lastName')}>
            <div className="d-flex flex-row">
              <span>Client</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('client.firstName client.lastName')} />
              </button>
            </div>
          </SortWrapper>
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            autoComplete="off"
            name="clientFullname"
            value={clientNameFilter}
            minLength="3"
            onChange={({ target: { value } }) => setClientNameFilter(value)}
          />
        </div>
      ),
      selector: (row) => {
        if (row?.client?._id) {
          return `${row?.client?.firstName} ${row?.client?.lastName}`;
        }
        return '-';
      },
      cell: (row) => {
        if (row?.client?._id) {
          return permissionName
            && permissionName.length > 0
            && permissionName.includes('edit_user') ? (
              <Link
                to={`/user-detail/${row?.client?._id}`}
                className="text-decoration-none"
              >
                <span
                  className="full-name-text"
                >
                  {`${row?.client?.firstName} ${row?.client?.lastName}`}
                </span>
              </Link>
            ) : (
              <span>{`${row?.client?.firstName} ${row?.client?.lastName}`}</span>
            );
        }
        return '-';
      },
      minWidth: '150px',
    },
    {
      name: (
        <div className="d-flex flex-column search-input-des pt-2" style={{ alignItems: 'start' }}>
          <SortWrapper handleSort={() => sortFields('client.customId')}>
            <div className="d-flex flex-row">
              <span>Client ID</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('client.customId')} />
              </button>
            </div>
          </SortWrapper>
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            autoComplete="off"
            name="clientCustomId"
            value={clientCustomIdFilter}
            minLength="3"
            onChange={({ target: { value } }) => setClientCustomIdFilter(value)}
          />
        </div>
      ),
      selector: (row) => row?.client?.customId ?? '-',
      minWidth: '150px',
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('activityType.name')}>
            <div className="d-flex flex-row">
              <span>Activity</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('activityType.name')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters
            tabName="Activity" 
            data={activityTypes} 
            filters={crmActivityTypeFilter} 
            setFilters={setCrmActivityTypeFilter} 
            searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
            usersFilters={userFilters}
            setUserFilters={setUserFilters}
          />
        </div>
      ),
      selector: (row) => (row?.activityType ? row?.activityType?.name : ''),
      cell: (row) => <span>{row?.activityType?.name}</span>,
      minWidth: '150px',
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('information')}>
          <div className="d-flex flex-row">
            <span>Information</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('information')} />
            </button>
          </div>
        </SortWrapper>
      ),
      selector: (row) => row?.information,
      cell: (row) => <span>{checkPsp(row)}</span>,
      minWidth: '150px',
    },
  ];
  return crmActivityColumns;
};
