/* eslint-disable react/no-children-prop */
import React, {
  useState, useMemo, useContext,
} from 'react';
import { Link } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClone, faComment, faEnvelope, faPhone, faExternalLink, faSms,
  faLessThanEqual,
} from '@fortawesome/free-solid-svg-icons';
import * as Flags from 'react-flags-select';
import { toast } from 'react-toastify';
import { BsTrashFill } from 'react-icons/bs';
import {
  FormControl, InputLabel, ListSubheader, MenuItem, Select,
} from '@mui/material';
import { editUser } from '../redux/users/userActions';
import { ModalFilters, getLocalDataByCountryCode } from '../components';
import {
  dateOptions, prettyCutOutputByCellWidth, valueOutputCutLength, sortColumnByOrderConfig, formatDate,
} from './helper';
import { createUserStatusChangesHistory } from '../redux/userStatusChangesHistory/userStatusChangesHistoryActions';
import { apiHelper } from '../redux/apiHelper';
import SendSmtpEmailModal from '../pages/Users/UserDetailComponents/SendSmtpEmailModal/SendSmtpEmailModal';
import { ModalContext } from '../context';
import SendSmsModal from '../pages/Users/UserDetailComponents/SendSmsModal/SendSmsModal';
import { TooltipComponent } from '../components/TooltipComponent/TooltipComponent';
import SortIcon from '../components/SortIcon';
import { SortWrapper, useTableSorting } from '../helpers/sortHelper';
import { RangeModalDebounceFilter } from '../pages/Transactions/modalFilters';
import { assignToShowOptionGroupsCreator } from '../helpers/helper';
import { SALES_ROLES, SALES_STATUSES } from '../pages/AffiliatesDashboard/utils/optionsHelper';
import { UPDATE_USER_IN_LIST } from '../redux/users/userTypes';
import { TableSelectWithGroupComponent } from '../components/SelectComponent/TableSelectWithGroupComponent';
import { TableSelectComponent } from '../components/SelectComponent/TableSelectComponent';

const currencyFormatter = require('currency-formatter');

const actionButtonStyle = {
  width: '100%',
  display: 'flex',
  gap: '3px',
  position: 'relative',
  justifyContent: 'flex-start',
};

const commentModalStyle = {
  width: '200px',
  padding: '10px',
  left: 25,
  top: -80,
  maxHeight: '300px',
  overflowY: 'auto',
};

const renderCommentModal = (showComment, _id, lastComment, lastCommentAt) => (
  <div
    className="depositsAdditionalInfoModal"
    style={{
      display: showComment === _id ? 'flex' : 'none',
      ...commentModalStyle,
    }}
  >
    <h6>Comments</h6>
    <br />
    <br />
    {lastComment?.length ? (
      <>
        {`Date: [${formatDate(new Date(lastCommentAt))}]`}
        <br />
        <br />
        <div style={{ whiteSpace: 'pre-line', maxWidth: '550px' }}>
          {`Comment: ${lastComment.replace(/<br\s*\/?>/gi, '\n')}`}
        </div>
      </>
    ) : (
      'No Comments'
    )}
  </div>
);

const renderActionButton = (icon, onClick) => (
  <button type="button" className="datatable-action__btn" onClick={onClick}>
    <FontAwesomeIcon icon={icon} size="sm" />
  </button>
);

const swappedSalesRoles = Object.fromEntries(
  Object.entries(SALES_ROLES).map(([key, value]) => [value, key]),
);

const onlineFilterPresets = [
  { _id: 'online', firstName: 'Online', lastName: '' },
  { _id: 'offline', firstName: 'Offline', lastName: '' },
];

export const UsersSchema = (
  userType,
  permissionName,
  formatDate,
  allAffiliates,
  affiliateFilter,
  setAffiliateFilter,
  salesStatuses,
  countriesToShow,
  userAction,
  isCheckStatus,
  setIsCheckStatus,
  handleStatusChange2,
  assignToShow,
  isCheckAssignTo,
  deleteAction,
  clientFullname,
  searchByName,
  clientEmail,
  clientSecondEmail,
  searchByEmail,
  searchBySecondEmail,
  clientPhone,
  clientSecondPhone,
  searchByPhone,
  searchBySecondPhone,
  clientLanguage,
  searchByClientLanguage,
  isCheckCountry,
  setIsCheckCountry,
  setIsCheckAssignTo,
  columnConfig,
  usersFilters,
  setUserFilters,
  setTimeInitiatedFilter,
  timeInitiatedFilter,
  lastCommentDate,
  setLastCommentDate,
  lastActionDate,
  setLastActionDate,
  firstTimeDepositFilter,
  setFirstTimeDepositFilter,
  isCheckFtdOwner,
  setIsCheckFtdOwner,
  isCheckStdOwner,
  setIsCheckStdOwner,
  lastPromotion,
  setLastPromotion,
  lastDemotion,
  setLastDemotion,
  lastAssignment,
  setLastAssignment,
  isCheckPreviousOwner,
  setIsCheckPreviousOwner,
  lastLoginAt,
  setLastLoginAt,
  lastOnline,
  setLastOnline,
  lastCallDate,
  isCheckOnlineStatuses,
  setIsCheckOnlineStatuses,
  setLastCallDate,
  brands,
  selectedBrands,
  setSelectedBrands,
  currencyRates,
  addCommentHandler = () => {},
  handleSort,
  onlineUsers,
  refresh,
  totalCallsRange,
  setTotalCallsRange,
  setDebounceTotalCallsRange,
  ibdeskToShow,
  ibdeskFilterPreset,
  setIbdeskFilterPreset,
  managerFilterPreset,
  setManagerFilterPreset,
  idFilter,
  setIdFilter,
  additionalInfoFilter,
  setAdditionalInfoFilter,
  lastCommentFilter,
  searchByLastComment,
  funnelNameFilterOptions = [],
  funnelNames,
  setFunnelNames,
) => {
  const dispatch = useDispatch();
  const CRMUser = useSelector((state) => state.crmUser.crmUserInfo);
  const { currentUserPermissions } = useSelector((state) => state.crmUser);
  const isUserCanSeeComments = useMemo(() => currentUserPermissions && currentUserPermissions.length > 0 && currentUserPermissions.includes('comments'), [currentUserPermissions]);
  const isCanEditUser = useMemo(() => currentUserPermissions && currentUserPermissions.length > 0 && currentUserPermissions.includes('edit_user'), [currentUserPermissions]);
  const selectedPrefix = useSelector((state) => state.voip.selectedPrefix);

  const [showComment, setShowComment] = useState('');
  const onlineUserIds = useMemo(() => Object.values(onlineUsers), [onlineUsers]);

  const filteredSalesStatuses = useMemo(
    () => salesStatuses.filter((status) => status.type === SALES_STATUSES[swappedSalesRoles[userType]] || status.type === SALES_STATUSES.BOTH),
    [salesStatuses],
  );

  const { sortFields, getSortIcon } = useTableSorting(handleSort);

  const copyReferral = () => {
    toast.success('Successfully copied!', {
      autoClose: 1000,
    });
  };
  const titleCase = (string) => string[0].toUpperCase() + string.slice(1).toLowerCase();

  const handleShowComment = (rowId) => {
    setShowComment(rowId);
  };

  const handleDropdownChange = async (data, id) => {
    try {
      // dispatch({
      //   type: UPDATE_USER_IN_LIST,
      //   payload: {
      //     _id: id,
      //     ...data,
      //   },
      // });

      await dispatch(editUser(id, data, false, false));
    } catch (error) {
      toast.error(error);
    }
  };

  const handleChangeAssignTo = async (e, id) => {
    try {
      const assignedTo = e.target.value;
      const assignedToObject = assignToShow.find((item) => item._id === assignedTo);
      const assignedToFullName = assignedToObject ? `${assignedToObject.firstName} ${assignedToObject.lastName}` : '';
      const newSalesStatus = salesStatuses.find((status) => status._id === assignedTo) ?? null;
      const salesStatusLabel = newSalesStatus ? newSalesStatus.name : '';

      const payload = {
        _id: id,
        assignedTo,
        assignedToFullName,
      };

      if (salesStatusLabel) payload.salesStatusLabel = salesStatusLabel;

      const data = {
        assignedTo,
      };
      await dispatch(editUser(id, data, true, false));
    } catch (error) {
      toast.error(error);
    }
  };

  const handleStatusChange = async (e, user) => {
    try {
      const { _id: id, salesStatus } = user;
      const salesStatusId = e.target.value;

      

      const noStatusMock = { _id: '', name: 'Select Status', color: '#fff' };
      const previousStatus = salesStatus ?? noStatusMock;
      const newSalesStatus = salesStatuses.find((status) => status._id === salesStatusId) ?? noStatusMock;

      dispatch({
        type: UPDATE_USER_IN_LIST,
        payload: {
          _id: id,
          salesStatus: newSalesStatus,
          salesStatusId,
        },
      });

      const promises = [
        dispatch(editUser(id, { salesStatusId }, false, false)),
      ];

      if (previousStatus?._id !== newSalesStatus?._id) {
        promises.push(dispatch(createUserStatusChangesHistory({
          user: id,
          agent: CRMUser._id,
          agentName: `${CRMUser.firstName} ${CRMUser.lastName}`,
          previousStatus: previousStatus.name,
          actualStatus: newSalesStatus.name,
        }, false)));
      }

      Promise.all(promises);
    } catch (error) {
      toast.error(error);
    }
  };

  const omitColumn = (columnName) => {
    let omit = false;

    columnConfig.forEach((column) => {
      if (column.name === columnName) {
        omit = !column.selected;
      }
    });

    return omit;
  };


  const handleClickToCall = async ({ id }) => {
    try {
      const res = await apiHelper('post', `/api/voip/call/${id}`, selectedPrefix ? { selectedPrefix } : {});
      if (res && res.data && res.data.success) {
        const { message } = res.data;

        toast.success(message);
        refresh();
      }
    } catch (error) {
      console.log(error.message);

      toast.error("Error: cant't make call");
    }
  };

  const handleSendEmailBtn = ({
    userId, showModal, hideModal, brandId,
  }) => {
    showModal({
      headerContent: <h3>SEND EMAIL</h3>,
      bodyContent: (
        <SendSmtpEmailModal
          userId={userId}
          handleClose={hideModal}
          brandId={brandId}
        />
      ),
    });
  };

  const handleSendSmsBtn = (user, showModal, hideModal) => {
    showModal({
      headerContent: (
        <h3>
          Send SMS to
          {' '}
          {`${user?.firstName} ${user?.lastName}`}
        </h3>
      ),
      bodyContent: <SendSmsModal
        userId={user._id}
        handleClose={hideModal}
        toPhoneNumber={user?.phone}
      />,
    });
  };

  const isAffiliateRule = useMemo(() => (permissionName && permissionName.length && permissionName.includes('see_affiliate_data_in_table')), [permissionName]);

  const columns = [
    {
      name: (
        <div className="d-flex align-items-start flex-column search-input-des">
          <SortWrapper handleSort={() => sortFields('customId')}>
            <span>ID</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('customId')} />
            </button>
          </SortWrapper>
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            autoComplete="off"
            name="ID"
            value={idFilter || ''}
            onChange={(e) => setIdFilter(e.target.value)}
          />
        </div>
      ),
      label: 'ID',
      reorder: true,
      minWidth: '110px',
      omit: omitColumn('ID'),
      cell: ({ customId }) => {
        if (customId.length >= valueOutputCutLength.id) {
          return (
            <TooltipComponent
              title={customId}
              children={(
                <CopyToClipboard text={customId}>
                  <span style={{ margin: '5px' }}>
                    <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
                    {prettyCutOutputByCellWidth(customId, 'id')}
                  </span>
                </CopyToClipboard>
              )}
            />
          );
        }
        return (
          <CopyToClipboard text={customId}>
            <span style={{ margin: '5px' }}>
              <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
              {customId}
            </span>
          </CopyToClipboard>
        );
      },
    },
    {
      name: 'Actions',
      minWidth: '170px',
      label: 'User Actions',
      reorder: true,
      omit: omitColumn('User Actions'),
      cell: (row) => {
        const {
          lastComment, _id, lastCommentAt, brandId,
        } = row;
        const { showModal, hideModal } = useContext(ModalContext);
        return (
          <div style={actionButtonStyle}>
            {isUserCanSeeComments && (
              <div onMouseEnter={() => handleShowComment(_id)} onMouseLeave={() => setShowComment('')}>
                {renderActionButton(faComment, () => addCommentHandler(row))}
                {renderCommentModal(showComment, _id, lastComment, lastCommentAt)}
              </div>
            )}
            {renderActionButton(faSms, () => handleSendSmsBtn(row, showModal, hideModal))}
            {renderActionButton(faEnvelope, () => handleSendEmailBtn({
              userId: _id, showModal, hideModal, brandId,
            }))}
            {renderActionButton(faPhone, () => handleClickToCall({ id: _id }))}
            <Link to={`/user-detail/${_id}`} target="blank" className="datatable-action__btn">
              <FontAwesomeIcon icon={faExternalLink} size="sm" />
            </Link>
          </div>
        );
      },
    },
    {
      name: (
        <div className="d-flex flex-column search-input-des pl-0" style={{ alignItems: 'start' }}>
          <SortWrapper handleSort={() => sortFields('firstName lastName')}>
            <div className="d-flex flex-row">
              <span>Full Name</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('firstName lastName')} />
              </button>
            </div>
          </SortWrapper>
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            autoComplete="off"
            name="clientFullname"
            value={clientFullname}
            minLength="3"
            onChange={(e) => searchByName(e)}
          />
        </div>
      ),
      minWidth: '200px',
      label: 'Full Name',
      reorder: true,
      omit: omitColumn('Full Name'),
      sortField: 'firstName',
      selector: (row) => `${row.firstName} ${row.lastName}`,
      cell: (row) => {
        const {
          _id, firstName, lastName,
        } = row;
        const fullName = `${firstName} ${lastName}`;
        return (
          <div style={{
            width: '100%',
            display: 'flex',
            gap: '3px',
            position: 'relative',
            justifyContent: 'flex-start',
          }}
          >
            {
              fullName.length >= valueOutputCutLength.fullName
                ? (
                  <TooltipComponent
                    title={`${firstName} ${lastName}`}
                    children={(
                      <Link to={`/user-detail/${_id}`}>
                        <span className="full-name-text" style={{ color: onlineUserIds.includes(_id) ? 'green' : 'white' }}>
                          { prettyCutOutputByCellWidth(fullName, 'fullName') }
                        </span>
                      </Link>
                      )}
                  />
                )
                : (
                  <Link to={`/user-detail/${_id}`}>
                    <span className="full-name-text" style={{ color: onlineUserIds.includes(_id) ? 'green' : 'white' }}>
                      {fullName}
                    </span>
                  </Link>
                )
            }
          </div>

        );
      },
    },
    {
      name: (
        <div className="d-flex flex-column search-input-des pl-0" style={{ alignItems: 'start' }}>
          <SortWrapper handleSort={() => sortFields('email')}>
            <div className="d-flex flex-row">
              <span>Email</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('email')} />
              </button>
            </div>
          </SortWrapper>
          <input
            type="text"
            className="leads-search-bar-filter"
            placeholder="Search"
            name="email"
            autoComplete="off"
            value={clientEmail}
            onChange={(e) => searchByEmail(e)}
          />
        </div>
      ),
      label: 'Email',
      reorder: true,
      minWidth: '174px',
      omit: omitColumn('Email'),
      selector: ({ email: rowEmail }) => (

        permissionName && permissionName.length > 0 && permissionName.includes('user_email')
          ? (
            <div>
              {
                rowEmail.length >= valueOutputCutLength.email
                  ? (
                    <TooltipComponent
                      title={rowEmail}
                      children={(
                        <div>
                          <CopyToClipboard text={rowEmail}>
                            <span style={{ margin: '5px', cursor: 'pointer' }} data-tip={rowEmail}>
                              <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
                              {prettyCutOutputByCellWidth(rowEmail, 'email')}
                            </span>
                          </CopyToClipboard>
                        </div>
                      )}
                    />
                  )
                  : (
                    <CopyToClipboard text={rowEmail}>
                      <span style={{ margin: '5px', cursor: 'pointer' }} data-tip={rowEmail}>
                        <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
                        {rowEmail}
                      </span>
                    </CopyToClipboard>
                  )
              }
            </div>
          )
          : null
      ),
    },
    {
      name: (
        <div className="d-flex flex-column search-input-des pl-0" style={{ alignItems: 'start' }}>
          <SortWrapper handleSort={() => sortFields('secondEmail')}>
            <div className="d-flex flex-row">
              <span>Second Email</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('secondEmail')} />
              </button>
            </div>
          </SortWrapper>
          <input
            type="text"
            className="leads-search-bar-filter"
            placeholder="Search"
            name="secondEmail"
            autoComplete="off"
            value={clientSecondEmail}
            onChange={(e) => searchBySecondEmail(e)}
          />
        </div>
      ),
      label: 'Second Email',
      reorder: true,
      minWidth: '174px',
      omit: omitColumn('Second Email'),
      selector: ({ secondEmail = '' }) => (
        permissionName && permissionName.length > 0 && permissionName.includes('user_email')
          ? (
            <div>
              {
                secondEmail?.length >= valueOutputCutLength.secondEmail
                  ? (
                    <TooltipComponent
                      title={secondEmail}
                      children={(
                        <div>
                          <CopyToClipboard text={secondEmail}>
                            <span style={{ margin: '5px', cursor: 'pointer' }} data-tip={secondEmail}>
                              <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
                              {prettyCutOutputByCellWidth(secondEmail, 'secondEmail')}
                            </span>
                          </CopyToClipboard>
                        </div>
                      )}
                    />
                  )
                  : (!secondEmail && secondEmail?.length === 0) ? '-' : (
                    <CopyToClipboard text={secondEmail}>
                      <span style={{ margin: '5px', cursor: 'pointer' }} data-tip={secondEmail}>
                        <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
                        {secondEmail}
                      </span>
                    </CopyToClipboard>
                  )
              }
            </div>
          )
          : null
      ),
    },
    {
      name: (
        <div className="d-flex flex-column search-input-des pl-0" style={{ alignItems: 'start' }}>
          <SortWrapper handleSort={() => sortFields('phone')}>
            <div className="d-flex flex-row">
              <span>Phone Number</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('phone')} />
              </button>
            </div>
          </SortWrapper>
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            name="clientPhone"
            autoComplete="off"
            value={clientPhone}
            onChange={(e) => searchByPhone(e)}
          />
        </div>
      ),
      label: 'Phone Number',
      reorder: true,
      minWidth: '174px',
      omit: omitColumn('Phone Number'),
      selector: (row) => (
        permissionName && permissionName.length > 0 && permissionName.includes('user_phone')
          ? (
            <div>
              {
                row?.phone.length >= valueOutputCutLength.phoneNumber
                  ? (
                    <TooltipComponent
                      title={row.phone}
                      children={(
                        <div>
                          <CopyToClipboard text={row.phone}>
                            <span style={{ margin: '5px', cursor: 'pointer' }} data-tip={row.phone}>
                              <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
                              {prettyCutOutputByCellWidth(row.phone, 'phoneNumber')}
                            </span>
                          </CopyToClipboard>
                        </div>
                      )}
                    />
                  )
                  : (
                    <CopyToClipboard text={row.phone}>
                      <span style={{ margin: '5px', cursor: 'pointer' }} data-tip={row.phone}>
                        <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
                        {row.phone}
                      </span>
                    </CopyToClipboard>
                  )
              }
            </div>
          )
          : null
      ),
    },
    {
      name: (
        <div className="d-flex flex-column search-input-des pl-0" style={{ alignItems: 'start' }}>
          <SortWrapper handleSort={() => sortFields('secondPhone')}>
            <div className="d-flex flex-row">
              <span>Second Phone Number</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('secondPhone')} />
              </button>
            </div>
          </SortWrapper>
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            name="clientSecondPhone"
            autoComplete="off"
            value={clientSecondPhone}
            onChange={(e) => searchBySecondPhone(e)}
          />
        </div>
      ),
      label: 'Second Phone Number',
      reorder: true,
      minWidth: '174px',
      omit: omitColumn('Second Phone Number'),
      selector: ({ secondPhone = '' }) => (
        permissionName && permissionName.length > 0 && permissionName.includes('user_phone')
          ? (
            <div>
              {
                secondPhone?.length >= valueOutputCutLength.phoneNumber
                  ? (
                    <TooltipComponent
                      title={secondPhone}
                      children={(
                        <div>
                          <CopyToClipboard text={secondPhone}>
                            <span style={{ margin: '5px', cursor: 'pointer' }} data-tip={secondPhone}>
                              <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
                              {prettyCutOutputByCellWidth(secondPhone, 'secondPhoneNumber')}
                            </span>
                          </CopyToClipboard>
                        </div>
                      )}
                    />
                  )
                  : secondPhone?.length === 0 ? '-' : (
                    <CopyToClipboard text={secondPhone}>
                      <span style={{ margin: '5px', cursor: 'pointer' }} data-tip={secondPhone}>
                        <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
                        {secondPhone}
                      </span>
                    </CopyToClipboard>
                  )
              }
            </div>
          )
          : null
      ),
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('country.nicename')}>
            <div className="d-flex flex-row">
              <span>Country</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('country.nicename')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters
            tabName="Country"
            data={countriesToShow}
            filters={isCheckCountry}
            setFilters={setIsCheckCountry}
            searchParams={{ id: 'iso', params: ['nicename', 'iso', 'iso3', 'name'], optionsName: ['nicename'] }}
            usersFilters={usersFilters}
            setUserFilters={setUserFilters}
            addNonSelected
          />
        </div>
      ),
      label: 'Country',
      reorder: true,
      minWidth: '160px',
      omit: omitColumn('Country'),
      selector: (row) => {
        if (row.countryCode && row.country?.[0]?.nicename) {
          const flagTitleCase = titleCase(row.countryCode);
          const UserCountryFlag = Flags[flagTitleCase];
          const countryNicename = row.country?.[0]?.nicename;

          return (
            countryNicename.length >= valueOutputCutLength.country
              ? (
                <TooltipComponent
                  title={countryNicename}
                  children={(
                    <div>
                      <span style={{ fontSize: 18 }} className="me-1">{ UserCountryFlag && <UserCountryFlag /> }</span>
                      <span style={{ cursor: 'pointer' }}>{prettyCutOutputByCellWidth(countryNicename, 'country')}</span>
                    </div>
                  )}
                />
              )
              : (
                <>
                  <span style={{ fontSize: 18 }} className="me-1">{ UserCountryFlag && <UserCountryFlag /> }</span>
                  <span>{countryNicename}</span>
                </>
              )
          );
        }
        return '-';
      },
    },
    {
      name: (
        <div className="d-flex flex-column search-input-des pl-0" style={{ alignItems: 'start' }}>
          <SortWrapper handleSort={() => sortFields('language.name')}>
            <div className="d-flex flex-row">
              <span>Language</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('language.name')} />
              </button>
            </div>
          </SortWrapper>
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            name="clientLanguage"
            autoComplete="off"
            value={clientLanguage}
            onChange={(e) => searchByClientLanguage(e)}
          />
        </div>
      ),
      minWidth: '160px',
      label: 'Language',
      reorder: true,
      omit: omitColumn('Language'),
      selector: ({ language }) => {
        if (language) {
          return (
            language.name.length >= valueOutputCutLength.language
              ? (
                <TooltipComponent
                  title={language.name}
                  children={(
                    <div>
                      <span style={{ cursor: 'pointer' }}>{prettyCutOutputByCellWidth(language.name, 'language')}</span>
                      <span>{`(${language.code})`}</span>
                    </div>
                  )}
                />
              ) : <span>{`${language.name} (${language.code})`}</span>
          );
        }
        return '-';
      },
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('createdAt')}>
            <div className="d-flex flex-row">
              <span>Created</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('createdAt')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters
            tabName="Created"
            onClick={(e) => e.stopPropagation()}
            data={dateOptions}
            filters={timeInitiatedFilter}
            setFilters={setTimeInitiatedFilter}
            searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
            usersFilters={usersFilters}
            setUserFilters={setUserFilters}
            isDateOptions
          />
        </div>
      ),
      label: 'Created',
      reorder: true,
      minWidth: '154px',
      omit: omitColumn('Created'),
      selector: (row) => (
        <span>{prettyCutOutputByCellWidth(formatDate(new Date(row.createdAt), 'created'))}</span>
      ),
      sortField: 'createdAt',
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('affiliateFullName')}>
            <div className="d-flex flex-row">
              <span>Affiliate Name</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('affiliateFullName')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters
            tabName="Affiliate Name"
            data={allAffiliates}
            filters={affiliateFilter}
            setFilters={setAffiliateFilter}
            searchParams={{ id: '_id', params: ['firstName', 'lastName'], optionsName: ['firstName', 'lastName'] }}
            usersFilters={usersFilters}
            setUserFilters={setUserFilters}
            addNonSelected
          />
        </div>
      ),
      minWidth: '180px',
      selector: ({ affiliateFullName = '-' }) => affiliateFullName ?? '-',
      cell: ({ _id, affiliateFullName = '' }) => {
        if (affiliateFullName) {
          return (
            affiliateFullName.length >= valueOutputCutLength.affiliateFullName
              ? (
                <TooltipComponent
                  title={affiliateFullName}
                  children={(
                    <div className="truncate-text" style={{ cursor: 'pointer' }} data-for={`aff-${_id}`} data-tip={`${affiliateFullName}`}>
                      {prettyCutOutputByCellWidth(affiliateFullName, 'affiliateFullName')}
                    </div>
                  )}
                />
              )
              : (
                <div
                  className="truncate-text"
                  data-for={`aff-${_id}`}
                  data-tip={`${affiliateFullName}`}
                >
                  {affiliateFullName}
                </div>
              )
          );
        }
        return '-';
      },
      label: 'Affiliate Name',
      reorder: true,
      omit: omitColumn('Affiliate Name') || !isAffiliateRule,
    },
    {
      name: (
        <div className="d-flex flex-row search-input-des p-2 pl-0" style={{ alignItems: 'start' }}>
          <SortWrapper handleSort={() => sortFields('source')}>
            <div className="d-flex flex-row">
              <span>Funnel Name</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('source')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters
            tabName="Funnel Name"
            data={funnelNameFilterOptions ?? []}
            filters={funnelNames}
            setFilters={setFunnelNames}
            searchParams={{ id: 'value', params: ['label'], optionsName: ['label'] }}
            usersFilters={usersFilters}
            setUserFilters={setUserFilters}
            addNonSelected
          />
        </div>
      ),
      label: 'Funnel Name',
      reorder: true,
      minWidth: '170px',
      omit: omitColumn('Funnel Name') || !isAffiliateRule,
      selector: (row) => (
        <div>
          {
            row?.source?.length >= valueOutputCutLength.funnelName
              ? (
                <TooltipComponent
                  title={row.source}
                  children={(
                    <div className="truncate-text" style={{ cursor: 'pointer', margin: '5px' }} data-for={`aff-${row._id}`} data-tip={`${row.source}`}>
                      {prettyCutOutputByCellWidth(row.source, 'funnelName')}
                    </div>
                  )}
                />
              )
              : (
                <div className="truncate-text" style={{ margin: '5px' }} data-for={`funnel-${row._id}`} data-tip={row.source}>
                  {row.source ? row.source : '-'}
                </div>
              )
            }
        </div>
      ),
    },
    {
      name: (
        <div className="d-flex flex-column search-input-des pl-0" style={{ alignItems: 'start' }}>
          <SortWrapper handleSort={() => sortFields('additionalInfo')}>
            <div className="d-flex flex-row">
              <span>Additional Info</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('additionalInfo')} />
              </button>
            </div>
          </SortWrapper>
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            autoComplete="off"
            name="AdditionalInfo"
            value={additionalInfoFilter || ''}
            onChange={(e) => setAdditionalInfoFilter(e.target.value)}
          />
        </div>
      ),
      label: 'Additional Info',
      reorder: true,
      minWidth: '170px',
      omit: omitColumn('Additional Info'),
      selector: (row) => (
        <div>
          {
            row?.additionalInfo?.length >= valueOutputCutLength.additionalInfo
              ? (
                <TooltipComponent
                  title={row.additionalInfo}
                  children={(
                    <div className="truncate-text" style={{ cursor: 'pointer', margin: '5px' }} data-for={`aff-${row._id}`} data-tip={`${row.additionalInfo}`}>
                      {prettyCutOutputByCellWidth(row.additionalInfo, 'funnelName')}
                    </div>
                  )}
                />
              )
              : (
                <div className="truncate-text" style={{ margin: '5px' }} data-for={`funnel-${row._id}`} data-tip={row.additionalInfo}>
                  {row.additionalInfo ?? '-'}
                </div>
              )
            }
        </div>
      ),
    },
    {
      name: (
        <div className="d-flex flex-row search-input-des p-2 pl-0" style={{ alignItems: 'start' }}>
          <SortWrapper handleSort={() => sortFields('managerUserFullName')}>
            <div className="d-flex flex-row">
              <span>Manager</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('managerUserFullName')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters
            tabName="Manager"
            data={assignToShow}
            filters={managerFilterPreset}
            setFilters={setManagerFilterPreset}
            searchParams={{ id: '_id', params: ['firstName', 'lastName'], optionsName: ['firstName', 'lastName'] }}
            usersFilters={usersFilters}
            setUserFilters={setUserFilters}
            addNonSelected
          />
        </div>
      ),
      label: 'Manager',
      reorder: true,
      minWidth: '170px',
      omit: omitColumn('Manager'),
      selector: ({ managerUserFullName }) => (
        <div>
          {
            managerUserFullName ?? '-'
          }
        </div>
      ),
    },
    {
      name: (
        <div className="d-flex flex-row search-input-des p-2 pl-0" style={{ alignItems: 'start' }}>
          <SortWrapper handleSort={() => sortFields('ibdeskUserFullName')}>
            <div className="d-flex flex-row">
              <span>Desk/IB</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('ibdeskUserFullName')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters
            tabName="Desk/IB"
            filterName="ibdesk"
            data={ibdeskToShow}
            filters={ibdeskFilterPreset}
            setFilters={setIbdeskFilterPreset}
            searchParams={{ id: '_id', params: ['firstName', 'lastName'], optionsName: ['firstName', 'lastName'] }}
            usersFilters={usersFilters}
            setUserFilters={setUserFilters}
            addNonSelected
          />
        </div>
      ),
      label: 'Desk/Ib',
      reorder: true,
      minWidth: '170px',
      omit: omitColumn('Desk/Ib'),
      selector: ({ ibdeskUserFullName }) => (
        <div>
          {
            ibdeskUserFullName ?? '-'
          }
        </div>
      ),
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('assignedToFullName')}>
            <div className="d-flex flex-row">
              <span>Assigned To</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('assignedToFullName')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters
            tabName="Assigned To"
            data={assignToShow}
            filters={isCheckAssignTo}
            setFilters={setIsCheckAssignTo}
            searchParams={{ id: '_id', params: ['firstName', 'lastName'], optionsName: ['firstName', 'lastName'] }}
            usersFilters={usersFilters}
            setUserFilters={setUserFilters}
            addNonSelected
          />
        </div>
      ),
      minWidth: '190px',
      selector: ({ assignedToFullName = '-' }) => assignedToFullName ?? '-',
      cell: ({ _id, assignedTo = '', assignedToFullName = '' }) => {
        if (permissionName && permissionName.length > 0 && permissionName.includes('assign_to_agent') && isCanEditUser) {
          const optionGroups = assignToShowOptionGroupsCreator(assignToShow, assignedTo);

          return (
            <select
              key={assignedTo}
              className="form-control user-status-select leads-status-select text-color-options-white"
              name="type"
              defaultValue={assignedTo ?? ''}
              style={{ color: '#fff' }}
              onChange={(e) => handleChangeAssignTo(e, _id)}
            >
              <option value="" style={{ color: '#fff' }} color="#fff">
                Select Manager
              </option>
              {assignedTo && (
              <option value={assignedTo} style={{ color: '#fff' }}>
                {assignedToFullName}
              </option>
              )}
              {optionGroups.map((group) => (
                <optgroup key={group.id} label={group.label}>
                  {group.options.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </optgroup>
              ))}
            </select>
          );
        }
        return assignedTo ? <div>{`${assignedToFullName}`}</div> : <div>-</div>;
      },
      label: 'Assigned To',
      reorder: true,
      omit: omitColumn('Assigned To'),
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('salesStatusLabel')}>
            <div className="d-flex flex-row">
              <span>Status</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('salesStatusLabel')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters 
            tabName="Status" 
            data={filteredSalesStatuses} 
            filters={isCheckStatus} 
            setFilters={setIsCheckStatus} 
            searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
            usersFilters={usersFilters}
            setUserFilters={setUserFilters}
            addNonSelected
          />
        </div>
      ),
      minWidth: '190px',
      omit: omitColumn('Status'),
      label: 'Status',
      reorder: true,
      selector: (row) => (row.salesStatusId ? row.salesStatusId : ''),
      cell: (row) => {
        const currSalesStatusType = salesStatuses.find((stat) => stat._id === row.salesStatusId) || { _id: '', name: 'Select Status', color: '#fff' };

        return (
          <select
            className="form-control user-status-select leads-status-select"
            name="status"
            value={currSalesStatusType?._id}
            onChange={(e) => handleStatusChange(e, row)}
            style={{ color: currSalesStatusType?.color ? currSalesStatusType?.color : '#fff' }}
          >
            <option value="" style={{ color: '#fff' }} color="#fff">Select Status</option>
            {filteredSalesStatuses?.map((currentStatus) => (
              <option value={currentStatus._id} key={currentStatus._id} style={{ color: currentStatus.color }} color={currentStatus.color}>
                {' '}
                {currentStatus.name}
              </option>
            ))}
          </select>
        );
      },
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('brand.name')}>
            <div className="d-flex flex-row">
              <span>Brand</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('brand.name')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters 
            tabName="Brand" 
            data={brands} 
            filters={selectedBrands} 
            setFilters={setSelectedBrands}
            searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
            usersFilters={usersFilters}
            setUserFilters={setUserFilters}
            addNonSelected
          />
        </div>
      ),
      minWidth: '190px',
      omit: omitColumn('Brand'), 
      label: 'Brand',
      reorder: true,
      cell: ({ brandId, _id }) => (
        !!brands.length && (
          <select
            className="form-control user-status-select leads-status-select text-white"
            name="brand"
            defaultValue={brandId || ''}
            onChange={({ target: { value } }) => handleDropdownChange({ brandId: value }, _id)}
          >
            {brands.length && brands.map((brand) => (
              <option value={brand._id} key={brand._id}>
                {brand.name}
              </option>
            ))}
          </select>
        )
      ),
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('balanceInUSD')}>
          <div className="d-flex flex-row">
            <span>Balance</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('balanceInUSD')} />
            </button>
          </div>
        </SortWrapper>
      ),
      omit: omitColumn('Balance'),
      label: 'Balance',
      reorder: true,
      minWidth: '110px',
      cell: ({ balanceInUSD }) => (
        balanceInUSD?.toString()?.length >= valueOutputCutLength.balance 
          ? (
            <TooltipComponent 
              title={balanceInUSD.toString()}
              children={(
                <span style={{ cursor: 'pointer' }}>
                  {prettyCutOutputByCellWidth(currencyFormatter.format(balanceInUSD, { code: 'USD' }), 'balance')}
                </span>
              )}
            />
          )
          : (
            <span>
              {currencyFormatter.format(balanceInUSD, { code: 'USD' })}
            </span>
          )
      ),
      sortField: 'totalBalance',
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('creditInUSD')}>
          <div className="d-flex flex-row">
            <span>Credit</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('creditInUSD')} />
            </button>
          </div>
        </SortWrapper>
      ),
      omit: omitColumn('Credit'),
      label: 'Credit',
      reorder: true,
      minWidth: '110px',
      cell: ({ creditInUSD }) => (
        creditInUSD?.toString()?.length >= valueOutputCutLength.credit 
          ? (
            <TooltipComponent 
              title={creditInUSD.toString()}
              children={(
                <span style={{ cursor: 'pointer' }}>
                  {prettyCutOutputByCellWidth(currencyFormatter.format(creditInUSD, { code: 'USD' }), 'credit')}
                </span>
              )}
            />
          )
          : (
            <span>
              {currencyFormatter.format(creditInUSD, { code: 'USD' })}
            </span>
          )
      ),
      sortField: 'totalCredit',
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('netDeposits')}>
          <div className="d-flex flex-row">
            <span>Net Deposits</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('netDeposits')} />
            </button>
          </div>
        </SortWrapper>
      ),
      label: 'Net Deposits',
      reorder: true,
      omit: omitColumn('Net Deposits'),
      minWidth: '140px',
      cell: ({ netDeposits }) => (
        netDeposits?.toString()?.length >= valueOutputCutLength.netDeposits 
          ? (
            <TooltipComponent 
              title={netDeposits.toString()}
              children={(
                <span style={{ cursor: 'pointer' }}>
                  {prettyCutOutputByCellWidth(currencyFormatter.format(netDeposits, { code: 'USD' }), 'netDeposits')}
                </span>
            )}
            />
          )
          : (
            <span>
              {currencyFormatter.format(netDeposits, { code: 'USD' })}
            </span>
          )
      ),
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('firstTimeDeposit')}>
            <div className="d-flex flex-row">
              <span>First Time Deposit</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('firstTimeDeposit')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters 
            tabName="First Time Deposit" 
            data={dateOptions}
            filters={firstTimeDepositFilter}
            setFilters={setFirstTimeDepositFilter}
            searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
            usersFilters={usersFilters}
            setUserFilters={setUserFilters}
            isDateOptions
          />
        </div>
      ),
      label: 'First Time Deposit',
      reorder: true,
      omit: omitColumn('First Time Deposit'),
      minWidth: '160px',
      sortField: 'firstTimeDeposit',
      selector: ({ firstTimeDeposit = '' }) => (
        <span>{firstTimeDeposit ? formatDate(new Date(firstTimeDeposit)) : '-'}</span>
      ),
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('ftdOwnerFullName')}>
            <div className="d-flex flex-row">
              <span>FTD Owner</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('ftdOwnerFullName')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters 
            tabName="FTD Owner" 
            data={assignToShow}
            filters={isCheckFtdOwner}
            setFilters={setIsCheckFtdOwner}
            searchParams={{ id: '_id', params: ['firstName', 'lastName'], optionsName: ['firstName', 'lastName'] }}
            usersFilters={usersFilters}
            setUserFilters={setUserFilters}
            addNonSelected
          />
        </div>
      ),
      label: 'FTD Owner',
      reorder: true,
      omit: omitColumn('FTD Owner'),
      minWidth: '150px',
      sortField: 'ftdOwnerFullName',
      cell: ({ ftdOwnerFullName = '' }) => {
        ftdOwnerFullName = ftdOwnerFullName?.length === 1 ? '' : ftdOwnerFullName;

        return ftdOwnerFullName.length >= valueOutputCutLength.ftdOwnerFullName
          ? (
            <TooltipComponent
              title={ftdOwnerFullName}
              children={(
                <span className="full-name-text">
                  { prettyCutOutputByCellWidth(ftdOwnerFullName, 'ftdOwnerFullName') }
                </span>
            )}
            />
          )
          : (
            <span className="full-name-text">
              {ftdOwnerFullName.length ? ftdOwnerFullName : '-'}
            </span>
          );
      },
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('stdOwnerFullName')}>
            <div className="d-flex flex-row">
              <span>STD Owner</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('stdOwnerFullName')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters
            tabName="STD Owner" 
            data={assignToShow}
            filters={isCheckStdOwner}
            setFilters={setIsCheckStdOwner}
            searchParams={{ id: '_id', params: ['firstName', 'lastName'], optionsName: ['firstName', 'lastName'] }}
            usersFilters={usersFilters}
            setUserFilters={setUserFilters}
            addNonSelected
          />
        </div>
      ),
      label: 'STD Owner',
      reorder: true,
      omit: omitColumn('STD Owner'),
      minWidth: '150px',
      sortField: 'stdOwnerFullName',
      cell: ({ stdOwnerFullName = '' }) => {
        stdOwnerFullName = stdOwnerFullName?.length === 1 ? '' : stdOwnerFullName;

        return stdOwnerFullName.length >= valueOutputCutLength.stdOwnerFullName
          ? (
            <TooltipComponent
              title={stdOwnerFullName}
              children={(
                <span className="full-name-text">
                  { prettyCutOutputByCellWidth(stdOwnerFullName, 'stdOwnerFullName') }
                </span>
              )}
            />
          )
          : (
            <span className="full-name-text">
              {stdOwnerFullName.length ? stdOwnerFullName : '-'}
            </span>
          );
      },
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('isOnline')}>
            <div className="d-flex flex-row">
              <span>Online</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('isOnline')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters 
            tabName="Online" 
            data={onlineFilterPresets} 
            filters={isCheckOnlineStatuses} 
            setFilters={setIsCheckOnlineStatuses} 
            searchParams={{ id: '_id', params: ['firstName', 'lastName'], optionsName: ['firstName', 'lastName'] }}
            usersFilters={usersFilters}
            setUserFilters={setUserFilters}
          />
        </div>
      ),
      minWidth: '120px',
      label: 'Online',
      reorder: true,
      omit: omitColumn('Online'),
      sortField: 'online',
      selector: ({ _id }) => (
        onlineUserIds.includes(_id)
          ? (
            <span className="online">
              Online
            </span>
          )
          : (
            <span className="offline">
              Offline
            </span>
          )
      ),
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('lastPromotionDate')}>
            <div className="d-flex flex-row">
              <span>Last Promotion</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('lastPromotionDate')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters
            tabName="Last Promotion" 
            data={dateOptions}
            filters={lastPromotion}
            setFilters={setLastPromotion}
            searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
            usersFilters={usersFilters}
            setUserFilters={setUserFilters}
            isDateOptions
          />
        </div>
      ),
      label: 'Last Promotion',
      reorder: true,
      omit: omitColumn('Last Promotion'),
      minWidth: '185px',
      sortField: 'lastPromotionDate',
      selector: ({ lastPromotionDate }) => (
        <span>{lastPromotionDate ? formatDate(new Date(lastPromotionDate)) : '-'}</span>
      ),
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('lastDemotionDate')}>
            <div className="d-flex flex-row">
              <span>Last Demotion</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('lastDemotionDate')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters 
            tabName="Last Demotion" 
            data={dateOptions} 
            filters={lastDemotion} 
            setFilters={setLastDemotion} 
            searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
            usersFilters={usersFilters}
            setUserFilters={setUserFilters}
            isDateOptions
          />
        </div>
      ),
      label: 'Last Demotion',
      reorder: true,
      omit: omitColumn('Last Demotion'),
      minWidth: '180px',
      sortField: 'lastDemotionDate',
      selector: ({ lastDemotionDate }) => (
        <span>{lastDemotionDate ? formatDate(new Date(lastDemotionDate)) : '-'}</span>
      ),
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('lastAssignmentDate')}>
            <div className="d-flex flex-row">
              <span>Last Assignment</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('lastAssignmentDate')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters 
            tabName="Last Assignment" 
            data={dateOptions} 
            filters={lastAssignment} 
            setFilters={setLastAssignment} 
            searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
            usersFilters={usersFilters}
            setUserFilters={setUserFilters}
            isDateOptions
          />
        </div>
      ),
      label: 'Last Assignment',
      reorder: true,
      omit: omitColumn('Last Assignment'),
      minWidth: '190px',
      sortField: 'lastAssignmentDate',
      selector: ({ lastAssignmentDate }) => (
        <span>{lastAssignmentDate ? formatDate(new Date(lastAssignmentDate)) : '-'}</span>
      ),
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('prevAssignedToFullName')}>
            <div className="d-flex flex-row">
              <span>Previous Owner</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('prevAssignedToFullName')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters
            tabName="Previous Owner" 
            data={assignToShow}
            filters={isCheckPreviousOwner}
            setFilters={setIsCheckPreviousOwner}
            searchParams={{ id: '_id', params: ['firstName', 'lastName'], optionsName: ['firstName', 'lastName'] }}
            usersFilters={usersFilters}
            setUserFilters={setUserFilters}
            addNonSelected
          />
        </div>
      ),
      label: 'Previous Owner',
      reorder: true,
      omit: omitColumn('Previous Owner'),
      minWidth: '190px',
      sortField: 'prevAssignedToFullName',
      cell: ({ prevAssignedToFullName = '' }) => (prevAssignedToFullName?.length >= valueOutputCutLength.prevAssignedToFullName
        ? (
          <TooltipComponent
            title={prevAssignedToFullName}
            children={(
              <span className="full-name-text">
                { prettyCutOutputByCellWidth(prevAssignedToFullName, 'prevAssignedToFullName') }
              </span>
              )}
          />
        )
        : (
          <span className="full-name-text">
            {prevAssignedToFullName?.length ? prevAssignedToFullName : '-'}
          </span>
        )),
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('lastLoginAt')}>
            <div className="d-flex flex-row">
              <span>Last Login Date</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('lastLoginAt')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters 
            tabName="Last Login Date" 
            data={dateOptions} 
            filters={lastLoginAt} 
            setFilters={setLastLoginAt}
            searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
            usersFilters={usersFilters}
            setUserFilters={setUserFilters}
            isDateOptions
          />
        </div>
      ),
      label: 'Last Login Date',
      reorder: true,
      omit: omitColumn('Last Login Date'),
      selector: (row) => (
        <span>
          {row.lastLoginAt 
            ? prettyCutOutputByCellWidth(formatDate(new Date(row.lastLoginAt), 'lastLoginAt')) 
            : '-'}
        </span>
      ),
      minWidth: '190px',
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('lastOnline')}>
            <div className="d-flex flex-row">
              <span>Last Online</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('lastOnline')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters 
            tabName="Last Online" 
            data={dateOptions}
            filters={lastOnline} 
            setFilters={setLastOnline}
            searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
            usersFilters={usersFilters}
            setUserFilters={setUserFilters}
            isDateOptions
          />
        </div>
      ),
      label: 'Last Online',
      reorder: true,
      omit: omitColumn('Last Online'),
      selector: (row) => (
        <span>
          {row.lastOnline 
            ? prettyCutOutputByCellWidth(formatDate(new Date(row.lastOnline), 'lastOnline')) 
            : '-'}
        </span>
      ),
      minWidth: '190px',
      sortField: 'lastOnline',
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('totalCalls')}>
            <div className="d-flex flex-row">
              <span>Total Calls</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('totalCalls')} />
              </button>
            </div>
          </SortWrapper>
          <RangeModalDebounceFilter
            value={totalCallsRange ?? [0, 10000]}
            setValue={setTotalCallsRange}
            setFilters={setDebounceTotalCallsRange}
            filters={{}}
            maxValue={10000}
            filedName="totalCalls"
            debounceAmountRange
          />
        </div>
      ),
      label: 'Total Calls',
      reorder: true,
      omit: omitColumn('Total Calls'),
      minWidth: '140px',
      sortField: 'totalCalls',
      selector: ({ totalCalls = '-' }) => (
        <span>{totalCalls ?? '-'}</span>
      ),
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('lastCallDate')}>
            <div className="d-flex flex-row">
              <span>Last Call</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('lastCallDate')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters 
            tabName="Last Call" 
            data={dateOptions} 
            filters={lastCallDate} 
            setFilters={setLastCallDate}
            searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
            usersFilters={usersFilters}
            setUserFilters={setUserFilters}
            isDateOptions
          />
        </div>
      ),
      label: 'Last Call',
      reorder: true,
      omit: omitColumn('Last Call'),
      minWidth: '140px',
      sortField: 'lastCallDate',
      selector: ({ lastCallDate = '' }) => (
        <span>{lastCallDate ? formatDate(new Date(lastCallDate)) : '-'}</span>
      ),
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('updatedAt')}>
            <div className="d-flex flex-row">
              <span>Last Action</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('updatedAt')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters 
            tabName="Last Action" 
            data={dateOptions} 
            filters={lastActionDate} 
            setFilters={setLastActionDate} 
            searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
            usersFilters={usersFilters}
            setUserFilters={setUserFilters}
            isDateOptions
          />
        </div>
      ),
      label: 'Last Action',
      reorder: true,
      omit: omitColumn('Last Action'),
      minWidth: '160px',
      sortField: 'updatedAt',
      selector: ({ updatedAt = '' }) => (
        <span>{updatedAt ? formatDate(new Date(updatedAt)) : '-'}</span>
      ),
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('lastCommentAt')}>
            <div className="d-flex flex-row">
              <span>Last Comment Date</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('lastCommentAt')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters
            tabName="Last Comment Date"
            data={dateOptions}
            filters={lastCommentDate}
            setFilters={setLastCommentDate}
            searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
            usersFilters={usersFilters}
            setUserFilters={setUserFilters}
            isDateOptions
          />
        </div>
      ),
      cell: ({ lastCommentAt = '' }) => (
        <span>
          {lastCommentAt?.length 
            ? prettyCutOutputByCellWidth(formatDate(new Date(lastCommentAt)), 'lastComment') 
            : '-'}
        </span> 
      ),
      minWidth: '176px',
      label: 'Last Comment Date',
      reorder: true,
      omit: omitColumn('Last Comment Date'),
    },
    {
      name: (
        <div className="d-flex flex-column search-input-des pl-0" style={{ alignItems: 'start' }}>
          <SortWrapper handleSort={() => sortFields('lastComment')}>
            <div className="d-flex flex-row">
              <span>Last Comment</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('lastComment')} />
              </button>
            </div>
          </SortWrapper>
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            autoComplete="off"
            name="lastComment"
            value={lastCommentFilter}
            onChange={(e) => searchByLastComment(e.target.value)}
          />
        </div>
      ),
      cell: ({ lastComment = '' }) => {
        if (lastComment?.length >= valueOutputCutLength.lastComment) {
          return (
            <TooltipComponent 
              title={lastComment.replaceAll('<br>', '\n')}
              children={(
                <span className="truncate-text" style={{ cursor: 'pointer', margin: '5px' }} data-for={`aff-${lastComment}`} data-tip={`${lastComment.replaceAll('<br>', '\n')}`}>
                  {prettyCutOutputByCellWidth(lastComment.replaceAll('<br>', '\n'), 'lastComment')}
                </span>
                )}
            />
          );
        }
        return (
          <span className="truncate-text" style={{ margin: '5px' }} data-for={`funnel-${lastComment}`} data-tip={lastComment.replaceAll('<br>', '\n')}>
            {lastComment.replaceAll('<br>', '\n') || '-'}
          </span>
        );
      },
      minWidth: '176px',
      label: 'Last Comment',
      reorder: true,
      omit: omitColumn('Last Comment'),
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('countryCode')}>
          <div className="d-flex flex-row">
            <span>Local Time</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('countryCode')} />
            </button>
          </div>
        </SortWrapper>
      ),
      omit: omitColumn('Local Time'),
      label: 'Local Time',
      reorder: true,
      minWidth: '150px',
      selector: (row) => (
        <span>
          {row.countryCode 
            ? prettyCutOutputByCellWidth(formatDate(getLocalDataByCountryCode(row.countryCode) || new Date()), 'localTime') 
            : 'No country selected'}
        </span>
      ),
    },
    {
      name: 'Delete User',
      omit: omitColumn('Delete User'),
      label: 'Delete User',
      reorder: true,
      minWidth: '176px',
      cell: (row) => (
        <>
          {(permissionName && permissionName.length) > 0 && permissionName.includes('delete_user')
          && (
            <button type="button" className="btn btn-danger btn-sm me-1 p-1" onClick={() => deleteAction(row._id)}>
              <BsTrashFill role="button" color="white" size={18} />
            </button>
          )}
          {(permissionName && permissionName.length) > 0 && permissionName.includes('block_user')
            && (
              row.status === true
                ? <button type="button" className="btn btn-warning btn-sm me-1 p-1" onClick={() => userAction(row._id, 'block')}>Block</button>
                : <button type="button" className="btn btn-warning btn-sm me-1 p-1" onClick={() => userAction(row._id, 'unBlock')}>Unblock</button>
            )}
        </>
      ),
      sortable: false,
    },
  ];

  return sortColumnByOrderConfig(columns, columnConfig);
};
