export const GET_CHATS = 'GET_CHATS';
export const GET_CHAT = 'GET_CHAT';
export const ADD_CHAT = 'ADD_CHAT';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const DELETE_CHAT = 'DELETE_CHAT';
export const DELETE_CHAT_MESSAGE = 'DELETE_CHAT_MESSAGE';
export const ERROR_CHAT = 'ERROR_CHAT';
export const READ_MESSAGES = 'READ_MESSAGES';
export const GET_MESSAGE = 'GET_MESSAGE';
export const EDIT_MESSAGE = 'EDIT_MESSAGE';
