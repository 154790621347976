import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';

import { selectAllDocuments, selectAllDocumentsTotalCount } from '../../redux/documents/documentsSelectors';
import { getAllDocuments } from '../../redux/documents/documentsActions';
import { generateSortParams } from '../../helpers/sortHelper';
import { DocumentsSchema } from '../../DataTableSchemas/DocumentsSchema';
import { getBrands } from '../../redux/brand/brandActions';
import ResetIconLogo from '../../assets/images/reset-settings.svg';


export function Documents() {
  const dispatch = useDispatch();
  const allDocumentsData = useSelector(selectAllDocuments);
  const totalCount = useSelector(selectAllDocumentsTotalCount);

  const [isPaginationDT, setIsPaginationDT] = useState(false); 
  const [sortParams, setSortParams] = useState(JSON.stringify({ createdAt: -1 }));
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [brandsFilters, setBrandsFilters] = useState([]);
  const [documentsFilter, setDocumentsFilter] = useState({});

  const filterStorageName = 'DataTable_documents/filter';
  const paginationStorageName = 'DataTable_documents/pagination';

  const setStoredPagination = () => {
    const variablesRowsJSON = localStorage.getItem(paginationStorageName);
    if (variablesRowsJSON) {
      const filterRows = JSON.parse(variablesRowsJSON);
      setRowsPerPage(filterRows?.limit || 5);
      setPage(filterRows?.page || 1);
    }

    setIsPaginationDT(true);
  };

  const setStoredFilterData = () => {
    const storageData = localStorage.getItem(filterStorageName);
    if (storageData) {
      const filters = JSON.parse(storageData);
      setBrandsFilters(filters?.brand || []);
      setDocumentsFilter(filters || {});
    }
  };

  const getAllStoredData = () => {
    setStoredFilterData();
    setStoredPagination();
  };

  const handleClear = () => {
    setBrandsFilters([]);
    setDocumentsFilter({});
    setPage(1);
    setRowsPerPage(5);
    localStorage.removeItem(filterStorageName);
  };

  useEffect(() => {
    getAllStoredData(); 
    dispatch(getBrands());
  }, []);

  const handleRowsPerPageChange = (currentRowsPerPage) => {
    setRowsPerPage(currentRowsPerPage);
    localStorage.setItem(paginationStorageName, JSON.stringify({ limit: currentRowsPerPage }));
  };

  const handlePageChange = (page) => {
    setPage(page);
  }; 

  const handleSort = async (sortField, sortDirection) => {
    const sort = generateSortParams(sortField, sortDirection);
    setSortParams(sort);
  };

  const columns = DocumentsSchema(
    brandsFilters,
    setBrandsFilters,
    documentsFilter,
    setDocumentsFilter,
    handleSort,
  );

  useEffect(() => {
    const fetchData = async () => {
      if (isPaginationDT) {
        localStorage.setItem(filterStorageName, JSON.stringify(documentsFilter));
        await dispatch(getAllDocuments({
          page,
          limit: rowsPerPage,
          sort: sortParams,
          query: documentsFilter, 
        }));
      }
    };
   
    fetchData();
  }, [isPaginationDT, page, rowsPerPage, sortParams, documentsFilter]);

  return (
    <div className="content-wrapper right-content-wrapper">
      <div className="content-box">
        <h3>
          Documents
        </h3>
        <div className="action__btn-row">
          <div className="main_btn-row">
            <div className="secondary_btn-row">
              <button 
                type="button"
                className="btn-secondary_dark iconed"
                onClick={handleClear}
              >
                <svg style={{ maxHeight: '20px', maxWidth: '20px', color: '#C4C6D0' }}>
                  <use xlinkHref={`${ResetIconLogo}#resetIconLogo`} />
                </svg>
              </button>
            </div>
          </div>

        </div>
        <div className="dashboard-tbl-wrapper custom-tbl-wrapper mt-3">
          {
          isPaginationDT
            && (
              <DataTable
                columns={columns}
                data={allDocumentsData}
                highlightOnHover
                pagination
                paginationServer
                paginationTotalRows={totalCount}
                paginationPerPage={rowsPerPage}
                paginationRowsPerPageOptions={[5, 10, 25, 50]}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handleRowsPerPageChange}
                paginationDefaultPage={page}
                persistTableHead
                theme="solarizedd"
                progressComponent={<div className="datatable-loader__background" />}
              />
            )
        }
        </div>
      </div>
    </div>
  );
}
