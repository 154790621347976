import React, { useState, useEffect } from 'react';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './styles.module.css';


export function ChangeClientPassword({
  id, 
  label, 
  placeholder, 
  handleChange, 
  btnTitle, 
  inputValue, 
  setInputValue,
  header = 'Change Password',
  disabled = false,
  style = {},
  withoutHeader = false,
}) {
  const [isTouchedField, setIsTouchedField] = useState(false);
  const [isValidPassword, setIsValidPassword] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (isTouchedField && inputValue.length < 6) {
      setIsValidPassword(false);
    } else setIsValidPassword(true);
  }, [inputValue]);

  const changedHandler = (e) => {
    setInputValue(e.target.value.trim());
    setIsTouchedField(true);
  };

  return (
    <div className={styles.changePassWrapper} style={{ ...style }}>
      { !withoutHeader && (<h5 className={styles.changePassTitle}>{header}</h5>) }
      { !withoutHeader && (<div className={styles.changePassInputContainer} />) }
      <label className={styles.changePassInputLabel} htmlFor={id}>{label}</label> 
      <div className={styles.changePassInputFieldContainer}> 
        <input 
          id={id}
          name={id}
          placeholder={placeholder}
          className={styles.changePassInputField}
          value={inputValue}
          type={!showPassword ? 'password' : 'text'}
          disabled={disabled}
          onChange={changedHandler}
          autoComplete="new-password"
        /> 
        <FontAwesomeIcon
          icon={showPassword ? faEye : faEyeSlash}
          onClick={() => setShowPassword((prev) => !prev)}
          className={styles.changePassIcon}
          color="white"
        />
      </div>
      {(!isValidPassword && inputValue.length > 0) ? <p className={styles.changePassValidationField}>Password must have at least 6 characters</p> : null}
      {
        !disabled && (
          <div className={styles.changePassInputBtnContainer}>
            <button className={styles.changePassInputBtn} type="button" onClick={handleChange}>{btnTitle}</button>
          </div> 
        )
      }
    </div>  
  );
}
