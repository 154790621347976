import { toast } from 'react-toastify';
import { 
  GET_USER_BANK_ACCOUNT, 
  UPDATE_USER_BANK_ACCOUNT, 
  DELETE_USER_BANK_ACCOUNT, 
  ADD_USER_BANK_ACCOUNT, 
  UPDATE_STATUS_USER_BANK_ACCOUNT,
} from './userBankAccountTypes';
import { apiHelper } from '../apiHelper';


export const addUserBankAccount = ({ userId, data }) => async (dispatch) => {
  try {
    const res = await apiHelper('post', `/api/user-bank-account/${userId}`, data);

    if (res.data?.success) {
      const { newUserBankAccount } = res.data;
      dispatch({
        type: ADD_USER_BANK_ACCOUNT,
        payload: newUserBankAccount,
      });
    }
  } catch (error) {
    toast.error(error.message);
  }
};

export const getUserBankAccount = ({ 
  sort, page, limit, query, id,
}) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/user-bank-account/${id}`, undefined, {
      ...query,
      page,
      limit,
      sort,
    });
  
    if (res.data?.success) {
      const { userBankAccounts } = res.data;
      dispatch({
        type: GET_USER_BANK_ACCOUNT,
        payload: userBankAccounts,
      });
    }
  } catch (error) {
    toast.error(error.message);
  }
};

export const updateUserBankAccount = ({ userId, data }) => async (dispatch) => {
  try {
    const res = await apiHelper('patch', `/api/user-bank-account/${userId}`, data);

    if (res.data?.success) {
      const { updatedUserBankAccount } = res.data;

      dispatch({
        type: UPDATE_USER_BANK_ACCOUNT,
        payload: updatedUserBankAccount,
      });
    }
  } catch (error) {
    toast.error(error.message);
  }
};

export const deleteUserBankAccount = ({ documentId, userId }) => async (dispatch) => {
  try {
    const res = await apiHelper('delete', `/api/user-bank-account/${userId}`, { id: documentId });

    if (res.data?.success) {
      const { deletedUserBankAccount } = res.data;

      dispatch({
        type: DELETE_USER_BANK_ACCOUNT,
        payload: deletedUserBankAccount,
      });
    }
  } catch (error) {
    toast.error(error.message);
  }
};

export const updateStatusUserBankAccount = ({ userId, data }) => async (dispatch) => {
  try {
    const res = await apiHelper('patch', `/api/user-bank-account/update-status/${userId}`, data);

    if (res.data?.success) {
      const { updatedStatusUserBankAccount } = res.data;

      dispatch({
        type: UPDATE_STATUS_USER_BANK_ACCOUNT,
        payload: updatedStatusUserBankAccount,
      });
    }
  } catch (error) {
    toast.error(error.message);
  }
};
