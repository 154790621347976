import React, {
  useMemo, useEffect, useState, useRef, 
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faForward, faFile, faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { BsTrashFill, BsPenFill } from 'react-icons/bs';
import { toast } from 'react-toastify';
import Markdown from 'markdown-to-jsx';
import { UserPhoto } from './UserPhoto';
import { deleteChatMessage, editMessage } from '../../../redux/chat/chatActions';

export function ActiveChat({
  activeChat, setNewMessage, handleSendMessage, chatBoxRef, fileInputRef, newMessage, handleReadMessages, file, setFile,
}) {
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const [editMessageId, setEditMessageId] = useState('');
  const isFileLoaded = useMemo(() => file && file.name, [file]);
  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions);
  const hasDeleteChatMessagePermission = useMemo(() => permissionName?.includes('delete_chat_message'), [permissionName]);
  const hasEditChatMessagePermission = useMemo(() => permissionName?.includes('edit_chat_message'), [permissionName]);

  useEffect(() => {
    if (file && file.size > 3007152) {
      toast.error('File bigger then 3 MB');
      setFile(null);
      // eslint-disable-next-line no-param-reassign
      fileInputRef.current.value = '';
    }
  }, [file]);

  const deleteMessageHandler = (messageId) => {
    if (!messageId) return;
    dispatch(deleteChatMessage(messageId));
  };

  const editMessageHandler = () => {
    if (!editMessageId) return;
    dispatch(editMessage(editMessageId, { content: newMessage }));
    setNewMessage('');
    setEditMessageId('');
  };

  const handleEditClick = (messageId, content) => {
    setEditMessageId(messageId);
    setNewMessage(content);
    inputRef.current.focus();
  };

  const handleSumbit = () => {
    const method = editMessageId?.length ? editMessageHandler : handleSendMessage;
    
    method();
  };

  const splitFileName = (fileName) => {
    const [name, ext] = fileName.split('.');

    return (
      <>
        <span className="truncate-text">{name}</span>
        <span>{`.${ext}`}</span>
      </>
    );
  };

  return (
    <div className="chat-text-container ps-0 h-100">

      {!!Object.keys(activeChat).length && (
      <>
        <div className="settings-tray">
          <div className="friend-drawer no-gutters friend-drawer--grey">
            <UserPhoto imageId={activeChat.userData.profileImage} />
            <a target="_blank" href={`/user-detail/${activeChat.userData._id}`} className="text" rel="noreferrer">
              <h6>{`${activeChat.userData.firstName} ${activeChat.userData.lastName}`}</h6>
            </a>
          </div>
        </div>

        <div className="chat-panel">
          <div className="chat-messages" ref={chatBoxRef}>
            {activeChat.messages.map((message, index = 0) => {
              const direction = message.sender === activeChat.crmUser ? 'right' : 'left';
              return (

                <div className="chat__message-row" key={message._id}>
                  {
                  (direction === 'right' && (hasDeleteChatMessagePermission || hasEditChatMessagePermission)) && (
                    <div className="button__action-row">
                      {direction === 'right' && hasDeleteChatMessagePermission && (
                      <div className="chatBonIconContainer">
                        <BsTrashFill role="button" color="white" size={18} onClick={() => deleteMessageHandler(message._id)} />
                      </div>
                      )}
                      {
                    direction === 'right' && hasEditChatMessagePermission && (
                      <div className="chatBonIconContainer" onClick={() => handleEditClick(message._id, message.content)}>
                        <BsPenFill role="button" color="white" size={18} />
                      </div>
                    )
                  }
                    </div>
                  )
                }
                  <div className="chatMessageWrapper">
                    {(!message.isRead && message.sender !== activeChat.crmUser) && <span className="chat__unread-dot" />}
                    <div key={index} className={`chat-bubble chat-bubble--${direction}`}>
                      <Markdown options={{
                        overrides: {
                          a: {
                            // eslint-disable-next-line react/no-unstable-nested-components
                            component: ({ children, ...props }) => (
                              <a target="_blank" rel="noreferrer" {...props}>{children}</a>
                            ),
                          },
                        },
                      }}
                      >
                        {message.content}
                      </Markdown>
                      {!!message.fileName && <a aria-label="Open" target="_blank" className="chat-content__file" href={`${process.env.REACT_APP_SERVER_URL}/images/${message.fileName}`} download rel="noreferrer"><FontAwesomeIcon size="xl" className="pointer" icon={faFile} /></a> }
                    </div>
                  </div>
                  {direction === 'left' && hasDeleteChatMessagePermission && (
                    <div className="chatBonIconContainer">
                      <BsTrashFill role="button" color="white" size={18} onClick={() => deleteMessageHandler(message._id)} />
                    </div>
                  )}
                </div>
              );
            })}
          </div>

          <div className="chat-box-tray">
            <div className="chat-input__upload">
              <label htmlFor="file-input">
                <FontAwesomeIcon icon={faPaperclip} className="svg" />
                {isFileLoaded && <span className="chat-input__filename">{splitFileName(file.name)}</span>}
              </label>
              <input id="file-input" accept="image/*" type="file" ref={fileInputRef} onChange={(e) => setFile(e.target.files[0])} />
            </div>
            <input
              type="text"
              placeholder="Type your message here..."
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              onFocus={() => handleReadMessages(activeChat._id, activeChat.crmUser)}
              onKeyPress={(e) => (e.key === 'Enter' ? handleSumbit() : null)}
              ref={inputRef}
            />
            <FontAwesomeIcon
              className="svg"
              icon={faForward}
              onClick={() => handleSumbit()}
            />
          </div>
        </div>
      </>
      )}
    </div>
  );
};
