import { toast } from 'react-toastify';
import * as actionTypes from './affiliatesDashboardTypes';
import { apiHelper } from '../apiHelper';
import { toFixed } from '../../helpers/utils';

export const setStatusOption = (status) => ({
  type: actionTypes.SET_STATUS_OPTION,
  payload: status,
});

const setIsAffiliatesDashboardLoadingCreator = (status) => ({
  type: actionTypes.SET_IS_LOADING,
  payload: status,
});

export const setAnalysisCreator = (data) => ({
  type: actionTypes.SET_ANALYSIS,
  payload: data,
});

export const setFTDAffiliateDataCreator = (data) => ({
  type: actionTypes.SET_FIRST_TIME_DEPOSITS_DATA,
  payload: data,
});

export const setFTDAffiliateTotalCreator = (data) => ({
  type: actionTypes.SET_FIRST_TIME_DEPOSITS_TOTAL,
  payload: data,
});

export const setFTDFunnelDataCreator = (data) => ({
  type: actionTypes.SET_FUNNEL_NAME_DATA,
  payload: data,
});

export const setFTDFunnelTotalCreator = (data) => ({
  type: actionTypes.SET_FUNNEL_NAME_TOTAL,
  payload: data,
});

export const setCountryMapDataCreator = (data) => ({
  type: actionTypes.SET_COUNTRY_MAP_DATA,
  payload: data,
});

export const setTotalFtdByCountryCreator = (data) => ({
  type: actionTypes.SET_TOTAL_FTD_BY_COUNTRY,
  payload: data,
});

export const setStatisticByCountryCreator = (data) => ({
  type: actionTypes.SET_STATISTIC_BY_COUNTRY,
  payload: data,
});

export const setIsStatusLoading = (data) => ({
  type: actionTypes.SET_IS_STATUS_LOADING,
  payload: data,
});

export const setDataByStatusCreator = (data) => ({
  type: actionTypes.SET_DATA_BY_STATUS,
  payload: data,
});

export const setColorsDataByStatusCreator = (data) => ({
  type: actionTypes.SET_COLORS_DATA_BY_STATUS,
  payload: data,
});

export const setAffiliateFilterOptions = (data) => ({
  type: actionTypes.SET_AFFILIATE_FILTER_OPTIONS,
  payload: data,
});

export const setFunnelNameFilterOptions = (data) => ({
  type: actionTypes.SET_FUNNEL_NAME_FILTER_OPTIONS,
  payload: data,
});

const getParamsConfiguration = (filterObject) => {
  const params = {};

  if (filterObject?.period && filterObject?.period !== '' && filterObject?.period !== 'All') {
    params.created = `${filterObject.period[0]}||${filterObject.period[1]}`;
  }

  if (filterObject?.brand && filterObject?.brand !== 'all') {
    params.brandIds = filterObject.brand;
  }

  if (filterObject?.managerStatus) {
    const clientType = filterObject?.managerStatus === 3 ? '1,2,3' : filterObject?.managerStatus;
    params.clientTypes = clientType;
  }

  if (filterObject?.deskRole && filterObject?.deskRole !== 'all') {
    params.assignedToId = filterObject?.deskRole;
  }

  if (filterObject?.affiliate && filterObject?.affiliate !== 'all') {
    params.affiliateIds = filterObject?.affiliate;
  }

  if (filterObject?.funnelNames && filterObject?.funnelNames !== 'all') {
    params.funnelNames = filterObject?.funnelNames;
  }

  return params;
};

export const fetchAffiliatesDashboardAnalysis = (filterObject, isHideParse = false) => async (dispatch) => {
  if (!isHideParse) dispatch(setIsAffiliatesDashboardLoadingCreator(true));

  try {
    const params = getParamsConfiguration(filterObject);

    const response = await apiHelper('get', '/api/affiliate-dashboard/stats/', undefined, params);

    if (response.data && response.data.status) {
      const data = response.data.data ?? [];

      const analysisData = {
        leads: data.countUsers,
        firstTimeDeposits: data.countFTD,
        clientRate: data.cr,
        netDeposits: data.netDeposit,
      };

      dispatch(setAnalysisCreator(analysisData));
    } else {
      dispatch(setAnalysisCreator(null));
    }
  } catch (error) {
    toast.error(error.response.message);
  }

  if (!isHideParse) dispatch(setIsAffiliatesDashboardLoadingCreator(false));
};

export const fetchFTDByAffiliates = (page = 1, perPage = 5, sortColumn = null, sortColumnDirection = true, filterObject) => async (dispatch) => {
  dispatch(setIsAffiliatesDashboardLoadingCreator(true));

  try {
    const params = { page, perPage };
    const filterParams = getParamsConfiguration(filterObject);

    if (sortColumn) {
      params.sort = JSON.stringify({ [sortColumn]: sortColumnDirection ? -1 : 1 });
    }

    const response = await apiHelper(
      'get',
      '/api/affiliate-dashboard/ftd-by-affiliate-name/',
      undefined,
      { ...params, ...filterParams },
    );

    if (response.data && response.data.status) {
      const data = response.data.data ?? [];
      const totalCount = response.data.totalCount ?? 0;

      const preparedData = data.map((row) => ({
        affiliateId: row.affiliateId,
        funnel: row.affiliateName,
        leads: row.countUsers,
        ftd: row.countFTD,
        cr: row.cr,
        netDeposits: row.netDeposit,
      }));

      dispatch(setFTDAffiliateDataCreator(preparedData));
      dispatch(setFTDAffiliateTotalCreator(totalCount));
    } else {
      dispatch(setFTDAffiliateDataCreator([]));
      dispatch(setFTDAffiliateTotalCreator(0));
    }
  } catch (error) {
    toast.error(error.response.message);
  }

  dispatch(setIsAffiliatesDashboardLoadingCreator(false));
};

export const fetchFTDByFunnel = (page = 1, perPage = 5, sortColumn = null, sortColumnDirection = true, filterObject) => async (dispatch) => {
  dispatch(setIsAffiliatesDashboardLoadingCreator(true));

  try {
    const params = { page, perPage };
    const filterParams = getParamsConfiguration(filterObject);

    if (sortColumn) {
      params.sort = JSON.stringify({ [sortColumn]: sortColumnDirection ? -1 : 1 });
    }

    const response = await apiHelper('get', '/api/affiliate-dashboard/ftd-by-funnel-name/', undefined, { ...params, ...filterParams });

    if (response.data && response.data.status) {
      const data = response.data.data ?? [];
      const totalCount = response.data.totalCount ?? 0;

      const preparedData = data.map((row) => ({
        funnel: row.funnelName,
        leads: row.countUsers,
        ftd: row.countFTD,
        cr: row.cr,
        netDeposits: row.netDeposit,
      }));

      dispatch(setFTDFunnelDataCreator(preparedData));
      dispatch(setFTDFunnelTotalCreator(totalCount));
    } else {
      dispatch(setFTDFunnelDataCreator([]));
      dispatch(setFTDFunnelTotalCreator(0));
    }
  } catch (error) {
    toast.error(error.response.message);
  }

  dispatch(setIsAffiliatesDashboardLoadingCreator(false));
};

export const fetchFTDByCountry = (filterObject, isHideParse = false) => async (dispatch) => {
  if (!isHideParse) dispatch(setIsAffiliatesDashboardLoadingCreator(true));

  try {
    const params = getParamsConfiguration(filterObject);

    const response = await apiHelper('get', '/api/affiliate-dashboard/ftd-to-country/', undefined, params);

    if (response.data && response.data.status) {
      const data = response?.data?.data ?? { countriesInfo: [], totalFTDUsers: 0 };

      const countryMapData = data.countriesInfo.map((row) => ([row.country, row.countFTD]));
      const preparedCountryMapData = [['Country', 'Count'], ...countryMapData];

      const preparedStatistic = data.countriesInfo.map((row) => ({
        country: row.country,
        isoCountryCode: row.iso,
        countFTD: row.countFTD,
        percent: row.percent,
      }));

      dispatch(setCountryMapDataCreator(preparedCountryMapData));
      dispatch(setTotalFtdByCountryCreator(data.totalFTDUsers));
      dispatch(setStatisticByCountryCreator(preparedStatistic));
    } else {
      dispatch(setCountryMapDataCreator([]));
      dispatch(setTotalFtdByCountryCreator(0));
      dispatch(setStatisticByCountryCreator([]));
    }
  } catch (error) {
    toast.error(error.response.message);
  }

  if (!isHideParse) dispatch(setIsAffiliatesDashboardLoadingCreator(false));
};

export const fetchFTDByStatus = (filterObject, isHideParse = false) => async (dispatch) => {
  if (!isHideParse) dispatch(setIsAffiliatesDashboardLoadingCreator(true));
  if (!isHideParse) dispatch(setIsStatusLoading(true));

  try {
    const params = getParamsConfiguration(filterObject);

    const response = await apiHelper('get', '/api/affiliate-dashboard/ftd-to-status/', undefined, params);

    if (response.data && response.data.status) {
      const data = response?.data?.data ?? { statusesInfo: [] };

      const donutData = data.statusesInfo?.map((row) => ([`${row.status}: ${row.countFTD} (${toFixed(row.percent, 2)}%)`, row.countFTD])) ?? [];
      const preparedDonutData = [['Status', 'Count'], ...donutData];

      const colorData = data.statusesInfo?.reduce((acc, statusInfo) => {
        acc[statusInfo.status] = statusInfo.color;
        return acc;
      }, {});

      dispatch(setDataByStatusCreator(preparedDonutData));
      dispatch(setColorsDataByStatusCreator(Object.keys(colorData).length ? colorData : null));
    } else {
      dispatch(setDataByStatusCreator([]));
      dispatch(setColorsDataByStatusCreator([]));
    }
  } catch (error) {
    toast.error(error.response.message);
  }

  if (!isHideParse) dispatch(setIsAffiliatesDashboardLoadingCreator(false));
  if (!isHideParse) dispatch(setIsStatusLoading(false));
};

export const fetchByAllStatuses = (filterObject) => async (dispatch) => {
  dispatch(setIsStatusLoading(true));

  try {
    const params = getParamsConfiguration(filterObject);

    const response = await apiHelper('get', '/api/affiliate-dashboard/all-statuses/', undefined, params);

    if (response.data && response.data.status) {
      const data = response?.data?.data ?? { statusesInfo: [] };

      const donutData = data.statusesInfo?.map((row) => [
        `${row.status}: ${row.countUsers} (${toFixed(row.percent, 2)}%)`,
        row.countUsers,
      ]) ?? [];
      const preparedDonutData = [['Status', 'Amount'], ...donutData];

      const colorData = data.statusesInfo?.reduce((acc, statusInfo) => {
        acc[statusInfo.status] = statusInfo.color;
        return acc;
      }, {});

      dispatch(setDataByStatusCreator(preparedDonutData));
      dispatch(setColorsDataByStatusCreator(colorData));
    } else {
      dispatch(setDataByStatusCreator([]));
      dispatch(setColorsDataByStatusCreator([]));
    }
  } catch (error) {
    // toast.error(error.response.message);
  }

  dispatch(setIsStatusLoading(false));
};

export const getAffiliateOptions = () => async (dispatch) => {
  try {
    const response = await apiHelper('get', '/api/affiliate/options');

    if (response.data && response.data.success) {
      const { options } = response.data;
      await dispatch(setAffiliateFilterOptions(options));
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const getFunnelOptions = (selectedAffiliates = [], affiliateRequired = true) => async (dispatch) => {
  try {
    const response = await apiHelper('get', '/api/affiliate/funnelname-options', null, {
      selectedAffiliates,
      affiliateRequired,
    });

    if (response.data && response.data.success) {
      const { options } = response.data;
      await dispatch(setFunnelNameFilterOptions(options));
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};
