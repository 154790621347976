import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';

import { addUserBankAccount } from '../../../../../redux/userBankAccount/userBankAccountActions';


export function ModalAddUserBankAccount({
  hideModal, fiatCurrency, userId,
}) {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    name: '',
    iban: '',
    accountNumber: '',
    bankAddress: '',
    swiftCode: '',
    currencyName: 'USD',
    alias: '',
    beneficiary: '',
    beneficiaryAddress: '',
    correspondentAccount: '',
    inn: '',
    kpp: '',
    reference: '',
  });

  const { handleSubmit } = useForm();

  const handleSave = async () => {
    await dispatch(addUserBankAccount({ userId, data: formData }));
    hideModal();
  };
  
  return (
    <div>
      <form onSubmit={handleSubmit(handleSave)}>
        <div className="form-group col-md-12">
          <div className="select-row">
            <div className="form-group col-md-6 pt-2">
              <label className="control-label" htmlFor="currency">Currency</label>
              <select
                name="currency"
                value={formData.currencyName}
                className="form-control user-status-select"
                onChange={({ target: { value } }) => setFormData((prevState) => ({ ...prevState, currencyName: value }))}
              >
                {fiatCurrency.map(({ symbol, _id }) => <option key={_id} value={symbol}>{symbol}</option>)}
              </select>
            </div>
            <div className="form-group col-md-12 pt-2">
              <label className="control-label" htmlFor="alias">Alias</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Alias"
                name="alias"
                value={formData.alias}
                onChange={({ target: { value } }) => setFormData((prevState) => ({ ...prevState, alias: value }))}
              />
            </div>
          </div>
          <div className="select-row">
            <div className="form-group col-md-12 pt-2">
              <label className="control-label">Account Number</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Account Number"
                name="accountNumber"
                value={formData.accountNumber}
                onChange={({ target: { value } }) => setFormData((prevState) => ({ ...prevState, accountNumber: value }))}
              />
            </div>
            <div className="form-group col-md-12 pt-2">
              <label className="control-label" htmlFor="bankName">Bank Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Bank Name"
                name="bankName"
                value={formData.name}
                onChange={({ target: { value } }) => setFormData((prevState) => ({ ...prevState, name: value }))}
              />
            </div>
          </div>
          <div className="select-row">
            <div className="form-group col-md-12 pt-2">
              <label className="control-label" htmlFor="iban">IBAN</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter IBAN"
                name="iban"
                value={formData.iban}
                onChange={({ target: { value } }) => setFormData((prevState) => ({ ...prevState, iban: value }))}
              />
            </div>
            <div className="form-group col-md-12 pt-2">
              <label className="control-label" htmlFor="beneficiary">Beneficiary</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Beneficiary"
                name="beneficiary"
                value={formData.beneficiary}
                onChange={({ target: { value } }) => setFormData((prevState) => ({ ...prevState, beneficiary: value }))}
              />
            </div>
          </div>
          <div className="select-row">
            <div className="form-group col-md-12 pt-2">
              <label className="control-label" htmlFor="beneficiaryAddress">Beneficiary Address</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Beneficiary Address"
                name="beneficiaryAddress"
                value={formData.beneficiaryAddress}
                onChange={({ target: { value } }) => setFormData((prevState) => ({ ...prevState, beneficiaryAddress: value }))}
              />
            </div>
            <div className="form-group col-md-12 pt-2">
              <label className="control-label" htmlFor="correspondentAccount">Correspondent Account</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Correspondent Account"
                name="correspondentAccount"
                value={formData.correspondentAccount}
                onChange={({ target: { value } }) => setFormData((prevState) => ({ ...prevState, correspondentAccount: value }))}
              />
            </div>
          </div>
          <div className="select-row">
            <div className="form-group col-md-12 pt-2">
              <label className="control-label" htmlFor="swiftCode">SWIFT/BIC</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter SWIFT/BIC"
                name="swiftCode"
                value={formData.swiftCode}
                onChange={({ target: { value } }) => setFormData((prevState) => ({ ...prevState, swiftCode: value }))}
              />
            </div>
            <div className="form-group col-md-12 pt-2">
              <label className="control-label" htmlFor="reference">Reference</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Reference"
                name="reference"
                value={formData.reference}
                onChange={({ target: { value } }) => setFormData((prevState) => ({ ...prevState, reference: value }))}
              />
            </div>
          </div>
          <div className="select-row">
            <div className="form-group col-md-12 pt-2">
              <label className="control-label" htmlFor="kpp">KPP (RU)</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter KPP"
                name="kpp"
                value={formData.kpp}
                onChange={({ target: { value } }) => setFormData((prevState) => ({ ...prevState, kpp: value }))}
              />
            </div>
            <div className="form-group col-md-12 pt-2">
              <label className="control-label" htmlFor="inn">INN (RU)</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter INN"
                name="inn"
                value={formData.inn}
                onChange={({ target: { value } }) => setFormData((prevState) => ({ ...prevState, inn: value }))}
              />
            </div>
          </div>
          <div className="form-group col-md-12 pt-2" style={{ width: '50%' }}>
            <label className="control-label" htmlFor="bankAddress">Bank Address</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Bank Address"
              name="bankAddress"
              value={formData.bankAddress}
              onChange={({ target: { value } }) => setFormData((prevState) => ({ ...prevState, bankAddress: value }))}
            />
          </div>
        </div>
        <div className="m-2 d-flex justify-content-end">
          <button className="btn btn-default" type="submit">Save</button>
        </div>
      </form>
    </div>
  );
}
