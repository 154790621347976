import React, { useEffect } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

export function DoughnutChart({ data, colors, isMobile = false }) {
  useEffect(() => {
    const root = am5.Root.new('chartdiv');

    root.setThemes([am5themes_Animated.new(root)]);

    const chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        layout: root.verticalLayout,
      }),
    );

    const series = chart.series.push(
      am5percent.PieSeries.new(root, {
        alignLabels: true,
        calculateAggregates: true,
        valueField: 'value',
        categoryField: 'category',
        radiusValueField: 'value',
        legendLabelText: '{category}',
        legendValueText: '{value}', 
      }),
    );

    const colorSet = am5.ColorSet.new(root, {
      colors: Object.values(colors).map((color) => am5.color(color)),
    });

    series.set('colors', colorSet);

    // Prepare data for the chart
    const formattedData = data.slice(1).map(([category, value]) => {
      const [salesStatus] = category.split(':');
      return {
        category: salesStatus,
        value: Number(value),
        color: colors && colors[salesStatus] ? am5.color(colors[salesStatus]) : am5.color('#fff'),
      };
    }).sort((a, b) => b.value - a.value);

    series.slices.template.setAll({
      propertyFields: { fill: 'color' },
      strokeOpacity: 0.3,
      strokeWidth: 3,
      stroke: am5.color(0x1d1f38),
    });

    series.labelsContainer.set('paddingTop', 30);
    
    series.slices.template.adapters.add('radius', (radius, target) => {
      const { dataItem } = target;
      const high = series.getPrivate('valueHigh');
    
      if (dataItem) {
        const value = target.dataItem.get('valueWorking', 0);
        return (radius * value) / high;
      }
      return radius;
    });

    series.labels.template.setAll({
      forceHidden: true,
      text: '[bold{color}]{category}[/]',
      fill: am5.color('#ffffff'),
      fontSize: isMobile ? 10 : 12,
      inside: true,
    });

    series.ticks.template.setAll({
      forceHidden: true,
    });

    series.data.setAll(formattedData);
    series.appear(1000, 100);

    const legend = chart.children.push(am5.Legend.new(root, {
      centerX: am5.p50,
      x: am5.p50,
      marginTop: 15,
      marginBottom: 15,
    }));

    legend.labels.template.setAll({
      fill: am5.color('#ffffff'),
      fontSize: isMobile ? 10 : 12,
    });
    
    legend.valueLabels.template.setAll({
      fill: am5.color('#ffffff'),
      fontSize: isMobile ? 10 : 12,
    });
    
    legend.data.setAll(series.dataItems);
    
    root.resizeTimeout = setTimeout(() => {
      root.invalidate();
    }, 500);
    
    return () => {
      if (root.resizeTimeout) clearTimeout(root.resizeTimeout);
      root.dispose();
    };
  }, [data, colors, isMobile]);

  return <div id="chartdiv" style={{ width: '100%', height: '500px', marginBottom: '40px' }} />;
}
