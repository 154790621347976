/* eslint-disable react/no-children-prop */
import React, { useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faTrash, faDownload } from '@fortawesome/free-solid-svg-icons';
import { Switch } from '@mui/material';
import Swal from 'sweetalert2';

import { ModalContext } from '../context/ModalContext';
import { TooltipComponent } from '../components/TooltipComponent/TooltipComponent';
import { SortWrapper, useTableSorting } from '../helpers/sortHelper';
import SortIcon from '../components/SortIcon';
import { ModalFilters } from '../components';
import { formatDate, prettyCutOutputByCellWidth, valueOutputCutLength } from './helper';
import { EditDocumentsModal } from '../pages/Documents/EditDocumentsModal';
import { deleteDocument, downloadSignedDocuments, updateStatusDocument } from '../redux/documents/documentsActions';

import styles from '../pages/Documents/Documents.module.css';

export const DocumentsSchema = (
  brandsFilters,
  setBrandsFilters,
  documentsFilter,
  setDocumentsFilter,
  handleSort,
) => {
  const dispatch = useDispatch();
  const { showModal } = useContext(ModalContext);

  const brands = useSelector((state) => state.brands);
  const { sortFields, getSortIcon } = useTableSorting(handleSort);
  const { currentUserPermissions } = useSelector((state) => state.crmUser);
  const isUserCanEditDocument = currentUserPermissions && currentUserPermissions.length > 0 && currentUserPermissions.includes('documents_edit');
  const isUserCanDeleteDocument = currentUserPermissions && currentUserPermissions.length > 0 && currentUserPermissions.includes('documents_delete');
  const isUserCanDownloadDocument = currentUserPermissions && currentUserPermissions.length > 0 && currentUserPermissions.includes('documents_download');

  const editAction = (id, fields, brandId, documentName, editedFields, isMainDoc) => {
    showModal({
      headerContent: <h3>Edit Documents</h3>,
      bodyContent: <EditDocumentsModal 
        id={id}
        fields={fields}
        brandId={brandId}
        documentName={documentName}
        editedFields={editedFields}
        isMainDoc={isMainDoc}
      />,
    });
  };

  const deleteAction = (id) => {
    Swal.fire({
      title: 'Are you sure you want to delete this document?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteDocument(id));
      }
    });
  };

  const updateDocumentStatus = (id, status) => {
    dispatch(updateStatusDocument({ id, status }));
  };

  const downloadDocsHandler = (id, docsAmount) => {
    if (docsAmount === 0) {
      toast.error('No files to download');
      return;
    }
    dispatch(downloadSignedDocuments(id));
  };

  const columns = [
    {
      name: (
        <div className="d-flex align-items-center flex-column search-input-des">
          <SortWrapper handleSort={() => sortFields('documentName')}>
            <div className="d-flex flex-row">
              <span className="variableHeaderText">Document</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('documentName')} />
              </button>
            </div>
          </SortWrapper>
        </div>
      ),
      minWidth: '180px',
      cell: ({ documentName }) => (
        documentName?.length >= valueOutputCutLength.documentName
          ? (
            <TooltipComponent 
              title={documentName}
              children={(
                <span style={{ cursor: 'pointer' }}>
                  {prettyCutOutputByCellWidth(documentName, 'documentName')}
                </span>
                  )}
            />
          )
          : (
            <span>
              {documentName?.length > 0 ? documentName : '-'}
            </span>
          )   
      ),
    },
    {
      name: (
        <div className="d-flex align-items-center flex-column search-input-des">
          <SortWrapper handleSort={() => sortFields('signedCount')}>
            <div className="d-flex flex-row">
              <span className="variableHeaderText">Signed Documents</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('signedCount')} />
              </button>
            </div>
          </SortWrapper>
        </div>
      ),
      minWidth: '160px',
      cell: ({ _id, signedCount }) => (
        <div className={styles.btnContainer}>
          {isUserCanDownloadDocument && (
            <button type="button" className={styles.btn} onClick={() => downloadDocsHandler(_id, signedCount)}>
              <FontAwesomeIcon icon={faDownload} color="white" />
            </button>
          )}
          <span>{signedCount || 0}</span>
        </div>
      ),
    },
    {
      name: (
        <div className="d-flex align-items-center flex-column search-input-des">
          <SortWrapper handleSort={() => sortFields('updatedAt')}>
            <div className="d-flex flex-row">
              <span className="variableHeaderText">Last Updated</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('updatedAt')} />
              </button>
            </div>
          </SortWrapper>
        </div>
      ),
      minWidth: '150px',
      cell: ({ updatedAt }) => (updatedAt ? formatDate(new Date(updatedAt)) : '-'),
    },
    {
      name: (
        <div className="d-flex align-items-center flex-column search-input-des">
          <SortWrapper handleSort={() => sortFields('version')}>
            <div className="d-flex flex-row">
              <span className="variableHeaderText">Version</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('version')} />
              </button>
            </div>
          </SortWrapper>
        </div>
      ),
      minWidth: '100px',
      cell: ({ version }) => (
        <span>
          { `V${version}` }
        </span>
      ),
    },
    {
      name: (
        <div className="d-flex align-items-center flex-row">
          <SortWrapper handleSort={() => sortFields('brandName')}>
            <div className="d-flex flex-row">
              <span className="variableHeaderText">Brand</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('brandName')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters 
            tabName="Brand" 
            data={brands} 
            filters={brandsFilters} 
            setFilters={setBrandsFilters} 
            searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
            usersFilters={documentsFilter}
            setUserFilters={setDocumentsFilter}
          />
        </div>
      ),
      minWidth: '120px',
      cell: ({ brandName }) => {
        if (brandName?.length === 0) return '-';
        return `${brandName}`;
      },
    },
    {
      name: (
        <div className="d-flex align-items-center flex-row">
          <SortWrapper handleSort={() => sortFields('status')}>
            <div className="d-flex flex-row">
              <span className="variableHeaderText">Status</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('status')} />
              </button>
            </div>
          </SortWrapper>
        </div>
      ),
      maxWidth: '100px',
      cell: ({ _id, status }) => (
        <Switch checked={status} color="success" onChange={(_, checked) => updateDocumentStatus(_id, checked)} />
      ),
    },
    {
      name: 'Actions',
      minWidth: '200px',
      cell: ({
        _id, fields, brandId, documentName, editedFields, isMainDoc,
      }) => (
        <>
          {isUserCanEditDocument && (
          <button 
            type="button"  
            className="btn btn-success btn-sm me-1 p-1" 
            onClick={() => editAction(_id, fields, brandId, documentName, editedFields, isMainDoc)}
          >
            <FontAwesomeIcon icon={faPencil} />
          </button>
          )}
          {(isUserCanDeleteDocument && !isMainDoc) && (
          <button 
            type="button" 
            className="btn btn-danger btn-sm me-1 p-1"
            onClick={() => deleteAction(_id)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
          )}
        </>
      ),
    },
  ];

  return columns;
};

