/* eslint-disable array-callback-return */
import React, { useContext, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ModalContext } from '../../context';

import styles from './Documents.module.css';
import { editDocuments } from '../../redux/documents/documentsActions';


export function EditDocumentsModal({
  id, fields, brandId, documentName, editedFields, isMainDoc,
}) {
  const dispatch = useDispatch();
  const { hideModal } = useContext(ModalContext);
  const allBrands = useSelector((state) => state.brands);

  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState(false);
  const [brandIds, setBrandIds] = useState(brandId);
  const [newDoc, setNewDoc] = useState(isMainDoc);
  const [formData, setFormData] = useState(
    fields.reduce((acc, field) => ({
      ...acc, 
      documentName,
      brandId,
      newDoc,
      [field]: editedFields[field], 
    }), {}),
  );

  const brandsOptions = useMemo(
    () => allBrands.map(({ name, _id }) => ({
      key: _id,
      value: _id,
      label: name,
    })), 
    [allBrands],
  );
  const handleFieldChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const renderFieldInputs = () => (fields.map((field) => (
    <div className="form-group" key={field}>
      <label className="lead" htmlFor={field}>{`Specify the ${field}`}</label>
      <input
        type="text"
        className="form-control"
        aria-label=""
        aria-describedby=""
        value={formData[field]}
        name={field}
        onChange={(e) => handleFieldChange(field, e.target.value)}
        key={field}
      />
    </div>
  )));

  
  const submitForm = async (event) => {
    event.preventDefault();
    setDisabledSubmitBtn(true);
    await dispatch(editDocuments(
      { id, data: formData },
    ));
    hideModal();
  };

  const handleChangeCheckbox = (checked) => {
    setNewDoc(checked);
    setFormData((prev) => ({ ...prev, newDoc: checked }));
  };

  const handelBrandSelect = ({ target: { value } }) => {
    setBrandIds(value);
    setFormData((prev) => ({ ...prev, brandId: value }));
  };

  return (
    <form onSubmit={submitForm}>
      <div className="form-group">
        <label className="lead" htmlFor="documentName">Name PDF file</label>
        <input
          type="text"
          className="form-control"
          aria-label=""
          aria-describedby=""
          value={formData.documentName}
          name="documentName"
          onChange={(e) => handleFieldChange('documentName', e.target.value)}
        />
      </div>
      { renderFieldInputs() }
      <div className="form-group">
        <label className="lead" htmlFor="brandId">Brand</label>
        <select
          name="brandId"
          value={brandIds}
          className={styles.selector}
          onChange={handelBrandSelect}
        >
          {brandsOptions.map(({ key, label, value }) => <option key={key} value={value}>{label}</option>)}
        </select>
      </div>
      <div className={styles.checkboxContainer}>
        <label className="lead" htmlFor="newDoc">Create new Document</label>
        <input
          type="checkbox"
          name="newDoc"
          className={styles.checkbox}
          checked={newDoc}
          disabled={isMainDoc}
          onChange={({ target: { checked } }) => handleChangeCheckbox(checked)}
        />
      </div>
      <div className="m-2 d-flex justify-content-end">
        <button className="btn btn-default" type="submit" disabled={disabledSubmitBtn}>
          Add
        </button>
      </div>
    </form>
  );
}
