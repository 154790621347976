import {
  GET_ALL_DOCUMENTS, EDIT_DOCUMENT, DELETE_DOCUMENT, UPDATE_DOCUMENT_STATUS, GET_DOCUMENTS_OPTION,
} from './documentsTypes';
  
const initialState = {
  allDocuments: [],
  totalCount: 0,
  documentsOption: [],
};
  
const documentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_DOCUMENTS:
      return {
        ...state,
        allDocuments: action.payload.paginatedData,
        totalCount: action.payload?.totalCount,
      };
    case EDIT_DOCUMENT: {
      let updated = false;
        
      const updatedDocuments = state.allDocuments.map((doc) => {
        if (doc._id === action.payload._id) {
          updated = true;
          return { ...action.payload };
        }
        return doc;
      });
      
      if (!updated) {
        updatedDocuments.push(action.payload);
      }
      
      return {
        ...state,
        allDocuments: updatedDocuments,
      };
    }
    case DELETE_DOCUMENT:
      return {
        ...state,
        allDocuments: state.allDocuments.filter((doc) => doc._id !== action.payload),
        totalCount: state.totalCount - 1,
      };
    case UPDATE_DOCUMENT_STATUS:
      return {
        ...state,
        allDocuments: state.allDocuments.map((doc) => (doc._id === action.payload._id ? { ...doc, status: action.payload.status } : { ...doc })),
      };  
    case GET_DOCUMENTS_OPTION:
      return {
        ...state,
        documentsOption: action.payload,
      }; 
    default:
      return state;
  }
};
  
export default documentsReducer;
  
