/* eslint-disable no-shadow */
import { toast } from 'react-toastify';
import {
  GET_SALES_STATUSES,
  GET_SALES_STATUS,
  DELETE_SALES_STATUS,
  TOGGLE_STATE,
  ERROR_STATE,
  GET_SALES_STATUSES_TABLE_DATA,
  GET_USER_STATUS_OPTIONS,
} from './salesStatusTypes';
import { apiHelper } from '../apiHelper';

export const getUserSalesStatusOptions = (userId) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/sales-status/options/user/${userId}`, '');
    if (res?.data && res.data.success) {
      const { data } = res;

      dispatch({
        type: GET_USER_STATUS_OPTIONS,
        payload: data.options,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const exportFilteredDataToFile = async (fileType, currentPage, brandId) => {
  try {
    const res = await apiHelper('post', '/api/sales-status/export', { fileType, currentPage, brandId });

    if (res.data && res.data.success) {
      window.open(res.data.fileUrl);
    }

    return res.data;
  } catch (error) {
    toast.error(error.response.message);

    return error;
  }
};

export const getSalesStatusesTableData = (statusType = 'sales-list', brandId) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/sales-status/${statusType}`, '', { brandId });

    if (res?.data?.success) {
      await dispatch({
        type: GET_SALES_STATUSES_TABLE_DATA,
        payload: res.data,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const getSalesStatuses = () => async (dispatch) => {
  try {
    const res = await apiHelper('get', '/api/sales-status', '');
    if (res?.data && res?.data?.salesStatuses) {
      const { data } = res;
      await dispatch({
        type: GET_SALES_STATUSES,
        payload: data?.salesStatuses,
      });
    } else {
      await dispatch({
        type: GET_SALES_STATUSES,
        payload: [],
      });
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const getSalesStatus = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/sales-status/${id}`, '');
    if (res?.data && res?.data?.salesStatus) {
      const { data } = res;
      await dispatch({
        type: GET_SALES_STATUS,
        payload: data?.salesStatus,
      });
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const addSalesStatus = (data, currentPage, brandId) => async (dispatch) => {
  try {
    const res = await apiHelper('post', '/api/sales-status/add', data);

    if (res?.data) {
      const { data } = res;
      toast.success(data?.message);

      dispatch(getSalesStatusesTableData(currentPage, brandId));
    } else {
      await dispatch({
        type: ERROR_STATE,
      });
    }
  } catch (error) {
    // console.log(error.message);
    toast.error(error.message);
  }
};

export const editSalesStatus = (id, data, currentPage, brandId) => async (dispatch) => {
  try {
    const res = await apiHelper('put', `/api/sales-status/${id}`, data);

    if (res?.data) {
      const { data } = res;
      toast.success(data?.message);
      dispatch(getSalesStatusesTableData(currentPage, brandId));
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const deleteSalesStatus = (id, currentPage, brandId) => async (dispatch) => {
  try {
    const res = await apiHelper('delete', `/api/sales-status/${id}`, '');
    if (res?.data) {
      const { data } = res;
      toast.success(data?.message);
      dispatch(getSalesStatusesTableData(currentPage, brandId));
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const updateSalesStatusPriority = (data, currentPage, brandId) => async (dispatch) => {
  try {
    const res = await apiHelper('post', '/api/sales-status/priority', {
      newPriorities: data,
      currentPage,
    });
    if (res?.data) {
      dispatch(getSalesStatusesTableData(currentPage, brandId));
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const updateState = () => async (dispatch) => {
  try {
    await dispatch({
      type: TOGGLE_STATE,
    });
  } catch (error) {
    // console.log(error.response.message);
  }
};
