import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { generateSortParams } from '../../../../helpers/sortHelper';
import { UserBankTransferAccountSchema } from './UserBankTransferAccountSchema/UserBankTransferAccountSchema';
import { ModalContext } from '../../../../context';
import { ModalAddUserBankAccount } from './Modals/ModalAddUserBankAccount';
import { selectFiatCurrenciesSelector } from '../../../../redux/currency/currencySelector';
import { fiatCurrencies } from '../../../../redux/currency/currencyActions';
import { selectAllUserBankAccount, selectAllUserBankAccountTotalCount } from '../../../../redux/userBankAccount/userBankAccountSelector';
import { getUserBankAccount } from '../../../../redux/userBankAccount/userBankAccountActions';


export function UserBankTransferAccount() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(1);
  const [tableLoading, setTableLoading] = useState(false);
  const [sortParams, setSortParams] = useState(JSON.stringify({ createdAt: -1 }));
  const [assetFilters, setAssetFilters] = useState([]);
  const [status, setStatus] = useState([]);
  const [filter, setFilter] = useState({});

  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions);
  const fiatCurrency = useSelector(selectFiatCurrenciesSelector);
  const paginatedData = useSelector(selectAllUserBankAccount);
  const totalCount = useSelector(selectAllUserBankAccountTotalCount);

  const { showModal, hideModal } = useContext(ModalContext);

  const isUserCanAddBankAccount = permissionName && permissionName.length > 0 && permissionName.includes('add_admin_bank_account');

  useEffect(() => {
    async function fetchData() {
      if (id) {
        setTableLoading(true);
  
        await dispatch(getUserBankAccount({
          id, limit: rowsPerPage, page, sort: sortParams, query: filter,
        }));
  
        setTableLoading(false);
      }
    }

    fetchData();
  }, [id, page, rowsPerPage, sortParams, filter]);

  useEffect(() => {
    dispatch(fiatCurrencies());
  }, []);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (currentRowsPerPage, page) => {
    setRowsPerPage(currentRowsPerPage);
  };

  const handleSort = async (sortField, sortDirection) => {
    const sort = generateSortParams(sortField, sortDirection);
    setSortParams(sort);
  };

  const columns = UserBankTransferAccountSchema(
    handleSort,
    filter,
    setFilter,
    fiatCurrency,
    assetFilters,
    setAssetFilters,
    status,
    setStatus,
    id,
  );

  return (
    <div className="content-wrapper right-content-wrapper">
      <div className="action__btn-row">
        <div className="main_btn-row">
          <div className="secondary_btn-row">
            {isUserCanAddBankAccount && (
              <button 
                type="button" 
                className="btn-primary_light"
                onClick={() => showModal({ 
                  bodyContent: <ModalAddUserBankAccount 
                    hideModal={hideModal} 
                    fiatCurrency={fiatCurrency}
                    userId={id}
                  />, 
                  headerContent: <h3>Add Bank Account</h3>, 
                })}
              >
                <FontAwesomeIcon icon={faPlus} size="sm" />
                Add New
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="dashboard-tbl-wrapper custom-tbl-wrapper">
        <DataTable
          columns={columns}
          data={paginatedData}
          theme="solarizedd"
          fixedHeader
          pagination
          paginationServer
          paginationTotalRows={totalCount}
          paginationPerPage={rowsPerPage}
          paginationRowsPerPageOptions={[5, 10, 25, 50]}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          paginationDefaultPage={page}
          persistTableHead
          highlightOnHover
          progressPending={tableLoading}
          progressComponent={<div className="datatable-loader__background" />}
        />
      </div>
    </div>
  );
}
