import { useEffect } from 'react';

export const useMultiDebounce = (value = null, delay, callback) => {
  useEffect(() => {
    if (typeof value === 'object') {
      const handler = setTimeout(() => {
        callback(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }
  }, [...value]);
};

export const multiDebounceCallback = (inputValues, filterKeys, filters, setFilters, withCheckFields = [], initialLoadComplete, setInitialLoadComplete) => {
  const nextFilters = { ...filters };

  const setWithCheck = (key, value, minLength = 3) => {
    if ((value?.length >= minLength || value?.length === 0 || Array.isArray(value))) {
      nextFilters[key] = value;
    }
  };

  filterKeys.forEach((key, index) => {
    const value = inputValues[index];
    if (withCheckFields.includes(key)) {
      setWithCheck(key, value);
    } else {
      nextFilters[key] = value;
    }
  });

  const filtersChanged = JSON.stringify(nextFilters) !== JSON.stringify(filters);

  if (initialLoadComplete && filtersChanged) {
    setFilters(nextFilters);
  } else {
    setInitialLoadComplete(true);
  }
};
