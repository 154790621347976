import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { getCRMActivities, exportCRMActivityToFile } from '../../redux/crmActivity/crmActivityActions';
import { getActivityTypes } from '../../redux/activityType/activityTypeActions';
import { getSubCRMUsers } from '../../redux/crmUser/crmUserActions';
import { CRMActivityColumns } from '../../DataTableSchemas/CRMActivityColumns';
import { fetchCounties } from '../../redux/countries/countriesActions';
import { DownloadFile } from '../../components';
import { generateSortParams } from '../../helpers/sortHelper';
import { multiDebounceCallback, useMultiDebounce } from '../../hooks/useMultiDebounce';

function CRMActivity() {
  const dispatch = useDispatch();
  const paginationStorageName = 'DataTable_activity/crm_pagination';

  const [loader, setLoader] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(1);
  const [isPaginationDT, setIsPaginationDT] = useState(false);
  const [sortParams, setSortParams] = useState(JSON.stringify({ createdAt: -1 }));

  const [userFilters, setUserFilters] = useState({});
  const [crmUsersFilter, setCrmUsersFilter] = useState([]);
  const [crmActivityTypeFilter, setCrmActivityTypeFilter] = useState([]);
  const [clientNameFilter, setClientNameFilter] = useState(null);
  const [clientCustomIdFilter, setClientCustomIdFilter] = useState(null);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);

  const allCRMActivities = useSelector((state) => state?.crmActivity?.crmActivities);
  
  const getActivities = (page, rowsPerPage, userFilters, sortParams) => dispatch(getCRMActivities({
    page, rowsPerPage, userFilters, sortParams, 
  }));

  const setStoredPagination = () => {
    const crmActivityRowsJSON = localStorage.getItem(paginationStorageName);
    if (crmActivityRowsJSON) {
      const filterRows = JSON.parse(crmActivityRowsJSON);
      setRowsPerPage(filterRows.limit || 25);
    }
    
    setIsPaginationDT(true);
  };

  const handlePageChange = async (page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = async (currentRowsPerPage, page) => {
    localStorage.setItem(paginationStorageName, JSON.stringify({ limit: currentRowsPerPage }));
    setRowsPerPage(currentRowsPerPage);
  };

  const handleExportCRMActivity = async (fileType) => {
    await dispatch(exportCRMActivityToFile(fileType, page, rowsPerPage, userFilters));
  };
  
  useMultiDebounce([clientNameFilter, clientCustomIdFilter], 1000, (inputValues) => {
    multiDebounceCallback(
      inputValues,
      ['clientName', 'clientCustomId'],
      userFilters,
      setUserFilters,
      undefined,
      initialLoadComplete,
      setInitialLoadComplete,
    );
  });

  const handleSort = async (sortField, sortDirection) => {
    const sort = generateSortParams(sortField, sortDirection);
    setSortParams(sort);
  };

  const crmActivityCol = CRMActivityColumns(
    userFilters, 
    setUserFilters,
    crmUsersFilter,
    setCrmUsersFilter,
    crmActivityTypeFilter, 
    setCrmActivityTypeFilter,
    clientNameFilter,
    setClientNameFilter,
    handleSort,
    clientCustomIdFilter,
    setClientCustomIdFilter,
  );

  useEffect(() => {
    setLoader(true);
    const loginData = localStorage.getItem('user');
    const data = JSON.parse(loginData);
    const roleId = data?.roleId;
    const uid = data?._id;

    setStoredPagination();

    Promise.allSettled([
      dispatch(getActivityTypes()),
      dispatch(getSubCRMUsers(roleId, uid)),
      dispatch(fetchCounties()),
    ]);

    setLoader(false);
  }, []);

  useEffect(async () => {
    if (isPaginationDT) {
      setTableLoading(true);
      await getActivities(page, rowsPerPage, userFilters, sortParams);
      setTableLoading(false);
    }
  }, [isPaginationDT, page, rowsPerPage, userFilters, sortParams]);

  return (
    <div className="content-wrapper right-content-wrapper">
      <div className="content-box">
        <h3>CRM Users Activity Log</h3>
        <div className="action__btn-row">
          <DownloadFile handleExport={handleExportCRMActivity} />
        </div>
        <div className="dashboard-tbl-wrapper custom-tbl-wrapper">
          {
                isPaginationDT
                  && (
                    <DataTable
                      columns={crmActivityCol}
                      data={allCRMActivities.paginatedResult}
                      pagination
                      paginationServer
                      highlightOnHover
                      paginationPerPage={rowsPerPage}
                      paginationRowsPerPageOptions={[25, 50, 100, 500]}
                      paginationTotalRows={allCRMActivities.total}
                      onChangeRowsPerPage={handleRowsPerPageChange}
                      onChangePage={handlePageChange}
                      persistTableHead
                      selectableRowsHighlight
                      selectableRowsVisibleOnly
                      theme="solarizedd"
                      className="specific-field-table custome-table-scroll"
                      progressPending={tableLoading}
                      progressComponent={<div className="datatable-loader__background" />}
                    />
                  )
                }
        </div>
      </div>
    </div>
  );
}

export default CRMActivity;
