import { toast } from 'react-toastify';
import axios from 'axios';
import { apiHelper } from '../apiHelper';
import {
  GET_ALL_DOCUMENTS, EDIT_DOCUMENT, DELETE_DOCUMENT, UPDATE_DOCUMENT_STATUS, GET_DOCUMENTS_OPTION,
} from './documentsTypes';

const basicURL = `${process.env.REACT_APP_SERVER_URL}`;

export const getAllDocuments = ({
  sort, page, limit, query, 
}) => async (dispatch) => {
  try {
    let url = `?page=${page}&limit=${limit}&sort=${sort}`;

    Object.entries(query).forEach(([key, value]) => {
      url += `&${key}=${value}`;
    });
    const res = await apiHelper('get', `/api/documents/all-documents${url}`);

    if (res.data && res.data.success) {
      const { allDocumentsData } = res.data;

      await dispatch({
        type: GET_ALL_DOCUMENTS,
        payload: allDocumentsData,
      });
    }
  } catch (error) {
    toast.error(error.message);
  }
};

export const editDocuments = ({ id, data }) => async (dispatch) => {
  try {
    const res = await apiHelper(
      'patch',
      `/api/documents/edit-documents/${id}`,
      data,
    );

    if (res.data && res.data.success) {
      const { updatedDocument } = res.data;

      await dispatch({
        type: EDIT_DOCUMENT,
        payload: updatedDocument,
      });
    }
  } catch (error) {
    toast.error(error.message);
  }
};

export const deleteDocument = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('delete', `/api/documents/delete-documents/${id}`);

    if (res.data && res.data.success) {
      const { deletedDocId } = res.data;

      await dispatch({
        type: DELETE_DOCUMENT,
        payload: deletedDocId,
      });
    }
  } catch (error) {
    toast.error(error.message);
  }
};

export const updateStatusDocument = ({ id, status }) => async (dispatch) => {
  try {
    const res = await apiHelper(
      'patch',
      `/api/documents/update-status/${id}`,
      { status },
    );

    if (res.data && res.data.success) {
      const { updatedDoc } = res.data;

      await dispatch({
        type: UPDATE_DOCUMENT_STATUS,
        payload: updatedDoc,
      });
    }
  } catch (error) {
    toast.error(error.message);
  }
};

export const downloadSignedDocuments = (id) => async (dispatch) => {
  try {
    const endpointUrl = `/api/documents/download-signed-documents/${id}`;
    const downloadUrl = `${basicURL}${endpointUrl}`;
    const token = localStorage.getItem('token') ?? sessionStorage.getItem('token');

    axios({
      url: downloadUrl,
      method: 'GET',
      responseType: 'blob',
      headers: {
        Authorization: token ? JSON.parse(token) : null,
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement('a');
        a.href = url;
        a.download = 'signedDocuments.zip';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      })
      .catch((error) => console.error('Error downloading the file:', error));
  } catch (error) {
    toast.error(error.message);
  }
};

export const getDocumentsOption = () => async (dispatch) => {
  try {
    const res = await apiHelper('get', '/api/documents/documents-select-options');

    if (res.data && res.data.success) {
      const { documentsOptions } = res.data;

      await dispatch({
        type: GET_DOCUMENTS_OPTION,
        payload: documentsOptions,
      });
    }
  } catch (error) {
    toast.error(error.message);
  }
};
