import React, {
  useContext, useEffect, useMemo, useState, 
} from 'react';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import Switch from '@mui/material/Switch';
import { deleteAdminBankAccount, editAdminBankAccount } from '../redux/psp/adminBankAccountActions';
import {
  omitColumn, prettyCutOutputByCellWidth, valueOutputCutLength, ibDeskIdsOutput,
  countriesOutput, sortColumnByOrderConfig,
} from './helper';
import { ModalFilters } from '../components';
import { SortWrapper, useTableSorting } from '../helpers/sortHelper';
import SortIcon from '../components/SortIcon';
import { TooltipComponent } from '../components/TooltipComponent/TooltipComponent';
import { ModalContext } from '../context';
import { ModalEditAdminBankAccounts } from '../pages/PSP/modals/ModalEditAdminBankAccounts';

const isEnabledOptionData = [
  { _id: 'enabled', status: 'Enabled' },
  { _id: 'disabled', status: 'Disabled' },
];

export const BankAccountsPspSchema = ({
  permissionName,
  columnConfig,
  tempFilters,
  setTempFilters,
  showModalContext,
  handleSort,
  assetFilters,
  setAssetFilters,
  includedCountriesOptions,
  setIncludedCountriesOptions,
  excludedCountriesOptions,
  setExcludedCountriesOptions,
  status,
  setStatus,
  ibdeskFilterPreset,
  setIbdeskFilterPreset,
  allIbDesks,
  countries,
  fiatCurrency,
}) => {
  const dispatch = useDispatch();

  const { showModal, hideModal } = useContext(ModalContext);

  const { currentUserPermissions } = useSelector((state) => state.crmUser);

  const isUserCanEditPspOther = currentUserPermissions && currentUserPermissions.length > 0 && currentUserPermissions.includes('psp_other_edit');
  const isUserCanDeletePspOther = currentUserPermissions && currentUserPermissions.length > 0 && currentUserPermissions.includes('psp_other_delete');

  const deleteAction = (id) => {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then(async (result) => {
      if (result.isConfirmed === true) {
        dispatch(deleteAdminBankAccount(id));
      }
    });
  };

  const editAction = (id, alias, row) => {
    showModal({
      headerContent: <h3>{`Edit "${alias}" Withdrawal Method`}</h3>,
      bodyContent: (
        <ModalEditAdminBankAccounts
          id={id}
          row={row}
          hideModal={hideModal}
          allIbDesks={allIbDesks}
          countries={countries}
          fiatCurrency={fiatCurrency}
        />
      ),
    });
  };

  const handleStatusChange = (_id, status) => {
    dispatch(editAdminBankAccount(_id, { isEnabled: status }));
  };

  const { sortFields, getSortIcon } = useTableSorting(handleSort);

  const renderInputColumn = (fieldName, placeholder, filterKey) => (
    <div className="d-flex flex-column search-input-des" style={{ alignItems: 'start', width: '100%' }}>
      <SortWrapper handleSort={() => sortFields(filterKey)}>
        <div className="d-flex flex-row">
          <span>{fieldName}</span>
          <button type="button" className="sort-btn">
            <SortIcon image={getSortIcon(filterKey)} />
          </button>
        </div>
      </SortWrapper>
      <input
        type="text"
        name={filterKey}
        autoComplete="off"
        value={tempFilters[filterKey] || ''}
        placeholder={placeholder}
        className="email-templates-search-bar-filter"
        onChange={({ target: { value } }) => {
          setTempFilters({
            ...tempFilters,
            [filterKey]: value,
          });
        }}
      />
    </div>
  );

  const columns = [
    {
      name: (
        <div className="d-flex flex-row search-input-des" style={{ alignItems: 'start', width: '100%' }}>
          <SortWrapper handleSort={() => sortFields('currencyName')}>
            <div className="d-flex flex-row">
              <span>Currency</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('currencyName')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters
            tabName="Currency" 
            data={fiatCurrency} 
            filters={assetFilters} 
            setFilters={setAssetFilters} 
            searchParams={{ id: '_id', params: ['name', '_id', 'symbol'], optionsName: ['symbol'] }}
            usersFilters={tempFilters}
            setUserFilters={setTempFilters}
            style={{ left: '5px' }}
          />
        </div>
      ),
      cell: ({ currencyName }) => (
        <span>
          {currencyName?.length > 0 ? currencyName : '-'}
        </span>
      ),
      maxWidth: '140px',
      label: 'Currency',
      reorder: true,
      omit: omitColumn(columnConfig, 'Currency'), 
    },
    {
      name: (
        <div className="d-flex flex-row search-input-des" style={{ alignItems: 'start', width: '100%' }}>
          <SortWrapper handleSort={() => sortFields('ibDeskFullName')}>
            <div className="d-flex flex-row">
              <span>Desk/IB</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('ibDeskFullName')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters
            tabName="ibDeskId"
            data={allIbDesks}
            filters={ibdeskFilterPreset}
            setFilters={setIbdeskFilterPreset}
            searchParams={{ id: '_id', params: ['firstName', 'lastName'], optionsName: ['firstName', 'lastName'] }}
            usersFilters={tempFilters}
            setUserFilters={setTempFilters}
            style={{ left: '5px' }}
          />
        </div>
      ),
      cell: ({ ibDeskId }) => {
        const ibDeskIds = ibDeskIdsOutput(allIbDesks, ibDeskId);

        return (
          ibDeskIds?.length >= valueOutputCutLength.assignedTo
            ? (
              <TooltipComponent 
                title={ibDeskIds}
                // eslint-disable-next-line react/no-children-prop
                children={(
                  <span style={{ cursor: 'pointer' }}>
                    {prettyCutOutputByCellWidth(ibDeskIds, 'assignedTo')}
                  </span>
                  )}
              />
            )
            : (
              <span>
                {ibDeskIds?.length > 0 ? ibDeskIds : '-'}
              </span>
            )   

        );
      },
      maxWidth: '150px',
      label: 'Desk/IB',
      reorder: true,
      omit: omitColumn(columnConfig, 'Desk/IB'), 
    },
    {
      name: renderInputColumn('Alias', 'Filter by user Alias', 'alias'),
      cell: ({ alias }) => (
        alias?.length >= valueOutputCutLength.alias
          ? (
            <TooltipComponent 
              title={alias}
                // eslint-disable-next-line react/no-children-prop
              children={(
                <span style={{ cursor: 'pointer' }}>
                  {prettyCutOutputByCellWidth(alias, 'alias')}
                </span>
              )}
            />
          )
          : (
            <span>
              {alias?.length > 0 ? alias : '-'}
            </span>
          )   

      ),
      maxWidth: '120px',
      label: 'Alias',
      reorder: true,
      omit: omitColumn(columnConfig, 'Alias'), 
    },
    {
      name: renderInputColumn('Account Number', 'Filter by user Account Number', 'accountNumber'),
      cell: ({ accountNumber }) => (
        accountNumber?.length >= valueOutputCutLength.affiliateFullName
          ? (
            <TooltipComponent 
              title={accountNumber}
                // eslint-disable-next-line react/no-children-prop
              children={(
                <span style={{ cursor: 'pointer' }}>
                  {prettyCutOutputByCellWidth(accountNumber, 'affiliateFullName')}
                </span>
              )}
            />
          )
          : (
            <span>
              {accountNumber?.length > 0 ? accountNumber : '-'}
            </span>
          )   

      ),
      maxWidth: '120px',
      label: 'Account Number',
      reorder: true,
      omit: omitColumn(columnConfig, 'Account Number'), 
    },
    {
      name: renderInputColumn('IBAN', 'Filter by IBAN', 'iban'),
      cell: ({ iban }) => (
        iban?.length >= valueOutputCutLength.iban
          ? (
            <TooltipComponent 
              title={iban}
                // eslint-disable-next-line react/no-children-prop
              children={(
                <span style={{ cursor: 'pointer' }}>
                  {prettyCutOutputByCellWidth(iban, 'iban')}
                </span>
              )}
            />
          )
          : (
            <span>
              {iban?.length > 0 ? iban : '-'}
            </span>
          )   

      ),
      width: '230px',
      label: 'IBAN',
      reorder: true,
      omit: omitColumn(columnConfig, 'IBAN'), 
    },
    {
      name: renderInputColumn('Bank Name', 'Filter by user bank name', 'name'),
      cell: ({ name }) => (
        name?.length >= valueOutputCutLength.brand
          ? (
            <TooltipComponent 
              title={name}
                // eslint-disable-next-line react/no-children-prop
              children={(
                <span style={{ cursor: 'pointer' }}>
                  {prettyCutOutputByCellWidth(name, 'brand')}
                </span>
              )}
            />
          )
          : (
            <span>
              {name?.length > 0 ? name : '-'}
            </span>
          )   

      ),
      maxWidth: '140px',
      label: 'Bank Name',
      reorder: true,
      omit: omitColumn(columnConfig, 'Bank Name'), 
    },
    {
      name: renderInputColumn('Beneficiary', 'Filter by user Beneficiary', 'beneficiary'),
      cell: ({ beneficiary }) => (
        beneficiary?.length >= valueOutputCutLength.brand
          ? (
            <TooltipComponent 
              title={beneficiary}
                // eslint-disable-next-line react/no-children-prop
              children={(
                <span style={{ cursor: 'pointer' }}>
                  {prettyCutOutputByCellWidth(beneficiary, 'brand')}
                </span>
              )}
            />
          )
          : (
            <span>
              {beneficiary?.length > 0 ? beneficiary : '-'}
            </span>
          )   

      ),
      maxWidth: '140px',
      label: 'Beneficiary',
      reorder: true,
      omit: omitColumn(columnConfig, 'Beneficiary'), 
    },
    {
      name: renderInputColumn('Beneficiary Address', 'Filter by user Beneficiary Address', 'beneficiaryAddress'),
      cell: ({ beneficiaryAddress }) => (
        beneficiaryAddress?.length >= valueOutputCutLength.brand
          ? (
            <TooltipComponent 
              title={beneficiaryAddress}
                // eslint-disable-next-line react/no-children-prop
              children={(
                <span style={{ cursor: 'pointer' }}>
                  {prettyCutOutputByCellWidth(beneficiaryAddress, 'brand')}
                </span>
              )}
            />
          )
          : (
            <span>
              {beneficiaryAddress?.length > 0 ? beneficiaryAddress : '-'}
            </span>
          )   

      ),
      maxWidth: '140px',
      label: 'Beneficiary Address',
      reorder: true,
      omit: omitColumn(columnConfig, 'Beneficiary Address'), 
    },
    {
      name: renderInputColumn('Correspondent Account', 'Filter by user Correspondent Account', 'correspondentAccount'),
      cell: ({ correspondentAccount }) => (
        correspondentAccount?.length >= valueOutputCutLength.phoneNumber
          ? (
            <TooltipComponent 
              title={correspondentAccount}
                // eslint-disable-next-line react/no-children-prop
              children={(
                <span style={{ cursor: 'pointer' }}>
                  {prettyCutOutputByCellWidth(correspondentAccount, 'phoneNumber')}
                </span>
              )}
            />
          )
          : (
            <span>
              {correspondentAccount?.length > 0 ? correspondentAccount : '-'}
            </span>
          )   

      ),
      maxWidth: '150px',
      label: 'Correspondent Account',
      reorder: true,
      omit: omitColumn(columnConfig, 'Correspondent Account'), 
    },
    {
      name: renderInputColumn('SWIFT/BIC', 'Filter by SWIFT/BIC', 'swiftCode'),
      cell: ({ swiftCode }) => (
        swiftCode?.length >= valueOutputCutLength.brand
          ? (
            <TooltipComponent 
              title={swiftCode}
                // eslint-disable-next-line react/no-children-prop
              children={(
                <span style={{ cursor: 'pointer' }}>
                  {prettyCutOutputByCellWidth(swiftCode, 'brand')}
                </span>
              )}
            />
          )
          : (
            <span>
              {swiftCode?.length > 0 ? swiftCode : '-'}
            </span>
          )   

      ),
      maxWidth: '130px',
      label: 'SWIFT/BIC',
      reorder: true,
      omit: omitColumn(columnConfig, 'SWIFT/BIC'), 
    },
    {
      name: renderInputColumn('INN (RU)', 'Filter by user bank inn', 'inn'),
      cell: ({ inn }) => (
        inn?.length >= valueOutputCutLength.profitLoss
          ? (
            <TooltipComponent 
              title={inn}
                // eslint-disable-next-line react/no-children-prop
              children={(
                <span style={{ cursor: 'pointer' }}>
                  {prettyCutOutputByCellWidth(inn, 'profitLoss')}
                </span>
              )}
            />
          )
          : (
            <span>
              {inn?.length > 0 ? inn : '-'}
            </span>
          )   

      ),
      maxWidth: '120px',
      label: 'INN (RU)',
      reorder: true,
      omit: omitColumn(columnConfig, 'INN (RU)'), 
    },
    {
      name: renderInputColumn('KPP (RU)', 'Filter by user bank kpp', 'kpp'),
      cell: ({ kpp }) => (
        kpp?.length >= valueOutputCutLength.profitLoss
          ? (
            <TooltipComponent 
              title={kpp}
                // eslint-disable-next-line react/no-children-prop
              children={(
                <span style={{ cursor: 'pointer' }}>
                  {prettyCutOutputByCellWidth(kpp, 'profitLoss')}
                </span>
              )}
            />
          )
          : (
            <span>
              {kpp?.length > 0 ? kpp : '-'}
            </span>
          )   

      ),
      maxWidth: '120px',
      label: 'KPP (RU)',
      reorder: true,
      omit: omitColumn(columnConfig, 'KPP (RU)'), 
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('included_country_first_name')}>
            <div className="newTablePositionHeader">
              <span>Included Countries</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('included_country_first_name')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters
            data={countries} 
            filters={includedCountriesOptions} 
            setFilters={setIncludedCountriesOptions} 
            tabName="Included Countries" 
            searchParams={{ id: '_id', params: ['nicename'], optionsName: ['nicename'] }}
            usersFilters={tempFilters}
            setUserFilters={setTempFilters}
            style={{ left: '5px' }}
          />
        </div>
      ),
      cell: ({ included_countries }) => {
        const includedCountriesNames = countriesOutput(countries, included_countries);

        if (includedCountriesNames?.length >= valueOutputCutLength.smtpEmail) {
          return (
            <TooltipComponent
              title={includedCountriesNames}
              // eslint-disable-next-line react/no-children-prop
              children={(
                <span style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}>
                  {prettyCutOutputByCellWidth(includedCountriesNames, 'smtpEmail')}
                </span>
            )}
            />
          );
        }
        return (
          <span style={{ whiteSpace: 'nowrap' }}>
            {includedCountriesNames?.length > 0 ? includedCountriesNames : '-'}
          </span>
        );
      },
      minWidth: '140px',
      label: 'Included countries',
      reorder: true,
      omit: omitColumn(columnConfig, 'Included countries'), 
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('excluded_country_first_name')}>
            <div className="newTablePositionHeader">
              <span>Excluded Countries</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('excluded_country_first_name')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters
            data={countries} 
            filters={excludedCountriesOptions} 
            setFilters={setExcludedCountriesOptions} 
            tabName="Excluded Countries" 
            searchParams={{ id: '_id', params: ['nicename'], optionsName: ['nicename'] }}
            usersFilters={tempFilters}
            setUserFilters={setTempFilters}
            style={{ left: '5px' }}
          />
        </div>
      ),
      cell: ({ excluded_countries }) => {
        const excludedCountriesNames = countriesOutput(countries, excluded_countries);

        if (excludedCountriesNames?.length >= valueOutputCutLength.smtpEmail) {
          return (
            <TooltipComponent
              title={excludedCountriesNames}
              // eslint-disable-next-line react/no-children-prop
              children={(
                <span style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}>
                  {prettyCutOutputByCellWidth(excludedCountriesNames, 'smtpEmail')}
                </span>
            )}
            />
          );
        }
        return (
          <span style={{ whiteSpace: 'nowrap' }}>
            {excludedCountriesNames?.length > 0 ? excludedCountriesNames : '-'}
          </span>
        );
      },
      minWidth: '140px',
      label: 'Excluded Countries',
      reorder: true,
      omit: omitColumn(columnConfig, 'Excluded Countries'), 
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('isEnabled')}>
            <div className="newTablePositionHeader" style={{ width: '100%' }}>
              <span>Status</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('isEnabled')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters
            data={isEnabledOptionData} 
            filters={status} 
            setFilters={setStatus} 
            tabName="Status" 
            searchParams={{ id: '_id', params: ['status'], optionsName: ['status'] }}
            usersFilters={tempFilters}
            setUserFilters={setTempFilters}
            style={{ left: '5px' }}
          />
        </div>
      ),
      cell: ({ isEnabled }) => (
        <span>
          {isEnabled ? 'Enabled' : 'Disabled'}
        </span>
      ),
      maxWidth: '126px',
      label: 'Status',
      reorder: true,
      omit: omitColumn(columnConfig, 'Status'), 
    },
    {
      name: renderInputColumn('Reference', 'Filter by user Reference', 'reference'),
      cell: ({ reference }) => (
        reference?.length >= valueOutputCutLength.profitLoss
          ? (
            <TooltipComponent 
              title={reference}
                // eslint-disable-next-line react/no-children-prop
              children={(
                <span style={{ cursor: 'pointer' }}>
                  {prettyCutOutputByCellWidth(reference, 'profitLoss')}
                </span>
              )}
            />
          )
          : (
            <span>
              {reference?.length > 0 ? reference : '-'}
            </span>
          )   

      ),
      maxWidth: '120px',
      label: 'Reference',
      reorder: true,
      omit: omitColumn(columnConfig, 'Reference'), 
    },
    {
      name: 'Actions',
      minWidth: '240px',
      style: {
        display: 'flex',
        justifyContent: 'flex-start !important',
      },
      cell: (row) => (
        isUserCanDeletePspOther || isUserCanEditPspOther ? (
          <div className="action-btn">
            {
              permissionName && permissionName.length > 0 && permissionName.includes('edit_admin_bank_account')
                ? (
                  <Switch 
                    checked={row.isEnabled}
                    color="success"
                    onChange={({ target: { checked } }) => {
                      handleStatusChange(row._id, checked);
                    }}
                  />
                )
                : null  
            }
            {
              permissionName && permissionName.length > 0 && permissionName.includes('edit_admin_bank_account')
                ? (
                  <button
                    type="button"
                    className="btn btn-success btn-sm me-1 p-1"
                    onClick={() => editAction(row._id, row.alias, row)}
                  >
                    <FontAwesomeIcon icon={faPencil} />
                  </button>
                )
                : null
            }
            {
              permissionName && permissionName.length > 0 && permissionName.includes('delete_admin_bank_account')
                ? (
                  <button
                    type="button"
                    className="btn btn-danger btn-sm me-1 p-1"
                    onClick={() => deleteAction(row._id)}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                )
                : null
            }
          </div>
        ) : '-'
      ),
      label: 'Actions',
      reorder: true,
      omit: omitColumn(columnConfig, 'Actions'), 
    },
  ];

  return sortColumnByOrderConfig(columns, columnConfig);
};
