import React, {
  useEffect, useState, useRef, useContext,
  useMemo, 
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { Dropdown } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import {
  faPlus, 
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RefreshIcon from '@mui/icons-material/Refresh';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { setNewOrderedColumns } from '../../DataTableSchemas/helper';

import {
  showAllUsers,
  deleteUser,
  editUser,
  deleteUsers,
  editUsers,
  getCountries,
  exportUsersToFile,
  updateExportDataState,
  updateUserState,
  moveUsersToManagers,
  prepareCSVFile,
  clearUsersState,
} from '../../redux/users/userActions';
import { getAffiliates } from '../../redux/affiliate/affiliateActions';
import { deleteAdminCommentsByUserIds } from '../../redux/adminComment/adminCommentActions';
import { getBrands } from '../../redux/brand/brandActions';
import { getSalesStatuses } from '../../redux/salesStatus/salesStatusActions';
import {
  getSubCRMUsers, createCRMFilter, deleteCRMFilter, updateCRMFilter, getDesksIbs,
} from '../../redux/crmUser/crmUserActions';
import { getCurrencyRates } from '../../redux/currencyRate/currencyRateActions';
import { getRole } from '../../redux/roles/roleActions';
import { UsersSchema } from '../../DataTableSchemas/UsersSchema';
import { 
  DatatableActions, 
  DatatableFilters, 
  DatatableColumns,
  CustomPaginationFooter,
  DownloadFile, 
} from '../../components';
import { leadsColumns } from '../../columnsDefaultConfig';
import { ModalContext } from '../../context';
import UserUploadModal from './UserUploadModal/UserUploadModal';
import { SET_UPLOADED_FILE_FIELDS } from '../../redux/users/userTypes';
import { AddCommentsModal } from '../../components/CalendarEventModals/AddCommentsModal';
import socket from '../../services/socket';
import { AddUserModal } from './AddUserModalComponent/AddUserModal';
import { generateSortParams } from '../../helpers/sortHelper';
import { multiDebounceCallback, useMultiDebounce } from '../../hooks/useMultiDebounce';
import { getFunnelOptions } from '../../redux/affiliatesDashboard/affiliatesDashboardActions';
import { selectAffiliatesDashboardFunnelNameFilterOptions } from '../../redux/affiliatesDashboard/affiliatesDashboardSelectors';
import { SALES_ROLES, SALES_STATUSES } from '../AffiliatesDashboard/utils/optionsHelper';

function Leads() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [, setRoleId] = useState('');
  const [, setUserId] = useState('');
  const [, setImgLoader] = useState(false);
  const [selectedUsersIds, setSelectedUsersIds] = useState([]);
  const [type] = useState('0');
  const [show, setShow] = useState(true);
  const [csvFile, setCsvFile] = useState('');
  const [csvFileExtension, setCsvFileExtension] = useState('');
  const [clientFullname, setClientFullname] = useState('');
  const [clientEmail, setClientEmail] = useState('');
  const [clientSecondEmail, setClientSecondEmail] = useState('');
  const [clientPhone, setClientPhone] = useState('');
  const [clientSecondPhone, setClientSecondPhone] = useState('');
  const [clientLanguage, setClientLanguage] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(1);
  const [isPaginationDT, setIsPaginationDT] = useState(false);
  const [isPermissionChecked, setIsPermissionChecked] = useState(false);
  const [commentsModalIsOpen, setCommentsModalIsOpen] = useState(false);
  const [rowDataForComments, setRowDataForComments] = useState(null);
  const [funnelNames, setFunnelNames] = useState([]);

  const users = useSelector((state) => state?.users?.users);
  const userIds = useMemo(() => users.map((user) => user._id), [users]);
  const allCountries = useSelector((state) => state?.users?.countries);
  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions);
  const success = useSelector((state) => state.users.success);
  const userDeleted = useSelector((state) => state?.users?.userDeleted);
  const userEditted = useSelector((state) => state?.users?.userEditted);
  const salesStatuses = useSelector((state) => state?.salesStatus?.salesStatuses);
  const crmUsers = useSelector((state) => state.crmUser?.crmUsers);
  const crmFilters = useSelector((state) => state.crmUser?.crmUserInfo?.filters);
  const allAffiliates = useSelector((state) => state.affiliate.affiliates);
  const usersCount = useSelector((state) => state.users.usersCount);
  const exportFilterdDataToFile = useSelector((state) => state.users.exportFilterdDataToFile);
  const uploadedFileFields = useSelector((state) => state.users.uploadedFileFields);
  const brands = useSelector((state) => state.brands);
  const currencyRates = useSelector((state) => state.currencyRates);
  const allIbDesks = useSelector((state) => state.crmUser?.deskIbs);
  const funnelNameFilterOptions = useSelector(selectAffiliatesDashboardFunnelNameFilterOptions);

  // Search Boxes
  const [ibdeskFilterPreset, setIbdeskFilterPreset] = useState([]);
  const [idFilter, setIdFilter] = useState('');
  const [additionalInfoFilter, setAdditionalInfoFilter] = useState('');
  const [lastCommentFilter, setLastCommentFilter] = useState('');
  const [managerFilterPreset, setManagerFilterPreset] = useState([]);
  const [countriesToShow, setCountriesToShow] = useState([]);
  const [isCheckCountry, setIsCheckCountry] = useState([]);
  const [isCheckStatus, setIsCheckStatus] = useState([]);
  const [assignToShow, setAssignToShow] = useState([]);
  const [affiliateFilter, setAffiliateFilter] = useState([]);
  const [isCheckAssignTo, setIsCheckAssignTo] = useState([]);
  const [timeInitiatedFilter, setTimeInitiatedFilter] = useState([]);
  const [lastCommentDate, setLastCommentDate] = useState([]);
  const [lastActionDate, setLastActionDate] = useState([]);
  const [firstTimeDepositFilter, setFirstTimeDepositFilter] = useState([]);
  const [isCheckFtdOwner, setIsCheckFtdOwner] = useState([]);
  const [isCheckStdOwner, setIsCheckStdOwner] = useState([]);
  const [isCheckPreviousOwner, setIsCheckPreviousOwner] = useState([]);
  const [isCheckOnlineStatuses, setIsCheckOnlineStatuses] = useState([]);
  const [lastPromotion, setLastPromotion] = useState([]);
  const [lastDemotion, setLastDemotion] = useState([]);
  const [lastAssignment, setLastAssignment] = useState([]);
  const [lastLoginAt, setLastLoginAt] = useState([]);
  const [lastOnline, setLastOnline] = useState([]);
  const [lastCallDate, setLastCallDate] = useState([]);
  const [totalCallsRange, setTotalCallsRange] = useState(null);
  const filtersStorageName = 'DataTable_users/leads_filters'; 
  const filterIdStorageName = 'DataTable_users/leads_filter_id'; 
  const columnsStorageName = 'DataTable_users/leads_columns';
  const paginationStorageName = 'DataTable_users/leads_pagination';
  const pageStorageName = 'DataTable_users/leads_page';
  const sortParamsStorageName = 'DataTable_users/leads_sortParams';
  const leadFiltersJSON = localStorage.getItem(filtersStorageName);
  const leadColumnsJSON = localStorage.getItem(columnsStorageName);
  const [leadFilters, setLeadFilters] = useState({});
  const [columnConfig, setColumnConfig] = useState([]);
  const [clearRows, setCrearRows] = useState(false);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [sortParams, setSortParams] = useState(JSON.stringify({ createdAt: -1 }));
  const [onlineUsers, setOnlineUsers] = useState({});

  const { showModal, hideModal } = useContext(ModalContext);
  const LEAD_TYPE = 1;

  const swappedSalesRoles = Object.fromEntries(
    Object.entries(SALES_ROLES).map(([key, value]) => [value, key]),
  );

  const filteredSalesStatuses = useMemo(
    () => salesStatuses.filter((status) => status.type === SALES_STATUSES[swappedSalesRoles[LEAD_TYPE]] || status.type === SALES_STATUSES.BOTH),
    [salesStatuses],
  );

  useEffect(() => {
    if (clearRows) {
      setCrearRows(false);
    }
  }, [clearRows]);

  const setDebounceTotalCallsRange = (type, value, amountRangeValue) => {
    if (amountRangeValue && amountRangeValue.length) {
      const [minValue, maxValue] = amountRangeValue;
      if (type === 'min') {
        setTotalCallsRange([Number(value), maxValue]);
      }
      if (type === 'max') {
        setTotalCallsRange([minValue, Number(value)]);
      }
      if (type === 'slider') {
        setTotalCallsRange(value);
      }
    }
  };

  const [initialLoadComplete, setInitialLoadComplete] = useState(false);

  const searchByName = ({ target: { value } }) => setClientFullname(value);
  const searchByEmail = ({ target: { value } }) => setClientEmail(value);
  const searchBySecondEmail = ({ target: { value } }) => setClientSecondEmail(value);
  const searchByPhone = ({ target: { value } }) => setClientPhone(value);
  const searchBySecondPhone = ({ target: { value } }) => setClientSecondPhone(value);
  const searchByClientLanguage = ({ target: { value } }) => setClientLanguage(value);
  const searchById = (id) => setIdFilter(id);
  const searchByAdditionalInfo = (info) => setAdditionalInfoFilter(info);
  const searchByLastComment = (comment) => setLastCommentFilter(comment);

  useMultiDebounce(
    [
      clientFullname,
      clientEmail,
      clientSecondEmail,
      clientPhone,
      clientSecondPhone,
      clientLanguage,
      idFilter,
      additionalInfoFilter,
      lastCommentFilter,
      totalCallsRange,
    ],
    1000,
    (inputValues) => {
      multiDebounceCallback(
        inputValues,
        [
          'fullName',
          'email',
          'secondEmail',
          'phone',
          'secondPhone',
          'language',
          'id',
          'additionalInfo',
          'lastComment',
          'totalCalls',
        ],
        leadFilters,
        setLeadFilters,
        undefined,
        initialLoadComplete,
        setInitialLoadComplete,
      );
    },
  );

  const handleUploadBtn = () => {
    showModal({
      headerContent: <h3>CONFIGURE FIELDS</h3>,
      bodyContent: (
        <UserUploadModal
          defaultRole="Lead"
          csvFile={csvFile}
          csvFileExtension={csvFileExtension}
          // eslint-disable-next-line no-use-before-define
          handlePageChange={handlePageChange}
          uploadedFileFields={uploadedFileFields}
          hideModal={hideModal}
        />
      ),
    });
  };

  useEffect(() => {
    setTimeout(() => {
      socket.emit('getOnlineCRMUsers');
    }, 1000);
    
    socket.on('recieveOnlineCRMUsers', ({ onlineUsers = {} }) => {
      if (Object.keys(onlineUsers).length) {
        const onlineUsersIds = Object.values(onlineUsers);
        let isNeedToChange = false;

        // eslint-disable-next-line no-restricted-syntax
        for (const userId of onlineUsersIds) {
          if (userIds.includes(userId)) {
            isNeedToChange = true;

            break;
          }
        };

        if (isNeedToChange) {
          setOnlineUsers(onlineUsers);
        }
      }
    });

    return () => {
      socket.off('recieveOnlineCRMUsers');
    };
  }, [socket, userIds]);

  useEffect(() => {
    if (uploadedFileFields.length > 0) {
      handleUploadBtn();
    } else {
      hideModal();
    }
  }, [uploadedFileFields]);

  useEffect(() => () => {
    dispatch({ type: SET_UPLOADED_FILE_FIELDS, payload: [] });
    dispatch(clearUsersState());
  }, []);

  const datatableFiltersRef = useRef(null);

  useEffect(() => {
    if (allCountries?.length) {
      setCountriesToShow(allCountries);
    }
  }, [allCountries]);

  const padTo2Digits = (num) => num.toString().padStart(2, '0');

  const formatDate = (date) => (
    `${[
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join('-')
    } ${
      [
        padTo2Digits(date.getHours()),
        padTo2Digits(date.getMinutes()),
        padTo2Digits(date.getSeconds()),
      ].join(':')}`
  );

  const isFiltersEmpty = (filters) => {
    const isEmpty = Object.keys(filters).every((key) => {
      if (filters[key].length) return false;

      return true;
    });

    return isEmpty;
  };

  const setStoredFilterData = async (id, uid, clientType, currencies) => {
    if (leadFiltersJSON && !isFiltersEmpty(JSON.parse(leadFiltersJSON))) {
      const filters = JSON.parse(leadFiltersJSON);
      setLeadFilters(filters);

      setIdFilter(filters.id || '');
      setAdditionalInfoFilter(filters.additionalInfo || '');
      setClientFullname(filters.fullName || '');
      setLastCommentFilter(filters.lastComment || '');
      setClientEmail(filters.email || '');
      setClientSecondEmail(filters.secondEmail || '');
      setClientPhone(filters.phone || '');
      setClientSecondPhone(filters.secondPhone || '');
      setClientLanguage(filters.language || '');
      setIsCheckCountry(filters.country || []);
      setFunnelNames(filters['funnel name'] || []);
      setIbdeskFilterPreset(filters.ibdesk || []);
      setManagerFilterPreset(filters.manager || []);
      setIsCheckStatus(filters.status || []);
      setIsCheckAssignTo(filters['assigned to'] || []);
      setAffiliateFilter(filters['affiliate name'] || []);
      setTimeInitiatedFilter(filters.created || []);
      setLastCommentDate(filters['last comment date'] || []);
      setFirstTimeDepositFilter(filters['first time deposit'] || []);
      setIsCheckFtdOwner(filters['ftd owner'] || []);
      setIsCheckStdOwner(filters['std owner'] || []);
      setIsCheckOnlineStatuses(filters['online status'] || []);
      setLastPromotion(filters['last promotion'] || []);
      setLastDemotion(filters['last demotion'] || []);
      setLastAssignment(filters['last assignment'] || []);
      setIsCheckPreviousOwner(filters['previous owner'] || []);
      setLastLoginAt(filters['last login date'] || []);
      setLastOnline(filters['last online'] || []);
      setLastCallDate(filters['last call'] || []);
      setTotalCallsRange(filters.totalCalls);
      setLastActionDate(filters['last action'] || []);
      setSelectedBrands(filters.brand || []);
    } else {
      await dispatch(showAllUsers(type, id, uid, clientType, '', page, rowsPerPage, {}, currencies, sortParams));
    }
  };

  const setStoredColumnsData = () => {
    if (leadColumnsJSON) {
      const columns = JSON.parse(leadColumnsJSON);

      setColumnConfig(columns);
    } else {
      localStorage.setItem(columnsStorageName, JSON.stringify(columnConfig));
    }
  };

  const setStoredPagination = () => {
    const leadRowsJSON = localStorage.getItem(paginationStorageName);
    if (leadRowsJSON) {
      const filterRows = JSON.parse(leadRowsJSON);
      setRowsPerPage(filterRows.limit || 25);
    }
    
    setIsPaginationDT(true);
  };

  async function fetchAllData() {
    const loginData = localStorage.getItem('user');
    const data = JSON.parse(loginData);
    const id = typeof data.roleId === 'string' ? data.roleId : data.roleId?._id;
    const uid = data?._id;
    const clientType = 1;

    Promise.all([
      dispatch(getDesksIbs()),
      setRoleId(id),
      dispatch(getRole(id)),
      setUserId(uid),
      dispatch(getCountries()),
      dispatch(getSalesStatuses()),
      dispatch(getSubCRMUsers(id, uid)),
      dispatch(getAffiliates()),
      dispatch(getBrands()),
    ]).then(() => {
      setStoredFilterData(id, uid, clientType, currencyRates);
    });
  }

  const refresh = async () => {
    const loginData = localStorage.getItem('user');
    const data = JSON.parse(loginData);
    const id = data?.roleId;
    const uid = data?._id;
    await dispatch(showAllUsers(type, id, uid, 1, '', page, rowsPerPage, leadFilters, currencyRates, sortParams));
  };

  const handleShowAddUserModal = () => {
    showModal({
      bodyContent: <AddUserModal userType={LEAD_TYPE} hideModal={hideModal} refresh={refresh} />,
      headerContent: <h5>Add User</h5>,
    });
  };

  const handleClear = () => {
    setSortParams(JSON.stringify({ createdAt: -1 }));
    setClientEmail('');
    setClientSecondEmail('');
    setAffiliateFilter([]);
    setClientFullname('');
    setLastCommentFilter('');
    setClientPhone('');
    setClientSecondPhone('');
    setClientLanguage('');
    setIsCheckAssignTo([]);
    setIbdeskFilterPreset([]);
    setManagerFilterPreset([]);
    setIdFilter('');
    setAdditionalInfoFilter('');
    setIsCheckCountry([]);
    setFunnelNames([]);
    setIsCheckStatus([]);
    setSelectedBrands([]);
    setLeadFilters({
      additionalInfo: '',
      email: '',
      fullName: '',
      id: '',
      language: '',
      lastComment: '',
      phone: '',
      secondEmail: '',
      secondPhone: '',
    });
    setTimeInitiatedFilter([]);
    setFirstTimeDepositFilter([]);
    setIsCheckFtdOwner([]);
    setIsCheckStdOwner([]);
    setIsCheckOnlineStatuses([]);
    setLastPromotion([]);
    setLastDemotion([]);
    setLastAssignment([]);
    setIsCheckPreviousOwner([]);
    setLastLoginAt([]);
    setLastOnline([]);
    setLastCallDate([]);
    setLastActionDate([]);
    setLastCommentDate([]);
    setTotalCallsRange();
    localStorage.removeItem(filtersStorageName);
    localStorage.removeItem(filterIdStorageName);
    localStorage.removeItem(sortParamsStorageName);
    localStorage.removeItem(pageStorageName);
    datatableFiltersRef.current.clearDrodownName();
  };

  useEffect(() => {
    Promise.all([
      dispatch(getCurrencyRates('USD')),
      dispatch(getFunnelOptions([], false)),
    ]);
  }, []);

  useEffect(() => {
    if (crmUsers?.length > 0) {
      setAssignToShow(crmUsers);
    }
  }, [crmUsers]);

  useEffect(() => {
    if (!isPermissionChecked && permissionName && permissionName.length) {
      setColumnConfig(leadsColumns(permissionName));
      setIsPermissionChecked(true);

      if (permissionName.includes('clients')) {
        setStoredPagination();
      }
    }
  }, [permissionName]);

  useEffect(() => {
    if (isPaginationDT) {
      fetchAllData();
      setStoredColumnsData();
    }
  }, [isPaginationDT]);

  const getUsers = (page, rowsPerPage, filters, sort) => {
    const loginData = localStorage.getItem('user');
    const data = JSON.parse(loginData);
    const id = data?.roleId;
    const uid = data?._id;

    return dispatch(showAllUsers(type, id, uid, 1, '', page, rowsPerPage, filters, currencyRates, sort));
  };

  const handleRowsPerPageChange = async (currentRowsPerPage, page) => {
    localStorage.setItem(paginationStorageName, JSON.stringify({ limit: currentRowsPerPage }));
    setRowsPerPage(currentRowsPerPage);
    await getUsers(page, currentRowsPerPage, leadFilters, sortParams);
  };

  const handlePageChange = async (page) => {
    setPage(page);
    localStorage.setItem(pageStorageName, JSON.stringify(page));
    await getUsers(page, rowsPerPage, leadFilters, sortParams);
  };

  useEffect(() => {
    if (type === '0') setShow(true);
    else setShow(false);
    if (success) {
      dispatch(updateUserState());
    }
  }, [type, success, userDeleted, userEditted]);

  const userAction = (id, actionType) => {
    Swal.fire({
      title: `Are you sure you want to ${actionType && actionType === 'block' ? 'block' : 'unblock'}?`,
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: `${actionType && actionType === 'block' ? 'Block' : 'Unblock'}`,
    }).then((result) => {
      if (result.isConfirmed) {
        const data = { status: !(actionType && actionType === 'block') };
        dispatch(editUser(id, data));
      }
    });
  };

  const deleteAction = (id) => {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed === true) {
        const userIdData = localStorage.getItem('userId');
        const loginUserId = JSON.parse(userIdData);

        const data = {
          crmUserId: loginUserId,
        };

        dispatch(deleteUser(id, data));
      }
    });
  };

  const fireSwal = (title) => Swal.fire({
    title,
    html: '',
    showCloseButton: true,
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes',
  });

  const toastError = (title) => {
    toast.error(title, {
      autoClose: 1000,
    });
  };

  const deleteUsersInBulk = () => {
    const promise = new Promise((res) => {
      if (selectedUsersIds.length) {
        fireSwal('Are you sure you want to delete user(s)?')
          .then(async (result) => {
            if (result.isConfirmed) {
              const data = {
                ids: selectedUsersIds,
              };

              setSelectedUsersIds([]);
              setCrearRows(true);
              const deletedUserResult = await dispatch(deleteUsers(data));
              showModal({
                headerContent: <h3>Resut</h3>,
                bodyContent: (deletedUserResult.map((result) => (
                  <div key={result.id} style={{ color: result.success ? 'green' : 'red' }}>{result.message}</div>
                ))),
              });
              await refresh();

              res(true);
            }
          });
      } else {
        toastError('Select atleast one user to complete this action.');
      }
    });

    return promise;
  };

  const deleteCommentsInBulk = () => {
    const promise = new Promise((res) => {
      if (selectedUsersIds.length) {
        fireSwal('Are you sure you want to delete all comments for this user(s)?')
          .then(async (result) => {
            if (result.isConfirmed) {
              setCrearRows(true);
              setSelectedUsersIds([]);
              await dispatch(deleteAdminCommentsByUserIds(selectedUsersIds));
              await refresh();

              res(true);
            }
          });
      } else {
        toastError('Select atleast one user to complete this action.');
      }
    });

    return promise;
  };

  const moveUsersToManagersInBulk = (managerIds) => {
    const promise = new Promise((res) => {
      if (selectedUsersIds.length) {
        fireSwal('Are you sure you want to move all this user(s) to selected manager(s)?')
          .then(async (result) => {
            if (result.isConfirmed) {
              const data = {
                userIds: selectedUsersIds,
                managerIds,
              };
  
              setCrearRows(true);
              setSelectedUsersIds([]);
              await dispatch(moveUsersToManagers(data));
              await refresh();

              res(true);
            }
          });
      } else {
        toastError('Select atleast one user to complete this action.');
      }
    });

    return promise;
  };

  const editUsersInBulk = (data, swalTitle) => {
    const promise = new Promise((res) => {
      if (selectedUsersIds.length) {
        fireSwal(swalTitle)
          .then(async (result) => {
            if (result.isConfirmed) {
              setCrearRows(true);
              setSelectedUsersIds([]);
              await dispatch(editUsers(data));
              await refresh();

              res(true);
            }
          });
      } else {
        toastError('Select atleast one user to complete this action.');
      }
    });

    return promise;
  };

  const createUserCRMFilter = async (name) => {
    const storageFilters = localStorage.getItem(filtersStorageName);
    const storageUserId = localStorage.getItem('userId');
    const crmUserId = JSON.parse(storageUserId);
    const filters = JSON.parse(storageFilters);
    
    if (!filters || !Object.keys(filters).length) {
      toastError('Select atleast one filter to complete this action.');

      return;
    }

    const data = {
      customId: filters.id,
      name,
      crmUserId,
      pathname,
      additionalInfo: filters.additionalInfo || '',
      fullName: filters.fullName || '',
      affiliate: filters['affiliate name'] || [],
      email: filters.email || '',
      secondEmail: filters.secondEmail || '',
      phone: filters.phone || '',
      country: filters.country || [],
      status: filters.status || [],
      assignedTo: filters['assigned to'] || [],
      timeOptionIds: filters.created || [],
      totalCalls: filters?.totalCalls?.length ? filters.totalCalls : [0, 10000],
      brand: filters.brand || [],
      lastComment: filters.lastComment || [],
      lastCommentAt: filters['last comment date'] || [],
      ibDeskId: filters.ibdesk || [],
      managerId: filters.manager || [],
      firstTimeDeposit: filters['first time deposit'] || [],
      ftdOwner: filters['ftd owner'] || [],
      stdOwner: filters['std owner'] || [],
      userOnlineStatus: filters['online status'] || [],
      lastDemotionDate: filters['last demotion'] || [],
      lastPromotionDate: filters['last promotion'] || [],
      lastAssignmentDate: filters['last assignment'] || [],
      prevAssignedToData: filters['previous owner'] || [],
      lastLoginDate: filters['last login date'] || [],
      lastCallDate: filters['last call'] || [],
      lastActionDate: filters['last action'] || [],
      funnelNames: filters['funnel name'] || [],
      lastOnline: filters['last online'] || [],
    };

    const res = await dispatch(createCRMFilter(data));

    if (res && res.data && res.data.filter) {
      localStorage.setItem(filterIdStorageName, JSON.stringify(res.data.filter._id));
      datatableFiltersRef.current.handleAfterCreate();
    }
  };

  const deleteUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);

      await dispatch(deleteCRMFilter(id));
      handleClear();
    } else {
      toastError('Select atleast one filter to complete this action.');
    }
  };

  const updateUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);
      const storageFilters = localStorage.getItem(filtersStorageName);
      const filters = JSON.parse(storageFilters);
      const data = {
        customId: filters.id || '',
        additionalInfo: filters.additionalInfo || '',
        fullName: filters.fullName || '',
        affiliate: filters['affiliate name'] || [],
        email: filters.email || '',
        secondEmail: filters.secondEmail || '',
        phone: filters.phone || '',
        secondPhone: filters.secondPhone || '',
        country: filters.country || [],
        status: filters.status || [],
        assignedTo: filters['assigned to'] || [],
        timeOptionIds: filters.created || [],
        brand: filters.brand || [],
        totalCalls: filters?.totalCalls?.length ? filters.totalCalls : [0, 10000],
        lastComment: filters.lastComment || [],
        lastCommentAt: filters['last comment date'] || [],
        ibDeskId: filters.ibdesk || [],
        managerId: filters.manager || [],
        firstTimeDeposit: filters['first time deposit'] || [],
        ftdOwner: filters['ftd owner'] || [],
        stdOwner: filters['std owner'] || [],
        userOnlineStatus: filters['online status'] || [],
        lastDemotionDate: filters['last demotion'] || [],
        lastPromotionDate: filters['last promotion'] || [],
        lastAssignmentDate: filters['last assignment'] || [],
        prevAssignedToData: filters['previous owner'] || [],
        lastLoginDate: filters['last login date'] || [],
        lastCallDate: filters['last call'] || [],
        lastActionDate: filters['last action'] || [],
        funnelNames: filters['funnel name'] || [],
        lastOnline: filters['last online'] || [],
      };

      dispatch(updateCRMFilter(id, data));
    } else {
      toastError('Select atleast one filter to complete this action.');
    }
  };

  // const loginAsUser = (user) => {
  //    if (user?._id) {
  //       const data = { email: user?.email, userByAdmin: true }
  //       dispatch(userDirectLogin(data))
  //    }
  // }

  const handleUserSelectedChange = async ({ selectedRows }) => {
    const ids = await selectedRows.map((row) => row._id);
    setSelectedUsersIds(ids);
  };

  useEffect(async () => {
    if (leadFilters && Object.keys(leadFilters).length) {
      localStorage.setItem(filtersStorageName, JSON.stringify(leadFilters));
      setPage(1);
      localStorage.setItem(pageStorageName, JSON.stringify(1));
      await getUsers(1, rowsPerPage, leadFilters, sortParams);
    }
  }, [leadFilters]);

  const setCRMFilters = (filter) => {
    const {
      customId, fullName, email, secondEmail, phone, secondPhone, language,
      country, status, assignedTo, timeOptionIds, affiliate, brand,
      lastComment, lastCommentDate, firstTimeDeposit, lastDemotionDate, lastPromotionDate,
      lastAssignmentDate, lastLoginDate, lastCallDate, lastActionDate,
      ftdOwner, stdOwner, prevAssignedToData, userOnlineStatus,
      totalCalls, ibDeskId, managerId, additionalInfo, funnelNames, lastOnline,
    } = filter;

    setClientEmail(email);
    setClientSecondEmail(secondEmail);
    setAffiliateFilter(affiliate);
    setClientFullname(fullName);
    setLastCommentFilter(lastComment);
    setClientPhone(phone);
    setClientSecondPhone(secondPhone);
    setClientLanguage(language);
    setIsCheckAssignTo(assignedTo);
    setIsCheckCountry(country);
    setFunnelNames(funnelNames);
    setIbdeskFilterPreset(ibDeskId);
    setManagerFilterPreset(managerId);
    setIdFilter(customId);
    setAdditionalInfoFilter(additionalInfo);
    setIsCheckStatus(status);
    setSelectedBrands(brand);
    setTotalCallsRange(totalCalls);
    setIsCheckPreviousOwner(prevAssignedToData || []);
    setLastOnline(lastOnline || []);
    setLeadFilters({
      id: customId,
      additionalInfo,
      fullName,
      email,
      phone,
      country,
      status,
      brand,
      totalCalls,
      ibdesk: ibDeskId,
      manager: managerId,
      created: timeOptionIds,
      'assigned to': assignedTo,
      'affiliate name': affiliate,
      lastComment,
      'last comment date': lastCommentDate,
      'first time deposit': firstTimeDeposit,
      'online status': userOnlineStatus,
      'last demotion': lastDemotionDate,
      'last promotion': lastPromotionDate,
      'last assignment': lastAssignmentDate,
      'last login date': lastLoginDate,
      'last call': lastCallDate,
      'last action': lastActionDate,
      'ftd owner': ftdOwner,
      'std owner': stdOwner,
      'previous owner': prevAssignedToData,
      'funnel name': funnelNames,
      'last online': lastOnline,
    });
    localStorage.setItem(filterIdStorageName, JSON.stringify(filter._id));
  };

  const handleStatusChange2 = (_id, e) => {
    if (_id) {
      const newUsers = [...users];
      const objIndex = newUsers.findIndex(((obj) => obj._id === _id));
      newUsers[objIndex].users.salesStatusId = e.target.value;
      // setUsers([...newUsers]);
      const data = { salesStatusId: e.target.value ? e.target.value : null };
      dispatch(editUser(_id, data, false));
    }
  };

  const handleCSVChange = (e, fileType) => {
    if (!e.target.files.length) return;

    const file = e.target.files[0];
    let typeCheck = ['csv'];

    if (fileType !== 'csv') {
      typeCheck = ['xlsx'];
    }

    if (file) {
      const reader = new window.FileReader();
      reader.readAsDataURL(file);
      setImgLoader(true);
      reader.onloadend = () => {
        const fileExtension = file.name
          .split('.')
          .pop()
          .toLowerCase();

        if (
          typeCheck.indexOf(
            fileExtension,
          ) > -1
        ) {
          setCsvFileExtension(fileExtension);
          setCsvFile(file);
        } else {
          setImgLoader(false);
          Swal.fire({
            text: `The file must be a file of type: ${fileType}`,
            icon: 'error',
            customClass: 'custom-alt',
          });
        }
      };
    }

    e.target.value = '';
  };

  useEffect(() => {
    const fetchData = async () => {
      const formData = new FormData();

      if (csvFile) {
        formData.append('csvFile', csvFile);
        formData.append('csvFileExtension', csvFileExtension);
        
        await dispatch(prepareCSVFile(formData));
      }
      setImgLoader(false);
    };
    fetchData();
  }, [csvFile, csvFileExtension]);

  const handleExportUser = async (fileType) => {
    const columns = columnConfig.filter(({ selected, field }) => selected && field);
    if (users.length && columns.length) {
      const { roleId, _id: userId } = JSON.parse(localStorage.getItem('user'));

      const data = {
        userType: type,
        roleId,
        userId,
        clientType: 1,
        affiliateId: '',
        page, 
        perPage: usersCount, 
        filters: leadFilters, 
        currencies: currencyRates,
        fileType,
        columns,
        selectedUsersIds,
        clientTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };

      toast.success('Export in progress. Please wait.', {
        autoClose: 1000,
      });
      dispatch(exportUsersToFile(data));
    } else {
      toastError('There is nothing to download.');
    }
  };

  useEffect(() => {
    if (exportFilterdDataToFile) {
      dispatch(updateExportDataState()); 
    }
  }, [exportFilterdDataToFile]);

  const handleParentDivClick = (e) => {
    const parentHasClass = e?.target?.closest('.dropdown-check-nostyle') !== null;
    if (!parentHasClass) {
      setCountriesToShow(allCountries);
      // setStatusesToShow(userTypeStatus);
      setAssignToShow(crmUsers);
    }
  };

  const addCommentHandler = (row) => {
    setRowDataForComments(row);
    setCommentsModalIsOpen(true);
  };

  const handleSort = async (sortField, sortDirection) => {
    const sort = generateSortParams(sortField, sortDirection);
    setSortParams(sort);
    localStorage.setItem(sortParamsStorageName, JSON.stringify(sort));

    if (page === 1) {
      await getUsers(page, rowsPerPage, leadFilters, sort);
    } else {
      setPage(1);
      localStorage.setItem(pageStorageName, JSON.stringify(1));
    }
  };

  const storeColumnConfig = (config) => {
    setColumnConfig(config);
    localStorage.setItem(columnsStorageName, JSON.stringify(config));
  };

  const columns = UsersSchema(
    LEAD_TYPE,
    permissionName,
    formatDate,
    allAffiliates,
    affiliateFilter, 
    setAffiliateFilter,
    salesStatuses,
    countriesToShow,
    userAction,
    isCheckStatus,
    setIsCheckStatus,
    handleStatusChange2,
    assignToShow,
    isCheckAssignTo,
    deleteAction,
    clientFullname,
    searchByName,
    clientEmail,
    clientSecondEmail,
    searchByEmail,
    searchBySecondEmail,
    clientPhone,
    clientSecondPhone,
    searchByPhone,
    searchBySecondPhone,
    clientLanguage,
    searchByClientLanguage,
    isCheckCountry, 
    setIsCheckCountry,
    setIsCheckAssignTo,
    columnConfig,
    leadFilters,
    setLeadFilters,
    setTimeInitiatedFilter,
    timeInitiatedFilter,
    lastCommentDate, 
    setLastCommentDate,
    lastActionDate,
    setLastActionDate,
    firstTimeDepositFilter,
    setFirstTimeDepositFilter,
    isCheckFtdOwner,
    setIsCheckFtdOwner,
    isCheckStdOwner,
    setIsCheckStdOwner,
    lastPromotion,
    setLastPromotion,
    lastDemotion,
    setLastDemotion,
    lastAssignment,
    setLastAssignment,
    isCheckPreviousOwner,
    setIsCheckPreviousOwner,
    lastLoginAt,
    setLastLoginAt,
    lastOnline,
    setLastOnline,
    lastCallDate,
    isCheckOnlineStatuses,
    setIsCheckOnlineStatuses,
    setLastCallDate,
    brands,
    selectedBrands,
    setSelectedBrands,
    currencyRates,
    addCommentHandler,
    handleSort,
    onlineUsers,
    refresh,
    totalCallsRange,
    setTotalCallsRange,
    setDebounceTotalCallsRange,
    allIbDesks,
    ibdeskFilterPreset,
    setIbdeskFilterPreset,
    managerFilterPreset,
    setManagerFilterPreset,
    idFilter,
    searchById,
    additionalInfoFilter,
    searchByAdditionalInfo,
    lastCommentFilter,
    searchByLastComment,
    funnelNameFilterOptions,
    funnelNames,
    setFunnelNames,
  );

  return (
    <div className="content-wrapper right-content-wrapper" onClick={(e) => handleParentDivClick(e)}>
      <div className="content-box">
        <h3>Leads</h3>
        <div className="action__btn-row">
          <div className="main_btn-row">
            <div className="secondary_btn-row">
              {show && permissionName && permissionName.length > 0 && permissionName.includes('add_user')
                ? (
                  <button
                    type="button"
                    className="btn-primary_light"
                    onClick={handleShowAddUserModal}
                  >
                    <FontAwesomeIcon
                      icon={faPlus}
                      size="xs"
                    />
                    Add New
                  </button>
                )
                : null}
              {show && permissionName && permissionName.length > 0 && permissionName.includes('add_user_with_csv_file')
                ? (
                  <Dropdown className="leads-download-dropdown">
                    <Dropdown.Toggle
                      variant=""
                      className="btn-primary_light iconed" 
                      id="dropdown-basic"
                    >
                      <FileUploadOutlinedIcon sx={{ fontSize: '20px' }} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="primary-menu">
                      <div>
                        <label htmlFor="file-import-xlsx" className="menu-text">Excel</label>
                        <input style={{ display: 'none' }} id="file-import-xlsx" type="file" name="csvFile" accept=".xlsx" onChange={(e) => handleCSVChange(e, 'xlsx')} />
                      </div>
                      <div>
                        <label htmlFor="file-import-csv" className="menu-text">CSV</label>
                        <input style={{ display: 'none' }} id="file-import-csv" type="file" name="csvFile" accept=".csv" onChange={(e) => handleCSVChange(e, 'csv')} />
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                )
                : null}
            </div>
            <div className="secondary_btn-row">
              {
                  show && crmFilters ? (
                    <DatatableFilters 
                      ref={datatableFiltersRef}
                      filters={crmFilters} 
                      setFilters={setCRMFilters}
                      createFilter={createUserCRMFilter}
                      deleteFilter={deleteUserCRMFilter}
                      updateFilter={updateUserCRMFilter}
                      storageKey={filterIdStorageName}
                      pathname={pathname}
                    /> 
                  ) : null
                }
              {
                  show && (
                    <button
                      type="button"
                      className="btn-secondary_dark iconed"
                      onClick={handleClear}
                    >
                      <CloseOutlinedIcon sx={{ fontSize: '20px' }} />
                    </button>
                  )
              }
              {
                  show && (
                    <button 
                      type="button"
                      className="btn-secondary_dark iconed" 
                      onClick={refresh}
                    >
                      <RefreshIcon sx={{ fontSize: '20px' }} />
                    </button>
                  )
              }
            </div>
            <div className="secondary_btn-row">
              {
                show ? (
                  <DatatableColumns 
                    setColumns={storeColumnConfig} 
                    columnConfig={columnConfig} 
                    defaultConfig={leadsColumns(permissionName)}
                  />
                ) : null
              }
            </div>
          </div>
          {
              show && permissionName && permissionName.length > 0 && permissionName.includes('export_leads_to_file')
                ? (
                  <DownloadFile handleExport={handleExportUser} />
                )
                : null
              }
          {
                  (
                    show 
                    && permissionName 
                    && permissionName.length > 0 
                    && permissionName.includes('edit_user') 
                    && selectedUsersIds.length > 0
                  ) ? (
                    <DatatableActions 
                      ids={selectedUsersIds} 
                      managers={crmUsers} 
                      statuses={filteredSalesStatuses} 
                      countries={allCountries}
                      brands={brands}
                      deleteUsers={deleteUsersInBulk}
                      deleteComments={deleteCommentsInBulk}
                      moveUsersToManagers={moveUsersToManagersInBulk}
                      editUsers={editUsersInBulk}
                      clientType="1"
                    />
                    ) : null
                }
        </div>
        <br />
        <div className="dashboard-tbl-wrapper custom-tbl-wrapper">
          {
                isPaginationDT
                  && (
                    <DataTable
                      rows={users?._id}
                      columns={columns}
                      data={users}
                      pagination
                      paginationServer
                      highlightOnHover
                      paginationResetDefaultPage={false} // optionally, a hook to reset pagination to page 1
                      paginationTotalRows={usersCount}
                      paginationPerPage={rowsPerPage}
                      paginationRowsPerPageOptions={[25, 50, 200, 500]}
                      onChangeRowsPerPage={handleRowsPerPageChange}
                      onChangePage={handlePageChange}
                      onColumnOrderChange={(newColumns) => setNewOrderedColumns(
                        newColumns,
                        columnConfig,
                        storeColumnConfig,
                      )}
                      paginationDefaultPage={page}
                      persistTableHead
                      selectableRows
                      onSelectedRowsChange={handleUserSelectedChange}
                      clearSelectedRows={clearRows}
                      selectableRowSelected={users?._id}
                      selectableRowsHighlight
                      selectableRowsVisibleOnly
                      theme="solarizedd"
                      progressComponent={<div className="datatable-loader__background" />}
                      // eslint-disable-next-line react/no-unstable-nested-components
                      paginationComponent={() => (
                        <CustomPaginationFooter
                          currentPage={page}
                          totalCount={usersCount}
                          onPageChange={handlePageChange}
                          rowsPerPage={rowsPerPage}
                          onRowsPerPageChange={handleRowsPerPageChange}
                          paginationRowsPerPageOptions={[25, 50, 200, 500]}
                        />
                      )}
                    />
                  )
                }
        </div>
      </div>
      {commentsModalIsOpen && <AddCommentsModal showModal={commentsModalIsOpen} setShowModal={setCommentsModalIsOpen} row={rowDataForComments} refresh={refresh} />}
    </div>
  );
}

export default Leads;
