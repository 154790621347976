import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import React from 'react';

function UserInfo({
  user, onlineUserIds, assignToModalUser, prevUser, nextUser,
}) {
  const { currentUserPermissions } = useSelector((state) => state.crmUser);
  const isUserCanPromoteClient = currentUserPermissions && currentUserPermissions.length > 0 && currentUserPermissions.includes('client_promote');
  const isUserCanDemoteClient = currentUserPermissions && currentUserPermissions.length > 0 && currentUserPermissions.includes('client_demote');
  
  const formatDate = (date) => {
    const padTo2Digits = (num) => num.toString().padStart(2, '0');
    return date ? `${date.getFullYear()}-${padTo2Digits(date.getMonth() + 1)}-${padTo2Digits(date.getDate())} ${padTo2Digits(date.getHours())}:${padTo2Digits(date.getMinutes())}:${padTo2Digits(date.getSeconds())}` : '';
  };

  return (
    <div className="crmuser-slider-block">
      <h5 style={{ color: onlineUserIds.includes(user._id) ? 'green' : 'white' }}>
        {user.firstName && user.lastName ? ` ${user.firstName} ${user.lastName} #${user.customId}` : ' Loading...'}
      </h5>
      {user.countryCode && (
        <div style={{
          display: 'flex', alignItems: 'center', color: '#fff', height: 'fit-content', gap: '5px',
        }}
        >
          <span>{formatDate(new Date())}</span>
          <span style={{ fontWeight: '800', fontSize: '17px', color: user.balanceInUSD > 0 ? 'green' : 'white' }}>
            {` ${user.balanceInUSD} USD`}
          </span>
          <span>{` (CREDIT: ${user.creditInUSD} USD)`}</span>
        </div>
      )}
      <div style={{ display: 'flex', gap: '5px' }}>
        {(user.clientType === 2 && isUserCanDemoteClient) && (
        <div className="Domote-botton" onClick={assignToModalUser}>
          Demote to Lead
        </div>
        )}
        {(user.clientType === 1 && isUserCanPromoteClient) && (
        <div className="Promote-botton" onClick={assignToModalUser}>
          Promote to Client
        </div>
        )}
        <Link to={prevUser ? `/user-detail/${prevUser._id}` : null}>Previous</Link>
        <Link to={nextUser ? `/user-detail/${nextUser._id}` : null}>Next</Link>
      </div>
    </div>
  );
}

export default UserInfo;
