/* eslint-disable react/no-danger */
import React from 'react';
import logoImage from '../../../../../assets/images/emailTemplates/logoPlaceholder.png';
import styles from './PreviewComponent.module.css';

function PreviewComponent({ HTMLWithProperClass }) {
  return (
    <div className={styles.previewWrapper}>
      <div className="col-xl-12 col-lg-12">
        <table border="0" cellPadding="0" cellSpacing="0" width="100%">
          <tr>
            <td style={{ backgroundColor: '#F1F0EE' }}>
              <table align="center" border="0" cellPadding="0" cellSpacing="0" width="640" style={{ borderCollapse: 'collapse', backgroundColor: '#F2F2F2' }}>
                <tr>
                  <td className={styles.header}>
                    <a
                      href="%siteURL%"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={logoImage}
                        alt="logo"
                        className={styles.companyBigLogo}
                      />
                    </a>
                    <div className={styles.avatar}><span>A</span></div>
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: '32px', backgroundColor: '#F2F2F2' }}>
                    <div dangerouslySetInnerHTML={{ __html: HTMLWithProperClass }} />
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: '32px 32px 24px 32px', backgroundColor: '#F2F2F2' }}>
                    <table border="0" cellPadding="0" cellSpacing="0" width="100%" style={{ borderCollapse: 'collapse' }}>
                      <tr>
                        <td style={{ backgroundColor: '#E6E9F5', padding: '24px 24px 8px 24px', borderRadius: '10px' }}>
                          <div className={styles.securityTipTitle}>
                            <span>Security Tips</span>
                          </div>
                          <ul className={styles.securityTipsList}>
                            <li>Never give your password to anyone</li>
                            <li>Enable Google Two-Factor</li>
                            <li>Set up your anti-phishing code to add an extra layer of security to your account</li>
                          </ul>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td>
                    <hr style={{
                      width: '576px', border: 'none', borderTop: '1px #BDBBD0 solid', height: '1px', 
                    }}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: '21px 32px 27px 32px', backgroundColor: '#F1F0EE' }}>
                    <table border="0" cellPadding="0" cellSpacing="0" width="100%" style={{ borderCollapse: 'collapse' }}>
                      <tr>
                        <td align="center" style={{ padding: '8px 0 0 0' }}>
                          <div className={styles.footerContentWrapper}>
                            <p style={{
                              color: '#6F6F80', fontSize: '12px', fontFamily: 'Arial, sans-serif', margin: 0, textAlign: 'left', 
                            }}
                            >
                              © 
                              {' '}
                              {(new Date()).getFullYear()}
                              {' '}
                              %companyName%. Brand are registered trademarks
                            </p>
                            <img className={styles.companySmallLogo} src={logoImage} alt="$" />
                          </div>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </div>
    </div>
  );
}

export default PreviewComponent;
