import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { FormControlWithRadioButtons } from '../../SalesDashboard/components/FormControl';
import { NoDataAvailableTableIcon } from '../components/NoDataAvailableTable';
import style from './StatusFTD.module.css';
import {
  selectAffiliatesDashboardColorDataByStatus, selectAffiliatesDashboardDataByStatus, selectIsStatusLoading, selectStatusOption, 
} from '../../../redux/affiliatesDashboard/affiliatesDashboardSelectors';
import { setStatusOption } from '../../../redux/affiliatesDashboard/affiliatesDashboardActions';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { DoughnutChart } from '../Charts/DoughnutChart/DoughnutChart';
import CircleLoader from '../../../components/CircleLoader/CircleLoader';

function StatusFTD({ downloadDepositsByStatusHandler }) {
  const dispatch = useDispatch();
  const [screenWidth, screenHeight] = useWindowSize();

  const donutData = useSelector(selectAffiliatesDashboardDataByStatus);
  const colorData = useSelector(selectAffiliatesDashboardColorDataByStatus);
  const selectedOption = useSelector(selectStatusOption);
  const isStatusLoading = useSelector(selectIsStatusLoading);
  const isMobile = screenWidth <= 499;

  const options = [
    { ticker: 'All Statuses' },
    { ticker: 'FTD' },
  ];

  const changeStatusOption = (option) => {
    dispatch(setStatusOption(option));
  };

  return (
    <div className={style.tableWrapper}>
      <div className={style.allDepositsTitleContainer}>
        <h3 className={style.allDepositsTitle}>Leads/clients status</h3>
        {/* <button type="button" onClick={downloadDepositsByStatusHandler} className={style.allDepositsAnalysisBtn}>
          <FileDownloadOutlinedIcon sx={{ color: '#C4C6D0' }} />
        </button> */}
      </div>
      <div className={style.formControlWrapper}>
        <FormControlWithRadioButtons options={options} selectedOptions={selectedOption} setSelectedOptions={changeStatusOption} />
        {
          isStatusLoading ? (
            <div style={{
              display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', 
            }}
            >
              <CircleLoader width={50} height={50} color="#9bbdff" radius={25} thickness={5} />
            </div>
          ) : (donutData?.length > 1) 
            ? colorData && <DoughnutChart data={donutData} colors={colorData} isMobile={isMobile} />
            : ( 
              <div className={style.noDataWrapper}>
                <NoDataAvailableTableIcon />
                <p className={style.noDataWrapperTitle}>No data available</p>
              </div>
            )
        } 
      </div>
    </div>
  );
}

export default StatusFTD;
