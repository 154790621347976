export const SET_ANALYSIS = 'SET_ANALYSIS';
export const SET_FIRST_TIME_DEPOSITS_DATA = 'SET_FIRST_TIME_DEPOSITS_DATA';
export const SET_FIRST_TIME_DEPOSITS_TOTAL = 'SET_FIRST_TIME_DEPOSITS_TOTAL';
export const SET_FUNNEL_NAME_DATA = 'SET_FUNNEL_NAME_DATA';
export const SET_FUNNEL_NAME_TOTAL = 'SET_FUNNEL_NAME_TOTAL';
export const SET_COUNTRY_MAP_DATA = 'SET_COUNTRY_MAP_DATA';
export const SET_TOTAL_FTD_BY_COUNTRY = 'SET_TOTAL_FTD_BY_COUNTRY';
export const SET_STATISTIC_BY_COUNTRY = 'SET_STATISTIC_BY_COUNTRY';
export const SET_IS_STATUS_LOADING = 'SET_IS_STATUS_LOADING';
export const SET_DATA_BY_STATUS = 'SET_DATA_BY_STATUS';
export const SET_COLORS_DATA_BY_STATUS = 'SET_COLORS_DATA_BY_STATUS';
export const SET_AFFILIATE_FILTER_OPTIONS = 'SET_AFFILIATE_FILTER_OPTIONS';
export const SET_FUNNEL_NAME_FILTER_OPTIONS = 'SET_FUNNEL_NAME_FILTER_OPTIONS';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_STATUS_OPTION = 'SET_STATUS_OPTION';
