import React, {
  useState, useEffect, useMemo, useCallback,
  useContext,
} from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactFlagsSelect from 'react-flags-select';
import {
  faTrash, faEdit, faThumbTack,
} from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import Markdown from 'markdown-to-jsx';
import { getRole } from '../../../redux/roles/roleActions';
import { editUser, userDirectLogin, updateClientPassword } from '../../../redux/users/userActions';
import { getSubCRMUsers } from '../../../redux/crmUser/crmUserActions';
import {
  getAdminComments, addAdminComment, editAdminComment, deleteAdminComment, deleteAdminComments, pinComment,
} from '../../../redux/adminComment/adminCommentActions'; //
import { getSalesStatuses } from '../../../redux/salesStatus/salesStatusActions';
import { getBrands } from '../../../redux/brand/brandActions';
import { addChat } from '../../../redux/chat/chatActions';
import { UserEvents } from './UserEvents/UserEvents';
import { apiHelper } from '../../../redux/apiHelper';
import { fetchCounties } from '../../../redux/countries/countriesActions';
import { selectAllCountries } from '../../../redux/countries/countriesSelectors';
import { UserStatusHistory } from './UserStatusHistory/UserStatusHistory';
import { createUserStatusChangesHistory, getUserStatusChangesHistory } from '../../../redux/userStatusChangesHistory/userStatusChangesHistoryActions';
import SendSmtpEmailModal from './SendSmtpEmailModal/SendSmtpEmailModal';
import { ModalContext } from '../../../context';
import { UserCallHistory } from './UserCallHistory/UserCallHistory';
import { ChangeClientPassword } from '../../../components/ChangeClientPassword/ChangeClientPassword';
import { UserEmailHistory } from './UserEmailHistory/UserEmailHistory';
import SendSmsModal from './SendSmsModal/SendSmsModal';
import KYCComments from './KYCComments/KYCComments';
import { UserSmsHistory } from './UserSmsHistory/UserSmsHistory';
import SkypeIcon from '../../../assets/images/SkypeIcon.svg';
import WhatsAppIcon from '../../../assets/images/WhatsApp.svg';
import LoginIcon from '../../../assets/images/LoginIcon.svg';
import CallIcon from '../../../assets/images/CallIcon.svg';
import ViberIcon from '../../../assets/images/ViberIcon`.svg';
import TelegramIcon from '../../../assets/images/TelegramIcon`.svg';
import EmailIcon from '../../../assets/images/EmailIcon.svg';
import MessageIcon from '../../../assets/images/MessageIcon.svg';
import SmsIcon from '../../../assets/images/SMSIcon.svg';
import AdminLabel from '../../../assets/images/roles/role=Admin.svg';
import AgentLabel from '../../../assets/images/roles/role=Agent.svg';
import DeskIBLabel from '../../../assets/images/roles/role=Desk_IB.svg';
import ManagerLabel from '../../../assets/images/roles/role=Manager.svg';
import SubAdminLabel from '../../../assets/images/roles/role=SUB_Admin.svg';
import SuperAdminLabel from '../../../assets/images/roles/role=Super_Admin.svg';
import { getDocumentsOption } from '../../../redux/documents/documentsActions';
import styles from './UserDetails.module.css';
import {
  CopyToClipboardInputDecorator,
} from '../../../components/CopyToClipboardInputDecorator/CopyToClipboardInputComponent';
import { SALES_ROLES, SALES_STATUSES } from '../../AffiliatesDashboard/utils/optionsHelper';
import { selectDocumentsOption } from '../../../redux/documents/documentsSelectors';

const roleToLabelSrc = {
  Admin: `${AdminLabel}#Admin`,
  Agent: `${AgentLabel}#Agent`,
  IBDesk: `${DeskIBLabel}#DeskIB`,
  Manager: `${ManagerLabel}#Manager`,
  'Sub Admin': `${SubAdminLabel}#SubAdmin`,
  SuperAdmin: `${SuperAdminLabel}#SuperAdmin`,
};

const colourStyles = {
  control: (styles, { isSelected }) => ({
    ...styles,
    background: '#374057',
    color: '#fff',
    border: '1px solid #374057',
    boxShadow: isSelected ? 'none' : 'none',
    borderColor: isSelected ? '#374057' : '#374057',
    '&:hover': {
      boxShadow: 'none',
    },
  }),
  input: (styles) => ({
    ...styles,
    color: '#fff',
  }),
  singleValue: (styles) => ({
    ...styles,
    color: '#fff',
  }),
  menuList: (styles) => ({
    ...styles,
    background: '#374057',
  }),
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    background: isFocused
      ? '#16202e'
      : isSelected
        ? '#16202e'
        : undefined,
    color: '#fff',
    cursor: 'pointer',
    zIndex: 1,
    '&:hover': {
      background: '#16202e',
    },
  }),
};

const padTo2Digits = (num) => num.toString().padStart(2, '0');

const formatDate = (date) => (
  `${[
    date.getFullYear(),
    padTo2Digits(date.getMonth() + 1),
    padTo2Digits(date.getDate()),
  ].join('-')
  } ${
    [
      padTo2Digits(date.getHours()),
      padTo2Digits(date.getMinutes()),
      padTo2Digits(date.getSeconds()),
    ].join(':')}`
);

const makeNumberCorrect = (phoneNumber = '', phoneCode = '') => {
  const trimmedPhoneNumber = phoneNumber.trim();
  const trimmedPhoneCode = phoneCode.trim();

  if (trimmedPhoneNumber.startsWith('+')) {
    return trimmedPhoneNumber;
  }

  if (trimmedPhoneNumber.startsWith(trimmedPhoneCode)) {
    return `+${trimmedPhoneNumber}`;
  }

  return `+${trimmedPhoneCode}${trimmedPhoneNumber}`;
};

function EditUser() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const [user, setUser] = useState('');
  const [selectedFlagCountry, setSelectedFlagCountry] = useState('');
  const [correctNumber, setCorrectNumber] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [selectedUserType, setSelectedUserType] = useState({ label: 'Lead', value: 1 });
  const [salesStatusType, setSalesStatusType] = useState({ value: '', color: '#fff' });
  const [agentUsers, setAgentUsers] = useState([]);
  const [assignedTo, setAssignedTo] = useState('');
  const [adminComment, setAdminComment] = useState('');
  const [userStatus, setUserStatus] = useState(true);
  const [adminCommentErr, setAdminCommentErr] = useState('');
  const [isCheckAllComments, setIsCheckAllComments] = useState(false);
  const [isCheckComment, setIsCheckComment] = useState([]);
  const [isCheckCommentErr, setIsCheckCommentErr] = useState('');
  const [showMore, setShowMore] = useState(false);
  const [editedCommentText, setEditedCommentText] = useState('');
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [brandId, setBrandId] = useState('');
  const [isChatEnabled, setIsChatEnabled] = useState(true);
  const [isWithdrawalEnabled, setIsWithdrawalEnabled] = useState(true);
  const [isPhoneVisible, setIsPhoneVisible] = useState(true);
  const [isEmailVisible, setIsEmailVisible] = useState(true);
  const [isEmailVerificationNeeded, setIsEmailVerificationNeeded] = useState(true);
  const [isVerificationEnabled, setIsVerificationEnabled] = useState(true);
  const [autoTraderLevel, setAutoTraderLevel] = useState('');
  const [originalSalesStatusType, setOriginalSalesStatusType] = useState({ value: '', color: '#fff' });
  const [changedClientPassword, setChangedClientPassword] = useState('');
  const [filteredSalesStatuses, setFilteredSalesStatuses] = useState([]);
  const [documentEnable, setDocumentEnable] = useState(false);
  const [documentSelectId, setDocumentSelectId] = useState('');

  const userData = useSelector((state) => state?.users?.user);
  const crmUsers = useSelector((state) => state.crmUser?.crmUsers);
  const crmUser = useSelector((state) => state.crmUser.crmUserInfo);
  const currentCrmUser = useSelector((state) => state.crmUser.crmUserInfo);
  const salesStatuses = useSelector((state) => state?.salesStatus?.salesStatuses);
  const adminComments = useSelector((state) => state?.adminComment?.adminComments);
  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions);
  const brands = useSelector((state) => state.brands);
  const countries = useSelector(selectAllCountries);
  const { totalCount: callHistoryTotalCount } = useSelector((state) => state.callHistory);
  const selectedPrefix = useSelector((state) => state.voip.selectedPrefix);
  const documentsOption = useSelector(selectDocumentsOption);

  const { showModal, hideModal } = useContext(ModalContext);

  const swappedSalesRoles = useMemo(() => Object.fromEntries(
    Object.entries(SALES_ROLES).map(([key, value]) => [value, key]),
  ), []);

  useEffect(() => {
    if (userData) {
      const filteredStatuses = salesStatuses.filter((status) => status.type === SALES_STATUSES[swappedSalesRoles[userData.clientType]] 
        || status.type === SALES_STATUSES.BOTH);
      setFilteredSalesStatuses(filteredStatuses);
    }
  }, [salesStatuses, userData]);

  const isCRMUserCanEditClient = useMemo(() => (permissionName && permissionName.length && permissionName.includes('edit_user')), [permissionName]);
  const isAffiliateRule = useMemo(() => (permissionName && permissionName.length && permissionName.includes('affiliates')), [permissionName]);
  const isUserCanSeeClientMessenger = useMemo(() => (permissionName && permissionName.includes('client_messenger')), [permissionName]);
  const isUserCanSeeComments = useMemo(() => (permissionName && permissionName.includes('comments')), [permissionName]);
  const isUserCanLoginAsClient = useMemo(() => (permissionName && permissionName.includes('cfd_login')), [permissionName]);
  const isUserCanSeeDocument = permissionName && permissionName.length > 0 && permissionName.includes('documents');

  useEffect(() => {
    setBrandId((user && user.brand && user.brand.length) ? user.brand[0]._id : '');
    if (typeof user.isChatEnabled !== 'undefined') setIsChatEnabled(user.isChatEnabled);
    if (typeof user.isWithdrawalEnabled !== 'undefined') setIsWithdrawalEnabled(user.isWithdrawalEnabled);
    setIsPhoneVisible(user.isPhoneVisible);
    setIsEmailVisible(user.isEmailVisible);
    setIsEmailVerificationNeeded(user.isEmailVerificationNeeded);
    setIsVerificationEnabled(user.isVerificationEnabled);
    const level = user?.autotrader?.[0]?.isEnabled ? 'enabled' : 'disabled';
    setAutoTraderLevel(level);
  }, [user]);

  useEffect(() => {
    if (!countries.length) {
      dispatch(fetchCounties());
    }
  }, [countries]);


  const findCountryByName = useCallback(() => {
    const lowerSearchString = selectedFlagCountry.trim().toLowerCase();

    if (lowerSearchString.length === 0) {
      return null;
    }

    return countries.find((country) => {
      const nicenameLower = (country.nicename ?? '').trim().toLowerCase();
      const isoLower = (country.iso ?? '').trim().toLowerCase();
      const iso3Lower = (country.iso3 ?? '').trim().toLowerCase();

      return [nicenameLower, isoLower, iso3Lower].some((code) => code === lowerSearchString);
    });
  }, [countries, selectedFlagCountry]);

  useEffect(() => {
    if (countries.length && selectedFlagCountry.length) {
      const country = findCountryByName(countries, selectedFlagCountry);
      const correctNumber = makeNumberCorrect(user.phone, country?.phonecode ?? '');
      setCorrectNumber(correctNumber);
    }
  }, [selectedFlagCountry, countries]);

  const {
    register, handleSubmit, control, reset, formState: { errors },
  } = useForm();

  const handleSave = async (formData) => {
    try {
      const data = {
        firstName: formData.firstname,
        lastName: formData.lastname,
        phone: formData.phone,
        fullAddress: formData.fullAddress,
        secondEmail: formData.secondEmail || '',
        secondPhone: formData.secondPhone || '',
        email: formData.email,
        dateOfBirth,
        countryCode: selectedFlagCountry,
        clientType: selectedUserType.value,
        status: userStatus,
        assignedTo: assignedTo.value ? assignedTo.value : null,
        salesStatusId: salesStatusType?.value ? salesStatusType?.value : null,
        brandId: brandId || null,
        isChatEnabled,
        isWithdrawalEnabled,
        isPhoneVisible,
        isEmailVisible,
        isEmailVerificationNeeded,
        isVerificationEnabled,
        crmUserId: crmUser._id,
        autoTraderLevel,
        signedDocumentEnable: documentEnable,
        documentId: documentSelectId,
      };

      await dispatch(editUser(id, data, true, true, true));

      if (salesStatusType?.value !== originalSalesStatusType?.value) {
        const previousStatusData = salesStatuses.find((status) => status._id === originalSalesStatusType?.value);
        const actualStatusData = salesStatuses.find((status) => status._id === salesStatusType?.value);

        await dispatch(createUserStatusChangesHistory({
          user: user._id,
          agent: crmUser._id,
          agentName: `${crmUser.firstName} ${crmUser.lastName}`,
          previousStatus: previousStatusData?.name ?? '',
          actualStatus: actualStatusData?.name ?? '',
        }));
        dispatch(getUserStatusChangesHistory(user._id));
      }
    } catch (error) {
      // console.error(error.message);
    }
  };

  const handleToggleShowMore = () => {
    if (showMore === true) setShowMore(false);
    else setShowMore(true);
  };

  const handleUserStatusChange2 = (e) => {
    setSalesStatusType({ value: e.target.value, color: e.target[e.target.selectedIndex].getAttribute('color') });
  };

  const handleAssignedToChange = (selectedAssignedTo) => {
    setAssignedTo(selectedAssignedTo);
  };

  const handleCommentSubmit = async (e) => {
    e.preventDefault();
    if (!adminComment) {
      setAdminCommentErr('Comment is required');
    } else {
      const authorId = JSON.parse(localStorage.getItem('userId'));
      const author = JSON.parse(localStorage.getItem('user'));
      const formattedComment = adminComment.replace(/\n/g, '<br>');
      const data = {
        text: formattedComment,
        authorId,
        userId: id,
        author,
      };
      setAdminCommentErr('');
      setAdminComment('');
      await dispatch(addAdminComment(data));
    }
  };

  const handleSelectAllComments = () => {
    setIsCheckCommentErr('');
    setIsCheckAllComments(!isCheckAllComments);
    setIsCheckComment(adminComments?.map((li) => li._id));
    if (isCheckAllComments) {
      setIsCheckComment([]);
    }
  };

  const handleCommentClick = (e) => {
    setIsCheckCommentErr('');
    const { value, checked } = e.target;
    setIsCheckComment([...isCheckComment, value]);
    if (!checked) {
      setIsCheckComment(isCheckComment.filter((item) => item !== value));
    }
  };

  useEffect(() => {
    setIsCheckCommentErr('');
    if (isCheckComment?.length === adminComments?.length) {
      setIsCheckAllComments(true);
    } else {
      setIsCheckAllComments(false);
    }
  }, [isCheckComment, adminComments]);

  const handleEditComment = (commentId) => {
    setEditingCommentId(commentId);
    const comment = adminComments.find((comment) => comment._id === commentId);
    if (comment) {
      setEditedCommentText(comment.text);
    }
  };

  const handleSaveEditedComment = (commentId) => {
    const formattedComment = editedCommentText.replace(/\n/g, '<br>');
    const authorId = JSON.parse(localStorage.getItem('userId'));
    dispatch(editAdminComment(commentId, { text: formattedComment, authorId }));
    setEditingCommentId(null);
    setEditedCommentText('');
  };

  const handleCancelEdit = () => {
    setEditingCommentId(null);
    setEditedCommentText('');
  };

  const handleDelSingleComment = async (e, commentId) => {
    e.preventDefault();
    setIsCheckCommentErr('');
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed === true) {
        dispatch(deleteAdminComment(commentId));
        setIsCheckComment([]);
      }
    });
  };

  const handleDelMultiComments = async (e) => {
    e.preventDefault();
    if (isCheckComment?.length < 1) {
      setIsCheckCommentErr('Check atleast one comment to delete');
    } else if (isCheckComment?.length > 0) {
      setIsCheckCommentErr('');
      const data = {
        ids: isCheckComment,
        userId: id,
      };

      Swal.fire({
        title: 'Are you sure you want to Delete?',
        html: '',
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
      }).then((result) => {
        if (result.isConfirmed === true) {
          dispatch(deleteAdminComments(data));
          setIsCheckComment([]);
        }
      });
    }
  };

  const loginAsUser = async ({
    _id, email, brand, firstName, lastName,
  }) => {
    const setURLByBrandName = (hostname) => `http${(hostname.startsWith('localhost') || (!Number.isNaN(hostname[0])) ? '' : 's')}://${hostname}`;
    if (_id) {
      const url = (brand && brand.length) ? setURLByBrandName(brand[0].hostname) : process.env.REACT_APP_FRONTEND_URL;

      Promise.allSettled([
        dispatch(userDirectLogin({ email, userByAdmin: 1 }, url)),
        apiHelper('post', '/api/crm-activity-log/add', {
          userId: _id,
          module: 'CRM User Login To User Account',
          message: `CRM User Logined as '${firstName} ${lastName}'`,
        }),
      ]);
    }
  };

  useEffect(async () => {
    async function fetchData() {
      const loginData = localStorage.getItem('user');
      const data = JSON.parse(loginData);
      const roleId = typeof data.roleId === 'string' ? data.roleId : data.roleId?._id;
      const uid = data?._id;

      if (data) {
        await Promise.all([
          dispatch(getRole(roleId)),
          dispatch(getSubCRMUsers(roleId, uid)),
          dispatch(getSalesStatuses()),
          dispatch(getAdminComments(id)),
          dispatch(getBrands()),
          dispatch(getUserStatusChangesHistory(id)),
          dispatch(getDocumentsOption()),
        ]);
      }
    }
    await fetchData();
  }, [id]);

  useEffect(() => {
    if (crmUsers?.length > 0) {
      const allAgents = crmUsers?.map((agen) => ({ label: `${agen.firstName} ${agen.lastName}`, value: agen._id }));
      setAgentUsers(allAgents);
    }
  }, [crmUsers]);

  useEffect(() => {
    setUser(userData);
    if (userData?.dateOfBirth) setDateOfBirth(userData?.dateOfBirth);
    if (userData?.countryCode) setSelectedFlagCountry(userData?.countryCode);
    if (userData?.clientType) {
      setSelectedUserType({ label: userData?.clientType === 1 ? 'Lead' : 'Client', value: userData?.clientType });
    } else {
      setSelectedUserType({ label: 'Lead', value: 1 });
    }

    if (userData?.salesStatus && Object.keys(userData?.salesStatus).length > 0) {
      setSalesStatusType({ value: userData?.salesStatus?._id, color: userData?.salesStatus?.color });
      setOriginalSalesStatusType({ value: userData?.salesStatus?._id, color: userData?.salesStatus?.color });
    } else {
      setSalesStatusType({ value: '', color: '#fff' });
      setOriginalSalesStatusType({ value: '', color: '#fff' });
    }

    if (agentUsers?.length > 0) {
      if (userData?.assignedTo) {
        const currAgent = agentUsers.find((stat) => stat?.value === userData?.assignedTo);
        if (currAgent) {
          setAssignedTo({ label: currAgent.label, value: currAgent.value });
        } else {
          setAssignedTo('');
        }
      } else {
        setAssignedTo('');
      }
    }

    if (userData) {
      setUserStatus(userData.status);
      setDocumentEnable(userData?.signedDocumentEnable ?? false);
    }

    if (userData) {
      reset({
        firstname: userData?.firstName,
        lastname: userData?.lastName,
        phone: userData?.phone,
        fullAddress: userData?.fullAddress,
      });
    }
  }, [userData, agentUsers]);

  const editUserData = {
    firstname: {
      required: 'First name is required',
    },
    lastname: {
      required: 'Last name is required',
    },
    // phone: {
    //   required: 'Phone Number is required',
    //   pattern: {
    //     value: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
    //     message: 'Please enter a valid contact number',
    //   },
    // },
  };

  const handleClickToCall = async () => {
    try {
      const res = await apiHelper('post', `/api/voip/call/${id}`, selectedPrefix ? { selectedPrefix } : {});
      if (res && res.data && res.data.success) {
        const { message } = res.data;

        toast.success(message);
      }
    } catch (error) {
      console.log(error.message);

      toast.error("Error: cant't make call");
    }
  };

  const handleChangeUserStatus = (userStatus) => {
    setUserStatus(userStatus);
  };

  const handleSendEmailBtn = () => {
    showModal({
      headerContent: <h3>SEND EMAIL</h3>,
      bodyContent: (
        <SendSmtpEmailModal
          userId={id}
          brandId={brandId}
          handleClose={hideModal}
        />
      ),
    });
  };

  const sendChatMessage = async () => {
    if (user.chat && user.chat._id) {
      history.push(`/chatbox/${user.chat._id}`);

      return;
    }

    const chat = await addChat({ userId: user._id, crmUserId: currentCrmUser._id });

    if (chat && chat._id) history.push(`/chatbox/${chat._id}`);
  };

  const handleSendSmsBtn = () => {
    showModal({
      headerContent: (
        <h3>
          Send SMS to
          {' '}
          {`${user?.firstName} ${user?.lastName}`}
        </h3>
      ),
      bodyContent: <SendSmsModal
        userId={id}
        handleClose={hideModal}
        toPhoneNumber={user?.phone}
      />,
    });
  };

  const messengerData = [
    {
      name: 'WhatsApp',
      baseLink: 'https://wa.me/',
      icon: `${WhatsAppIcon}#WhatsAppIcon`,
    },
    {
      name: 'Telegram',
      baseLink: 'https://t.me/',
      icon: `${TelegramIcon}#TelegramIcon`,
    },
    {
      name: 'Viber',
      baseLink: 'viber://chat?number=',
      icon: `${ViberIcon}#ViberIcon`,
    },
    {
      name: 'Skype',
      baseLink: 'skype:',
      icon: `${SkypeIcon}#SkypeIcon`,
    },
  ];

  const handlePasswordChange = async () => {
    if (changedClientPassword.length >= 6) {
      const success = await dispatch(updateClientPassword(id, changedClientPassword));
      if (success) {
        setUser({ ...user, plainPassword: changedClientPassword });
      }
      setChangedClientPassword('');
      return;
    }
    toast.error('Password should contains 6 symbols at least');
  };

  const handleTextareaKeyDown = (e) => {
    if (e.key === 'Enter' && e.shiftKey) {
      e.preventDefault();
      const textarea = e.target;
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;
      const { value } = textarea;
      const newValue = `${value.substring(0, start)}\n${value.substring(end)}`;
      setAdminComment(newValue);

      setTimeout(() => {
        textarea.selectionStart = start + 1;
        textarea.selectionEnd = start + 1;
      }, 0);
    }
  };

  const memoizedEdditingComment = useMemo(() => {
    const comment = adminComments.find((comment) => comment._id === editingCommentId);

    if (!comment) return null;

    return (
      <div key={`comment-${comment._id}`} className="form-check form-group input-wrapper input-border d-flex mb-3" style={{ padding: '0 0 10px 0' }}>
        {/* Edit Mode */}
        <div className="info-content" style={{ width: '100%' }}>
          <h5 className="mb-1">
            {' '}
            {`${
              formatDate(new Date(comment.createdAt))}, 
          ${comment.author?.firstName ?? 'Deleted'} 
          ${comment.author?.lastName ?? 'User'}
          `}
            {' '}
          </h5>
          <textarea
            rows="4"
            className="form-control edit-field"
            value={editedCommentText.replace(/<br\s*\/?>/gi, '\n')}
            onChange={(e) => setEditedCommentText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && e.shiftKey) {
                e.preventDefault();
                const textarea = e.target;
                const start = textarea.selectionStart;
                const end = textarea.selectionEnd;
                const { value } = textarea;
                const newValue = `${value.substring(0, start)}\n${value.substring(end)}`;
                setEditedCommentText(newValue);

                setTimeout(() => {
                  textarea.selectionStart = start + 1;
                  textarea.selectionEnd = start + 1;
                }, 0);
              }
            }}
          />
          <button type="button" className="btn btn-default edit-button mt-2 me-2" onClick={() => handleSaveEditedComment(comment._id)}>Save</button>
          <button type="button" className="btn btn-default edit-button mt-2" onClick={handleCancelEdit}>Cancel</button>
        </div>
      </div>
    );
  }, [editingCommentId, editedCommentText]);

  const memoizedFilteredSalesStatuses = useMemo(() => (
    filteredSalesStatuses?.length > 0 ? filteredSalesStatuses.map((currentStatus) => (
      <option value={currentStatus?._id} key={currentStatus?._id} style={{ color: currentStatus?.color }} color={currentStatus?.color}>
        {' '}
        {currentStatus?.name}
      </option>
    )) : null
  ), [filteredSalesStatuses]);

  const memoizedComments = useMemo(() => (
    <div>
      {adminComments?.map((comment) => (
        comment._id !== editingCommentId && (
          <div key={`comment-${comment._id}`} className="form-check form-group input-wrapper input-border d-flex mb-3">
            {/* View Mode */}
            {permissionName && permissionName.length > 0 && (permissionName.includes('delete_comment') || permissionName.includes('edit_comment')) ? (
              <div className="checkboxes me-3" style={{ display: 'flex', gap: '10px' }}>
                <input
                  className="form-check-input"
                  style={{ marginTop: '1px' }}
                  type="checkbox"
                  value={comment._id}
                  checked={isCheckComment?.includes(comment._id) || false}
                  onChange={(e) => handleCommentClick(e)}
                />
                {permissionName.includes('delete_comment')
            && <FontAwesomeIcon icon={faTrash} className="del-icon" onClick={(e) => handleDelSingleComment(e, comment._id)} />}
                {permissionName.includes('edit_comment')
            && <FontAwesomeIcon icon={faEdit} className="edit-icon" onClick={() => handleEditComment(comment._id)} />}
                <FontAwesomeIcon
                  icon={faThumbTack}
                  style={{ cursor: 'pointer', color: comment?.isPinned ? 'green' : 'white' }}
                  onClick={() => dispatch(pinComment(comment._id))}
                />
              </div>
            ) : null}
            <div className="info-content">
              <h5 className="mb-1">
                {' '}
                {`${formatDate(new Date(comment?.updatedAt))}, ${comment?.author?.firstName ?? 'Deleted'} ${comment?.author?.lastName ?? 'User'} `}
                {comment?.updatedAt !== comment?.createdAt && '(Edited)'}
                {' '}
                <span>
                  <svg style={{ maxHeight: '20px' }}>
                    <use xlinkHref={roleToLabelSrc[comment.role]} />
                  </svg>
                </span>
              </h5>
              <label className="control-label px-3">
                <Markdown
                  style={{
                    overflowWrap: 'break-word',
                    wordBreak: 'break-all',
                    whiteSpace: 'pre-wrap',
                    fontWeight: 400,
                  }}
                  options={{
                    overrides: {
                      ol: {
                      // eslint-disable-next-line react/no-unstable-nested-components
                        component: ({ children, ...props }) => (
                          <ol
                            {...props}
                            style={{
                              listStyleType: 'decimal',
                              marginLeft: '15px',
                              overflowWrap: 'break-word',
                              wordBreak: 'break-all',
                              whiteSpace: 'normal',
                            }}
                          >
                            {children}
                          </ol>
                        ),
                      },
                      ul: {
                      // eslint-disable-next-line react/no-unstable-nested-components
                        component: ({ children, ...props }) => (
                          <ul
                            {...props}
                            style={{
                              listStyleType: 'decimal',
                              marginLeft: '15px',
                              overflowWrap: 'break-word',
                              wordBreak: 'break-all',
                              whiteSpace: 'normal',
                            }}
                          >
                            {children}
                          </ul>
                        ),
                      },
                    },
                  }}
                >
                  {comment?.text.replaceAll('<br>', '\n') ?? ''}
                </Markdown>
              </label>
            </div>
          </div>
        )
      ))}
    </div>
  ), [adminComments, editingCommentId, isCheckAllComments, isCheckComment]);

  return (
    <div className="edit-user-block">
      <div className="left-block">
        <div className="personal-info">
          <div className={styles.smallBtnRow}>
            <h5 style={{ marginBottom: 0 }}>Personal Info</h5>
            <div className={styles.btnRow}>
              <button type="button" className={styles.btn} onClick={handleSendEmailBtn}>
                <svg className={styles.svgSize}>
                  <use xlinkHref={`${EmailIcon}#EmailIcon`} />
                </svg>
                Email
              </button>
              <button type="button" className={styles.btn} onClick={sendChatMessage}>
                <svg className={styles.svgSize}>
                  <use xlinkHref={`${MessageIcon}#MessageIcon`} />
                </svg>
                Message
              </button>
              <button type="button" className={styles.btn} onClick={handleSendSmsBtn}>
                <svg className={styles.svgSize}>
                  <use xlinkHref={`${SmsIcon}#SMSIcon`} />
                </svg>
                SMS
              </button>
            </div>
          </div>

          <div className={styles.mainBtnRow}>
            {isUserCanSeeClientMessenger && (
              messengerData.map((item) => (
                <a className={styles.btn} href={`${item.baseLink}${correctNumber}`} target="_blank" rel="noopener noreferrer" key={item.baseLink}>
                  <svg className={styles.svgSize}>
                    <use xlinkHref={item.icon} />
                  </svg>
                  {item.name}
                </a>
              ))
            )}
            <button type="button" className={styles.btn} onClick={handleClickToCall}>
              <svg className={styles.svgSize}>
                <use xlinkHref={`${CallIcon}#CallIcon`} />
              </svg>
              Call
            </button>
            {
              isUserCanLoginAsClient && (
                <button type="button" className={styles.btn} onClick={() => loginAsUser(user)}>
                  <svg className={styles.svgSize}>
                    <use xlinkHref={`${LoginIcon}#LoginIcon`} />
                  </svg>
                  Login
                </button>
              )
            }
          </div>

          <form onSubmit={handleSubmit(handleSave)}>
            <div className="row">
              <div className="form-group mt-2">
                <ChangeClientPassword
                  header=""
                  id="Pin"
                  label="PIN"
                  placeholder=""
                  disabled
                  withoutHeader
                  inputValue={user.pin}
                  style={{ padding: '0' }}
                />
              </div>
              <div className="form-group mt-2">
                <ChangeClientPassword
                  header=""
                  id="Plain Password"
                  label="Password"
                  placeholder=""
                  disabled
                  withoutHeader
                  inputValue={user.plainPassword}
                  style={{ padding: '0' }}
                />
              </div>
              <div className="form-group mt-2">
                <label className="control-label">First Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter First name"
                  {...register('firstname', editUserData?.firstname)}
                  name="firstname"
                  defaultValue={user?.firstName}
                  control={control}
                  disabled={!isCRMUserCanEditClient}
                />
                {errors?.firstname && <span className="errMsg">{errors?.firstname?.message}</span>}
              </div>

              <div className="form-group mt-2">
                <label className="control-label">Last Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Last name"
                  {...register('lastname', editUserData?.lastname)}
                  name="lastname"
                  defaultValue={user?.lastName}
                  control={control}
                  disabled={!isCRMUserCanEditClient}
                />
                {errors?.lastname && <span className="errMsg">{errors?.lastname?.message}</span>}
              </div>
              <div className="form-group mt-2">
                <label className="control-label">Country</label>
                <ReactFlagsSelect
                  disabled={!isCRMUserCanEditClient}
                  selected={selectedFlagCountry}
                  onSelect={(code) => setSelectedFlagCountry(code)}
                  searchable
                  searchPlaceholder="Search for a country"
                  className="admin-country-react-flags-select"
                />
              </div>

              {permissionName && permissionName.length > 0 && permissionName.includes('user_email')
                ? (
                  <div className="form-group mt-2 d-block position-relative">
                    <label className="control-label">Email</label>
                    <CopyToClipboardInputDecorator
                      value={editUserData?.email ?? user.email}
                    >
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Enter Email"
                        name="email"
                        defaultValue={user.email}
                        control={control}
                        {...register('email', editUserData?.email)}
                        disabled={!isCRMUserCanEditClient}
                      />
                    </CopyToClipboardInputDecorator>
                  </div>
                )
                : null}

              {permissionName && permissionName.length > 0 && permissionName.includes('user_phone')
                ? (
                  <div className="form-group mt-2">
                    <label className="control-label">Phone Number</label>
                    <CopyToClipboardInputDecorator value={editUserData?.phone ?? user?.phone}>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Phone number"
                        {...register('phone', editUserData?.phone)}
                        name="phone"
                        defaultValue={user?.phone}
                        control={control}
                        disabled={!isCRMUserCanEditClient}
                      />
                    </CopyToClipboardInputDecorator>
                    {errors?.phone && <span className="errMsg">{errors?.phone?.message}</span>}
                  </div>
                )
                : null}

              {showMore ? (
                <>
                  {
                  (permissionName && permissionName.length > 0 && permissionName.includes('user_email')) && (
                    <div className="form-group mt-2 d-block position-relative">
                      <label className="control-label">Second Email</label>
                      <CopyToClipboardInputDecorator
                        value={editUserData?.secondEmail ?? user.secondEmail}
                      >
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Enter Email"
                          name="secondEmail"
                          defaultValue={user.secondEmail}
                          control={control}
                          {...register('secondEmail', editUserData?.secondEmail)}
                          disabled={!isCRMUserCanEditClient}
                        />
                      </CopyToClipboardInputDecorator>
                    </div>
                  )
                }
                  {(permissionName && permissionName.length > 0 && permissionName.includes('user_phone'))
                    && (
                      <div className="form-group mt-2">
                        <label className="control-label">Second Phone Number</label>
                        <CopyToClipboardInputDecorator value={editUserData?.secondPhone ?? user?.secondPhone}>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Phone number"
                            {...register('secondPhone', editUserData?.secondPhone)}
                            name="secondPhone"
                            defaultValue={user?.secondPhone}
                            control={control}
                            disabled={!isCRMUserCanEditClient}
                          />
                        </CopyToClipboardInputDecorator>
                        {errors?.secondPhone && <span className="errMsg">{errors?.secondPhone?.message}</span>}
                      </div>
                    )}
                  <div className="form-group mt-2 dob">
                    <label className="control-label">Birthday</label>
                    <input
                      disabled={!isCRMUserCanEditClient}
                      type="date"
                      className="form-control"
                      placeholder="Type date of birth..."
                      name="dateOfBirth"
                      value={dateOfBirth ? new Date(dateOfBirth)?.toISOString()?.substring(0, 10) : new Date()?.toISOString()?.substring(0, 10)}
                      onChange={(event) => setDateOfBirth(event.target.value)}
                    />
                  </div>

                  <div className="form-group mt-2 dob">
                    <label className="control-label">Full Address</label>
                    <input
                      disabled={!isCRMUserCanEditClient}
                      type="text"
                      className="form-control"
                      {...register('fullAddress', editUserData?.fullAddress)}
                      placeholder="Full Address of residents"
                      name="fullAddress"
                      defaultValue={user?.fullAddress}
                      control={control}
                    />
                  </div>

                  {user.ref && user.ref.refererId
                    ? (
                      <div className="form-group mt-2">
                        <label className="control-label">Referrer Id</label>
                        <input
                          type="text"
                          className="form-control"
                          value={user.ref.refererId}
                          disabled
                        />
                      </div>
                    )
                    : null}

                  {user.referals && user.referals.length
                    ? (
                      <div className="form-group mt-2">
                        <label className="control-label">Invitation Count</label>
                        <input
                          type="text"
                          className="form-control"
                          value={user.referals.length}
                          disabled
                        />
                      </div>
                    )
                    : null}
                  <div className="form-group mt-2">
                    <label className="control-label">
                      Registration Date
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={formatDate(new Date(user?.createdAt))}
                      disabled
                    />
                  </div>
                  {permissionName && permissionName.length > 0 && permissionName.includes('block_user') && (
                    <div className="form-group mt-2">
                      <label className="control-label">Enabled Account</label>
                      <select
                        disabled={!isCRMUserCanEditClient}
                        defaultValue={userStatus}
                        style={{
                          backgroundColor: '#374057',
                          color: 'white',
                          border: '0',
                          width: '100%',
                          padding: '10px',
                        }}
                        onChange={(e) => handleChangeUserStatus(e.target.value)}
                      >
                        <option value>yes</option>
                        <option value={false}>no</option>
                      </select>
                    </div>
                  )}
                  <div className="form-group mt-2">
                    <label className="control-label">Chat Enabled</label>
                    <select
                      disabled={!isCRMUserCanEditClient}
                      defaultValue={user.isChatEnabled}
                      style={{
                        backgroundColor: '#374057', color: 'white', border: '0', width: '100%', padding: '10px',
                      }}
                      onChange={({ target: { value } }) => setIsChatEnabled(value)}
                    >
                      <option value>yes</option>
                      <option value={false}>no</option>
                    </select>
                  </div>
                  <div className="form-group mt-2">
                    <label className="control-label">Withdrawal Enabled</label>
                    <select
                      disabled={!isCRMUserCanEditClient}
                      defaultValue={user.isWithdrawalEnabled}
                      style={{
                        backgroundColor: '#374057', color: 'white', border: '0', width: '100%', padding: '10px',
                      }}
                      onChange={({ target: { value } }) => setIsWithdrawalEnabled(value)}
                    >
                      <option value>yes</option>
                      <option value={false}>no</option>
                    </select>
                  </div>
                  <div className="form-group mt-2">
                    <label className="control-label">Enabled Client Phone</label>
                    <select
                      disabled={!isCRMUserCanEditClient}
                      defaultValue={user.isPhoneVisible}
                      style={{
                        backgroundColor: '#374057', color: 'white', border: '0', width: '100%', padding: '10px',
                      }}
                      onChange={({ target: { value } }) => setIsPhoneVisible(value)}
                    >
                      <option value>yes</option>
                      <option value={false}>no</option>
                    </select>
                  </div>
                  <div className="form-group mt-2">
                    <label className="control-label">Email Visible</label>
                    <select
                      disabled={!isCRMUserCanEditClient}
                      defaultValue={user.isEmailVisible}
                      style={{
                        backgroundColor: '#374057', color: 'white', border: '0', width: '100%', padding: '10px',
                      }}
                      onChange={({ target: { value } }) => setIsEmailVisible(value)}
                    >
                      <option value>yes</option>
                      <option value={false}>no</option>
                    </select>
                  </div>
                  {/* <div className="form-group mt-2">
                    <label className="control-label">Email Verification</label>
                    <select
                      disabled={!isCRMUserCanEditClient}
                      defaultValue={user.isEmailVerificationNeeded}
                      style={{
                        backgroundColor: '#374057', color: 'white', border: '0', width: '100%', padding: '10px',
                      }}
                      onChange={({ target: { value } }) => setIsEmailVerificationNeeded(value)}
                    >
                      <option value>yes</option>
                      <option value={false}>no</option>
                    </select>
                  </div> */}
                  <div className="form-group mt-2">
                    <label className="control-label">Enabled Verification</label>
                    <select
                      disabled={!isCRMUserCanEditClient}
                      defaultValue={user.isVerificationEnabled}
                      style={{
                        backgroundColor: '#374057', color: 'white', border: '0', width: '100%', padding: '10px',
                      }}
                      onChange={({ target: { value } }) => setIsVerificationEnabled(value)}
                    >
                      <option value>yes</option>
                      <option value={false}>no</option>
                    </select>
                  </div>
                  <div className="form-group mt-2">
                    <label className="control-label">AutoTrader Enabled</label>
                    <select
                      disabled={!isCRMUserCanEditClient}
                      defaultValue={autoTraderLevel}
                      style={{
                        backgroundColor: '#374057', color: 'white', border: '0', width: '100%', padding: '10px',
                      }}
                      onChange={({ target: { value } }) => setAutoTraderLevel(value)}
                    >
                      <option value="disabled">No</option>
                      <option value="enabled">Yes</option>
                    </select>
                  </div>
                  <div className="form-group mt-2">
                    <label className="control-label">AutoTrader Risk Level</label>
                    <input
                      disabled
                      type="text"
                      className="form-control"
                      value={user?.autotrader?.[0]?.isEnabled ? user?.autotrader?.[0]?.riskLevel : 'Disabled'}
                    />
                  </div>
                  {isUserCanSeeDocument && (
                  <div className="form-group mt-2">
                    <label className="control-label">Signed Documents Enabled</label>
                    <select 
                      defaultValue={documentEnable}
                      style={{
                        backgroundColor: '#374057', color: 'white', border: '0', width: '100%', padding: '10px',
                      }}
                      onChange={({ target: { value } }) => setDocumentEnable(value)}
                    >
                      <option value={false}>No</option>
                      <option value>Yes</option>
                    </select>
                  </div>
                  )}
                  {isUserCanSeeDocument && (
                  <div className="form-group mt-2">
                    <label className="control-label">Select Document</label>
                    <select 
                      defaultValue={user.documentId ?? ''}
                      style={{
                        backgroundColor: '#374057', color: 'white', border: '0', width: '100%', padding: '10px',
                      }}
                      onChange={({ target: { value } }) => setDocumentSelectId(value)}
                    >
                      <option value="">No Document Selected</option>
                      {
                        documentsOption.map((el) => (
                          <option value={el.value} key={el.value}>{el.name}</option>
                        ))
                      }
                    </select>
                  </div>
                  )}
                </>
              ) : null}
            </div>
            <div style={{
              display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'flex-end',
            }}
            >
              <div
                style={{
                  color: 'gray', borderBottom: '2px dashed gray', height: '25px', cursor: 'pointer',
                }}
                onClick={() => handleToggleShowMore()}
              >
                {`Show ${showMore ? 'less' : 'more'}`}
              </div>
              {
                isCRMUserCanEditClient && <button className="btn btn-default" type="submit">Update Personal Details</button>
              }
            </div>
            <br />
          </form>
        </div>
        <ChangeClientPassword
          id="NewClientPasswordChange"
          label="New Password"
          placeholder="Enter Password"
          handleChange={handlePasswordChange}
          btnTitle="Change Password"
          inputValue={changedClientPassword}
          setInputValue={setChangedClientPassword}
          disabled={!isCRMUserCanEditClient}
        />
        {isUserCanSeeComments && (
          <div>
            <div className="comments-wrapper">
              <div className="comments-content">
                <h3>COMMENTS</h3>
              </div>
              <div className="form-group col-md-12 pt-2 mb-4">
                <label className="control-label">Add new comment</label>
                <textarea
                  rows="10"
                  placeholder="Type your comment...."
                  className="form-control"
                  name="adminComment"
                  value={adminComment}
                  onChange={(event) => setAdminComment(event.target.value)}
                  onKeyDown={handleTextareaKeyDown}
                />
                {adminCommentErr ? (<span className="errMsg">{adminCommentErr}</span>) : ('')}
              </div>
              <div className="add-comment d-flex justify-content-end">
                <button type="button" onClick={handleCommentSubmit} className="btn btn-default">Add Comment</button>
              </div>
              {adminComments?.length > 0
                ? (
                  <>
                    <div className="form-check form-group mb-lg-5 mb-4">
                      <input className="form-check-input me-3" type="checkbox" checked={isCheckAllComments} onChange={(e) => handleSelectAllComments(e)} />
                      <label className="control-label" htmlFor="flexCheckDefault">
                        Select all comments
                      </label>
                    </div>
                    <div className="form-border-wrapper">
                      { memoizedEdditingComment }
                      { memoizedComments }
                    </div>
                    {isCheckCommentErr ? (<span className="errMsg">{isCheckCommentErr}</span>) : ('')}
                    {permissionName && permissionName.length > 0 && permissionName.includes('delete_comment')
                      ? (
                        <div className="delete-comment d-flex justify-content-end">
                          <button type="button" onClick={handleDelMultiComments}>Delete selected comments</button>
                        </div>
                      )
                      : null}
                  </>
                )
                : null}
            </div>
          </div>
        )}

      </div>
      <div className="right-block">
        <div>
          <h5>Sales</h5>

          <form onSubmit={handleSubmit(handleSave)}>

            <div className="form-group mt-2">
              <label className="control-label">
                User ID
              </label>
              <input
                type="text"
                className="form-control"
                value={user.customId}
                disabled
              />
            </div>
            <div className="form-group mt-2">
              <label className="control-label">Brand</label>
              <select
                className="form-control user-status-select"
                name="brand"
                value={brandId}
                disabled={!isCRMUserCanEditClient}
                onChange={({ target: { value } }) => setBrandId(value)}
              >
                {brands?.length && brands.map((brand) => (
                  <option value={brand._id} key={brand._id}>
                    {brand.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group mt-2">
              <label className="control-label">Status</label>
              <select
                className="form-control user-status-select"
                name="type"
                value={salesStatusType?.value}
                onChange={handleUserStatusChange2}
                style={{ color: salesStatusType?.color ? salesStatusType?.color : '#fff' }}
                disabled={!isCRMUserCanEditClient}
              >
                <option value="" style={{ color: '#fff' }} color="#fff">Select Status</option>
                {memoizedFilteredSalesStatuses}
              </select>
            </div>

            {permissionName && permissionName.length > 0 && permissionName.includes('assign_to_agent')
              ? (
                <div>
                  <label className="control-label">Assigned To</label>
                  <Select
                    value={assignedTo}
                    onChange={handleAssignedToChange}
                    options={agentUsers}
                    styles={colourStyles}
                    isDisabled={!isCRMUserCanEditClient}
                  />
                </div>
              )
              : null}

            <div className="form-group mt-2" style={{ cursor: 'not-allowed' }}>
              <label className="control-label">Role</label>
              <Select
                value={selectedUserType}
                styles={colourStyles}
                isDisabled
              />
            </div>

            <div className="form-group mt-2">
              <label className="control-label">
                Funnel Name
              </label>
              <input
                type="text"
                className="form-control"
                value={user?.source ? user.source : '-'}
                disabled
              />
            </div>

            <div className="form-group mt-2">
              <label className="control-label">
                Additional Info
              </label>
              <input
                type="text"
                className="form-control"
                value={user?.additionalInfo ?? '-'}
                disabled
              />
            </div>
            {
              isAffiliateRule && (
                <div className="form-group mt-2">
                  <label className="control-label">
                    Affiliate Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    disabled
                    value={user?.affiliator?.length ? `${user.affiliator[0].firstName} ${user.affiliator[0].lastName}` : '-'}
                  />
                </div>
              )
            }
            <div className="form-group mt-2">
              <label className="control-label">
                Calls Count
              </label>
              <input
                type="text"
                className="form-control"
                value={callHistoryTotalCount}
                disabled
              />
            </div>

            {/* <div className="form-group col-md-12 pt-2">
              <label className="control-label">Additional Info</label>
              <textarea
                placeholder="Enter additional info if any..."
                className="form-control"
                name="additionalInfo"
                value={additionalInfo}
                rows="3"
                onChange={(event) => setAdditionalInfo(event.target.value)}
                disabled={!isCRMUserCanEditClient}
              />
            </div> */}
            <div style={{ textAlign: 'right' }}>
              {
                isCRMUserCanEditClient && <button className="btn btn-default" type="submit">Update Sales Info</button>
              }
            </div>
          </form>
        </div>
        <UserEvents userId={user._id} />
        <UserStatusHistory />
        <UserCallHistory />
        <UserEmailHistory userId={user._id} />
        <UserSmsHistory userId={user._id} />
        <KYCComments userId={user._id} />
      </div>
    </div>
  );
}

export default EditUser;
