export const depositColumns = [
  {
    name: 'Transaction ID',
    field: 'customId',
    selected: true,
  },
  {
    name: 'Client ID',
    field: 'userCustomId',
    selected: true,
  },
  {
    name: 'Client',
    field: 'userFullName',
    selected: true,
  },
  {
    name: 'Account Id',
    field: 'accountCustomId',
    selected: true,
  },
  {
    name: 'Desk/IB',
    field: 'ibdeskFullName',
    selected: true,
  },
  {
    name: 'Email',
    field: 'userEmail',
    selected: true,
  },
  {
    name: 'Second Email',
    field: 'userSecondEmail',
    selected: true,
  },
  {
    name: 'Assigned To',
    field: 'assignedToFullName',
    selected: true,
  },
  {
    name: 'Amount',
    field: 'amount',
    selected: true,
  },
  {
    name: 'Currency',
    field: 'currency',
    selected: true,
  },
  {
    name: 'Type',
    field: 'balanceType',
    selected: true,
  },
  {
    name: 'Brand',
    field: 'brandName',
    selected: true,
  },
  {
    name: 'In USD',
    field: 'amountInUSD',
    selected: true,
  },
  {
    name: 'Affiliate Name',
    field: 'userAffiliateFullName',
    selected: true,
  },
  {
    name: 'Funnel Name',
    field: 'userSource',
    selected: true,
  },
  {
    name: 'Additional Info',
    field: 'userAdditionalInfo',
    selected: true,
  },
  {
    name: 'Status',
    field: 'status',
    selected: true,
  },
  {
    name: 'Time Initiated',
    field: 'createdAt',
    selected: true,
  },
  {
    name: 'Transaction Time',
    field: 'procesedTime',
    selected: true,
  },
  {
    name: 'Real/Fake',
    field: 'isReal',
    selected: true,
  },
  {
    name: 'Source',
    field: 'type',
    selected: true,
  },
  {
    name: 'Actions',
    field: '',
    selected: true,
  },
];
